import axios from "axios";
import { debounce } from "debounce";
import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTwitter, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'

export default function BannerSlide() {
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const navigate=useNavigate();
  const [show, setShow]=useState(false);
  const [slides, setSlides]=useState([]);
  const [imagesPerPage, setImagesPerPage]=useState(5);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const [icon, setIcon] = useState();

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);
  useEffect(() => {
    axios
    .get(
      url+"/image/search/" +
      "$GemeindeKub*" +
        "?page=" +
        0 +
        "&size=" +
        1
    )
    .then((response) => response.data)
    .then((data) => {
        console.log(data);
        setIcon({id: data.content[0].id, link:  data.content[0].link,url: data.content[0].urls.split(",")[0]});  
    })
    .catch((error) => {
      console.log(error);
    });
	}, []);
  useEffect(()=> {  
    findAllSlides();  
  },[]);

  function findAllSlides() {
    axios
      .get(
        url+"/image/search/" +
        "$BannerSlide*" +
          "?page=" +
          0 +
          "&size=" +
          imagesPerPage
      )
      .then((response) => response.data)
      .then((data) => {
      setSlides(data.content);     
      })
      .catch((error) => {
        console.log(error);
      });
  }



    return (
        <><div style={{ position: "relative" }}>
        <Carousel fade indicators={size>3?(true):(false)} style={{zIndex:'0'}}>
        {slides.length === 0 ? (
                <Carousel.Item interval={15000} className="overlayCaro">
                <img
                  className="d-block w-100 bannerImg"
                  src="https://pbs.twimg.com/media/EaiznCbWAAAOzj_.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Herzlich Willkommen</h3>
                  <p>bei der Freiwilligen Feuerwehr Kubschütz</p>
                </Carousel.Caption>
              </Carousel.Item>
                ) : (
                  slides.map((slide) => (
        <Carousel.Item key={slide.id} interval={15000} className="overlayCaro">
          <a href={slides.length <2 ?("https://www.google.de"):(slide.link)} target="_blank" rel="noreferrer">
          <img
            className="d-block w-100 bannerImg"
            src={url+"/image/image/download/"+slide.id+"/"+slide.urls.split(",")[0]}
            alt="First slide"
          />
          <Carousel.Caption>
            {size<3?( <h6 className="textBanner" >{slide.description}</h6>):(size<5?(<h4>{slide.description}</h4>):(<h3>{slide.description}</h3>))}
           
          </Carousel.Caption>
          </a>
        </Carousel.Item>  
          )))}
      </Carousel>
      
      <div className="social-media">
        <a href="https://de-de.facebook.com/ffwkubschuetz/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size={size < 3 ? "2x" : "3x"} className="facebook rounded-5"/>
        </a>
        <a href="https://twitter.com/FF_Kubschuetz" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} size={size < 3 ? "2x" : "3x"} className="twitter rounded-5"/>
        </a>
        <a href="https://www.instagram.com/ffw.kubschuetz/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size={size < 3 ? "2x" : "3x"} className="instagram rounded-4"/>
        </a>
        {icon?(
        <a href={icon.link} target="_blank" rel="noopener noreferrer">
          <img        src={url+"/image/image/download/"+icon.id+"/"+icon.url}
          width={size < 3 ? "32" : "50"} 
          height={size < 3 ? "32" : "50"}
          className="GeimeindeLogo rounded-5"
          alt="brand"/>
        </a>):null}
      </div>
      <div className="overlayAfter"></div>
      </div>
      </>
    );
  }