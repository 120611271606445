import imageCompression from 'browser-image-compression';
import { Card, Row, Form, Button, Col, FormControl, InputGroup, Image, Container, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwt_decode from 'jwt-decode';
import {
  faSave,
  faPlusSquare,
  faUndo,
  faList,
  faEdit,
  faCheckCircle,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import MyToast from "../MyToast";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { useDropzone } from 'react-dropzone';
import { debounce } from "lodash";

const CalendarItem = (props) => {

  const url = process.env.REACT_APP_PUBLIC_BACKEND;
  const { id } = useParams();
  const navigate = useNavigate();
  const [calendarItem, setCalendarItem] = useState();
  const [category, setCategory] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [show, setShow] = useState(null);
  const [methode, setMethode] = useState("success");
  const [change, setChange] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [selectedTime, setSelectedTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [selectedEndTime, setSelectedEndTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
  const [check, setCheck] = useState(props.check ? props.check : false);
  const [ImagePath, setImagePath] = useState([]);

  let initialState = {
    id: "",
    title: "",
    imageUrl: "",
    category: "",
    location: "",
    report: "",
    institutions: "",
    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    date: new Date().toISOString().slice(0, 10),
    enddate: new Date().toISOString().slice(0, 10),
    endtime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    enable: false,
    links: "",
    author: jwt_decode(axios.defaults.headers.Authorization).sub
  };
  const [calendarItemTemp, setCalendarItemTemp] = useState(initialState);
  const [files, setFiles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [linkFields, setLinkFields] = useState([]);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  function resolveBreakpoint(width) {
    if (width < 576) {
      return 1;
    } else if (width >= 576 && width < 768) {
      return 2;
    } else if (width >= 768 && width < 992) {
      return 2;
    } else if (width >= 992 && width < 1200) {
      return 3;
    } else if (width >= 1200 && width < 1440) {
      return 4;
    } else if (width >= 1440) {
      return 5;
    }
  }

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  const handleAddLinkFieldTemp = (val1, val2) => {
    if (linkFields.length < 6) {
      setLinkFields([...linkFields, { value1: val1, value2: val2 }]);
    }
  };
  const handleChangeLinkFieldsTemp = (index, inputIndex, e) => {
    const newFields = [...linkFields];
    newFields[index][`value${inputIndex}`] = e.target.value;
    setLinkFields(newFields);
  };
  function handleGenerateString() {
    const filledFields = linkFields.filter((field) => field.value1 && field.value2);
    const fieldString = filledFields.map((field) => `${field.value1}|${field.value2}`).join(',');
    return fieldString;
  };
  const handleGenerateFields = (string) => {
    if (string.length > 0) {
      const newFields = string.split(',').map((fieldString) => {
        const [value1, value2] = fieldString.split('|');
        return { value1, value2 };
      });
      setLinkFields(newFields);
    }
    else { setLinkFields([]); }
  };
  const handleSelect = (e) => {
    if (!selectedOptions.includes(e)) {
      if (selectedOptions.length > 0) setCalendarItemTemp({ ...calendarItemTemp, institutions: selectedOptions + "," + e });
      else { setCalendarItemTemp({ ...calendarItemTemp, institutions: e }); }
      setSelectedOptions([...selectedOptions, e]);
      setChange(true);
    }
  }
  const handleDeselect = (e) => {
    const arg = selectedOptions.filter(option => option !== e);
    setSelectedOptions(selectedOptions.filter(option => option !== e));
    setCalendarItemTemp({ ...calendarItemTemp, institutions: arg.join(",") });
    setChange(true);
  }

  const handleSelectCat = (e) => {
    if (!calendarItemTemp.category !== e) {
      setCalendarItemTemp({ ...calendarItemTemp, category: e });
      setChange(true);
    }
  }
  const handleDeselectCat = () => {
    setCalendarItemTemp({ ...calendarItemTemp, category: "" });
    setChange(true);
  }


  useEffect(() => {
    console.log('tempCalendar', calendarItemTemp);
    console.log('calendar', calendarItem);
    console.log('id', id);
    console.log('pathtemp', linkFields);
  });

  useEffect(() => {
    if (id != null) {
      setTimeout(() => {
        axios.get(url + "/calendar/" + id)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            if (data.urls) {
              if (data.imageUrl.length > 0) setImagePath(data.imageUrl.split(","));
              setCalendarItem(data);
              setCalendarItemTemp(data);
            }
            else { setCalendarItem({ ...data, imageUrl: "" }); setCalendarItemTemp({ ...data, imageUrl: "" }); }
            if (data.institutions.length > 0) setSelectedOptions(data.institutions.split(","));
            if (data.imageUrl.length > 0) setImagePath(data.imageUrl.split(","));

            if (data.links.length > 0) {
              handleGenerateFields(data.links);
            }

            setSelectedDate(data.date);
            setSelectedTime(data.time);
            setSelectedEndDate(data.enddate);
            setSelectedEndTime(data.endtime);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 500);
    }
    setTimeout(() => {
      axios.get(url + "/calendar/category")
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setCategory(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);
    setTimeout(() => {
      axios.get(url + "/calendar/institutions")
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .then((data) => {
          setInstitutions(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);
  }, []);

  function handleCloseOld(index) {
    const updatedImages = [...ImagePath];
    updatedImages.splice(index, 1);
    setImagePath(updatedImages);
    setCalendarItemTemp({ ...calendarItemTemp, imageUrl: updatedImages.join(",") })
    setChange(true);
  };
  function handleCloseClick(index) {
    const updatedImages = [...files];
    updatedImages.splice(index, 1);
    setFiles(updatedImages);
    setChange(true);
  };
  function handleTimeChange(event) {
    setSelectedEndTime(event.target.value);
    setCalendarItemTemp({ ...calendarItemTemp, endtime: event.target.value });
    setChange(true);
    setSelectedTime(event.target.value);
    setCalendarItemTemp({ ...calendarItemTemp, time: event.target.value });
  };
  function handleDateChange(event) {
    setChange(true);
    setSelectedDate(event.target.value);
    setSelectedEndDate(event.target.value);
    setCalendarItemTemp({ ...calendarItemTemp, date: event.target.value, enddate: event.target.value })
  };
  function handleEndTimeChange(event) {
    setChange(true);
    setSelectedEndTime(event.target.value);
    setCalendarItemTemp({ ...calendarItemTemp, endtime: event.target.value })
  };
  function handleEndDateChange(event) {
    setChange(true);
    setSelectedEndDate(event.target.value);
    setCalendarItemTemp({ ...calendarItemTemp, enddate: event.target.value })
  };
  function onReset() {
    try {
      if (id) {
        setCalendarItemTemp(calendarItem);
        setChange(false);
        setFiles([]);
        setSelectedOptions(calendarItem.institutions.split(","));
        if (calendarItem.imageUrl.length > 1) {
          setImagePath(calendarItem.imageUrl.split(","));
        }
        setSelectedDate(calendarItem.date);
        setSelectedTime(calendarItem.time);
        setSelectedEndDate(calendarItem.endate);
        setSelectedEndTime(calendarItem.endtime);
        handleGenerateFields(calendarItem.links);
        setTimeout(() => {
          setShow(null);
        }, 3000);
      }
      else {
        setCalendarItemTemp(initialState);
        setChange(false);
        setFiles([]);
        setImagePath([]);
        handleGenerateFields("");
        setSelectedDate(new Date().toISOString().slice(0, 10));
        setSelectedTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        setSelectedEndDate(new Date().toISOString().slice(0, 10));
        setSelectedEndTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        setSelectedOptions([]);
        setChange(false);
        setTimeout(() => {
          setShow(null);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function updateCalendarItem() {
    const reportup = { ...calendarItemTemp, links: handleGenerateString() };
    setCalendarItemTemp({ ...calendarItemTemp, links: handleGenerateString() });
    var count1 = 0;
    if (calendarItem.imageUrl.length > 0) {
      const urls = ImagePath.join(",");
      calendarItem.imageUrl.split(",").map((img, index) => {
        count1++;
        if (urls.indexOf(img) === -1) {
          setTimeout(() => {
            axios.delete(url + "/calendar/image/" + calendarItemTemp.id + "/" + img)
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
                setMethode("danger");
                setShow("calendarItem picture updated failed");
              }, 1000 + count1 * 500);
          })
        }
      });
    }

    if (files) {
      var count2 = 0;
      files.forEach(async (img) => {
        const formData = new FormData();
        count2++;
        setTimeout(async () => {
          const compressedFile = await compressImage(img);
          formData.append("file", compressedFile);
          axios.post(url + "/calendar/" + calendarItemTemp.id + "/image/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
              console.log("upload successfully!")
            })
            .catch((error) => {
              setMethode("danger");
              setShow("Calendar picture updated failed");
              console.log(error);
            });
        }, 1000 + count2 * 1000);
      });
    }
    setCalendarItem({ ...calendarItemTemp, links: handleGenerateString() });
    setMethode("put");
    setTimeout(() => {
      axios
        .put(url + "/calendar", reportup)
        .then((response) => {
          setMethode("success");
          setShow("CalendarItem updated successfully");
        })
        .catch((error) => {
          setMethode("danger");
          setShow("Report update failed");
          console.log(error);
        });
    }, 1000);
    setTimeout(() => {
      setShow(null);
      navigate("/calendar-list");
    }, 3000);
  }

  async function compressImage(image) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    try {
      const compressedFile = await imageCompression(image, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  function submitCalendarItem() {
    const report = { ...calendarItemTemp, id: generateUuid(), links: handleGenerateString() };
    console.log(report);
    setCalendarItem(calendarItemTemp);
    axios
      .post(url + "/calendar", report)
      .then((response) => {
        setShow(true);
      })
      .catch((error) => {
        setShow(null);
        console.log(error);
      });

    if (files) {
      var count = 0;
      files.forEach(async (img) => {
        const formData = new FormData();
        count++;
        setTimeout(async () => {
          const compressedFile = await compressImage(img);
          formData.append("file", compressedFile);
          axios.post(url + "/calendar/" + report.id + "/image/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
              console.log("upload Image " + count + " successfully!");
              setShow("CalendarItem submitted successfully");
              setMethode("success");
            })
            .catch((error) => {
              console.log(error);
            });
        }, 1000 + count * 1000);
      });

      setTimeout(() => {
        setShow(null);
        onReset();
      }, 3000);
    }
  }
  function generateUuid() {
    var uid = calendarItemTemp.date + calendarItemTemp.time + Math.floor(Math.random() * 100);
    uid = uid.toString();
    uid = uid.replace("-", "");
    uid = uid.replace(":", "");
    uid = uid.replace("-", "");
    return uid;
  }

  function Dropzone() {
    const onDrop = useCallback(async acceptedFiles => {
      const compressedFiles = await Promise.all(
        acceptedFiles.map(file => compressImage(file))
      );
      setFiles(files.concat(compressedFiles));
      setChange(true);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Hier hinneinziehen ...</p> :
            <p>Hier Bilder hineinziehen oder klicken zum Auswählen</p>
        }
      </div>
    )
  }
  return (<>
    {size > 2 ? (<><br></br><br></br><br></br><br></br></>) : null}
    <Container fluid>
      <div style={{ display: show ? "block" : "none" }}>
        <MyToast
          show={show ? true : false}
          message={
            show
          }
          type={methode}
          onClose={() => setShow(null)} dismissible
        />
      </div>
      <Card className={"border border-dark bg-dark text-white"} style={{ margin: '1%' }}>
        <Card.Header>
          <FontAwesomeIcon icon={id ? faEdit : faPlusSquare} />{" "}
          {id ? "Aktualisierung Kalendareintrag" : "Neuen Kalendareintrag hinzufügen"}
        </Card.Header>
        <Form style={{ backgroundColor: '#343a40', color: 'white' }}
          onReset={onReset}

          id="bookFormId"
        >
          <Card.Body>
            <Row>
              <Form.Group as={Col} controlId="formGridTitle">
                <Form.Label>Titel</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="test"
                  name="title"
                  value={calendarItemTemp.title}
                  onChange={(e) => {
                    setChange(true);
                    setCalendarItemTemp({ ...calendarItemTemp, title: e.target.value })
                  }}
                  className={check ? (calendarItemTemp.location.length < 2 || calendarItemTemp.location.length > 250 ? "border-danger bg-dark text-white" : "bg-dark text-white") : "bg-dark text-white"}
                  placeholder="Titel hier eingeben"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridAuthor">
                <Form.Label>Ort</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="test"
                  name="location"
                  value={calendarItemTemp.location}
                  onChange={(e) => {
                    setChange(true);
                    setCalendarItemTemp({ ...calendarItemTemp, location: e.target.value })
                  }}
                  className={check ? (calendarItemTemp.location.length < 2 || calendarItemTemp.location.length > 250 ? "border-danger bg-dark text-white" : "bg-dark text-white") : "bg-dark text-white"}
                  placeholder="Ort hier eingeben"
                />
              </Form.Group>
            </Row>
            <br></br>
            <Row>
              <Form.Group as={Col} controlId="formCalendar">
                <Form.Label>   Datum  </Form.Label>

                <InputGroup>
                  <FormControl className={check ? (calendarItemTemp.date.length < 2 ? "border-danger bg-secondary text-white" : "bg-secondary text-white") : "bg-secondary text-white"} type="date" value={selectedDate}
                    placeholder="dd.mm.yyyy" onChange={handleDateChange} />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} controlId="formTime">
                <Form.Label >   Zeit  </Form.Label>
                <InputGroup>
                  <FormControl className={check ? (calendarItemTemp.time.length < 2 ? "border-danger bg-secondary text-white" : "bg-secondary text-white") : "bg-secondary text-white"} type="time" value={selectedTime} onChange={handleTimeChange} />
                </InputGroup>

              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formEndCalendar">
                <Form.Label>   Enddatum  </Form.Label>

                <InputGroup>
                  <FormControl className={check ? (calendarItemTemp.enddate.length < 2 ? "border-danger bg-secondary text-white" : "bg-secondary text-white") : "bg-secondary text-white"} type="date" value={selectedEndDate}
                    placeholder="dd.mm.yyyy" onChange={handleEndDateChange} />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} controlId="formEndTime">
                <Form.Label >   Endzeit  </Form.Label>
                <InputGroup>
                  <FormControl className={check ? (calendarItemTemp.endtime.length < 2 ? "border-danger bg-secondary text-white" : "bg-secondary text-white") : "bg-secondary text-white"} type="time" value={selectedEndTime} onChange={handleEndTimeChange} />
                </InputGroup>

              </Form.Group>
            </Row>
            <br></br>
            <Row>
              <Form as={Col}>
                <Form.Label>Beteiligte Feuerwehren</Form.Label>
                <Dropdown onSelect={handleSelect}>
                  <Dropdown.Toggle className={check ? (calendarItemTemp.institutions.length < 2 ? "border-danger" : "") : ""} variant="secondary" id="dropdown-basic">
                    {selectedOptions !== null ? "Option auswählen" : selectedOptions.join(', ')}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {institutions.map(option => (
                      <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Text>
                  {selectedOptions.map(option => (
                    <div key={option} onClick={() => handleDeselect(option)} style={{ texborderColor: 'red', borderRadius: "5px", padding: '1px', margin: '2px', border: 'solid white 1px', cursor: 'pointer' }}>
                      &emsp;{option} &times;
                    </div>
                  ))}
                </Form.Text>
              </Form>
              <Form as={Col}>
                <Form.Label>Kategorie</Form.Label>
                <Dropdown onSelect={handleSelectCat}>
                  <Dropdown.Toggle className={check ? (calendarItemTemp.category.length < 2 ? "border-danger" : "") : ""} variant="secondary" id="dropdown-basic">
                    {calendarItemTemp.category !== null ? "Option auswählen" : selectedOptions.join(', ')}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {category.map(option => (
                      <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Text>
                  {calendarItemTemp.category ?
                    <div key={1} onClick={() => handleDeselectCat()} style={{ borderColor: 'red', borderRadius: "5px", padding: '1px', margin: '2px', border: 'solid white 1px', cursor: 'pointer' }}>
                      &emsp;{calendarItemTemp.category} &times;
                    </div>
                    : null}
                </Form.Text>
              </Form>
            </Row>
            <br></br>
            <Row>
              <Form.Group as={Col} controlId="formGridPrice">
                <Form.Label>Beschreibung<a style={{ fontWeight: 'lighter', fontSize: 'small' }}>({calendarItemTemp.report.length}/1000)</a></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={15}
                  required
                  autoComplete="off"
                  type="test"
                  name="report"
                  value={calendarItemTemp.report}
                  onChange={(e) => {
                    setChange(true);
                    setCalendarItemTemp({ ...calendarItemTemp, report: e.target.value })
                  }}
                  className={check ? (calendarItemTemp.report.length < 5 || calendarItemTemp.report.length > 2499 ? "border-danger bg-dark text-white" : "bg-dark text-white") : "bg-dark text-white"}
                  placeholder="Berichttext hier eingeben"
                />
              </Form.Group>
            </Row>

            <br></br>
            Bilder
            <br></br>
            <Row style={{ border: 'solid white 1px', background: 'black', borderRadius: "6px", margin: '2px', marginTop: '8px' }}>
              <Dropzone />

            </Row>
            <div style={{ overflowX: "auto", marginTop: '20px' }}>
              <Row className="flex-nowrap">
                {
                  files.map((img, index) => (
                    <Col key={index} className="mb-3" style={{ width: "100%" }}>
                      <div style={{ width: "200px" }}>
                        <Button
                          variant="danger"
                          className="p-0"
                          style={{ position: "absolute", right: "1", top: "0" }}
                          onClick={() => handleCloseClick(index)}
                        >
                          &emsp;&times;&emsp;
                        </Button>
                        <Image
                          className="imagestyle"
                          src={URL.createObjectURL(img)}
                        />
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
            <div style={{ overflowX: "auto", marginTop: '20px' }}>
              <Row className="flex-nowrap">
                {
                  ImagePath.map((img, index) => (
                    <Col key={index} className="mb-3" style={{ width: "100%" }}>
                      <div style={{ width: "200px" }}>
                        <Button
                          variant="danger"
                          className="p-0"
                          style={{ position: "absolute", right: "1", top: "0" }}
                          onClick={() => handleCloseOld(index)}
                        >
                          &emsp;&times;&emsp;
                        </Button>
                        <Image className="imagestyle"
                          src={url + "/calendar/image/download/" + calendarItemTemp.id + "/" + img}
                        />
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
            <br></br>
            Links
            <Button
              variant="transparent"
              className=""
              onClick={() => (handleAddLinkFieldTemp('', ''))}
            >
              <FontAwesomeIcon icon={faPlusSquare} style={{ color: "white" }} />
            </Button>
            <br></br>
            {linkFields.length > 0 ? (
              linkFields.map((field, index) => (
                <Row>
                  <Form.Group as={Col} controlId={index}>
                    <Form.Label>Anzeigetext</Form.Label>
                    <Form.Control
                      required
                      autoComplete="off"
                      type="test"
                      name="title"
                      value={field.value1}
                      onChange={(e) => {
                        setChange(true);
                        handleChangeLinkFieldsTemp(index, 1, e);
                      }}
                      className={"bg-dark text-white"}
                      placeholder="Hier Titel für Link hinzufügen"
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId={index}>
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      required
                      autoComplete="off"
                      type="test"
                      name="title"
                      value={field.value2}
                      onChange={(e) => {
                        setChange(true);
                        handleChangeLinkFieldsTemp(index, 2, e);
                      }}
                      className={"bg-dark text-white"}
                      placeholder="Hier Link hinzufügen z.B: https://www.google.de"
                    />
                  </Form.Group>
                </Row>
              ))) : null}



          </Card.Body>



          <Card.Footer style={{ textAlign: "right" }}>
            {axios.defaults.headers.Authorization ? (jwt_decode(axios.defaults.headers.Authorization).sub === "ADMiN" || jwt_decode(axios.defaults.headers.Authorization).sub === calendarItemTemp.author ? (
              <Button onClick={id ? updateCalendarItem : submitCalendarItem} size="sm" variant="success" disabled={change === false || calendarItemTemp.title.length > 250 || calendarItemTemp.location.length > 250 || calendarItemTemp.report.length > 1999}>
                <FontAwesomeIcon icon={faSave} />{" "}
                {id ? "Aktualisieren" : "Speichern"}
              </Button>) : null) : null}{" "}
            <Button size="sm" variant="info" type="reset" disabled={change === false}>
              <FontAwesomeIcon icon={faUndo} /> Reset
            </Button>{" "}
            <Button size="sm" variant="warning" onClick={(e) => { check === false ? setCheck(true) : setCheck(false) }}>
              <FontAwesomeIcon icon={check ? faCheckDouble : faCheckCircle} /> Prüfen
            </Button>{" "}
            <Button
              size="sm"
              variant="info"
              type="button"
              onClick={() => navigate("/calendar-list")}
            >
              <FontAwesomeIcon icon={faList} /> Übersicht Kalendareinträge
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </Container>



    <br></br>
    <br></br>
  </>
  );
}
export default CalendarItem;
