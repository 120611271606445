import { faBell, faPeopleGroup, faTruckMedical, faCircleInfo, faNewspaper, faPeopleRoof, faHandsHoldingChild, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Toast, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function PopUp() {
    const [showToast, setShowToast] = useState(false);
    const [isRinging, setIsRinging] = useState(true);
    const [isBellLoaded, setIsBellLoaded] = useState(false);
    const [latestMissions, setLatestMissions] = useState([]);
    const [latestReports, setLatestReports] = useState([]);
    const navigate = useNavigate();
    const url = process.env.REACT_APP_PUBLIC_BACKEND;

    const toggleToast = () => {
        setShowToast(!showToast);
        setIsRinging(false); // Stop the ringing animation when toast is shown
    };

    useEffect(() => {
        // Simulate loading the bell icon and sliding in
        setTimeout(() => {
            setIsBellLoaded(true);
        }, 1000);

        // Fetch the latest missions and reports
        fetchLatestMissions();
        fetchLatestReports();
    }, []);
  
    const fetchLatestMissions = async () => {
        try {
          const response = await axios.get(url+"/missions/public?pageNumber="+0+"&pageSize="+2+"&sortBy=date&sortDir="+"asc");
            setLatestMissions(response.data.content);
        } catch (error) {
            console.error('Error fetching latest missions:', error);
        }
    };

    const fetchLatestReports = async () => {
        try {
          const response = await axios.get(url+"/report/public?pageNumber="+0+"&pageSize="+2+"&sortBy=date&sortDir="+"asc");
            setLatestReports(response.data.content);
        } catch (error) {
            console.error('Error fetching latest reports:', error);
        }
    };

    const getReportIcon = (category) => {
        switch (category) {
            case 'Jahreshauptversammlung':
                return faPeopleRoof;
            case 'Dienstbericht':
                return faPersonChalkboard;
            case 'Jugendfeuerwehr':
                return faHandsHoldingChild;
            case 'News':
                return faNewspaper;
            default:
                return faCircleInfo;
        }
    };

    return (
        <div className={`side-toast-container ${isBellLoaded ? 'bell-loaded' : ''}`}>
            {/* The bell icon with sliding and continuous ringing animation */}
            <div className='poppi'>
                <button className={`bell-button ${isRinging ? 'ring' : ''}`} onClick={toggleToast}>
                    {isBellLoaded && <FontAwesomeIcon icon={faBell} />}&nbsp;Neu!
                </button>
            </div>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                className="side-toast"
                animation={false} // Disable built-in Bootstrap animations
            >
                <Toast.Header closeButton={false}>
                    <strong className="mr-auto">Updates</strong>
                </Toast.Header>
                <Toast.Body>
                    {/* Add your content for displaying the newest posts here */}
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {latestReports.map((report, index) => (
                            <li key={index} style={{ marginBottom: '2%' }}>
                                <a type="button" className="clicky" onClick={() => { navigate(`/reportPage/reportDetails/${report.id}`) }}>
                                    <FontAwesomeIcon icon={getReportIcon(report.category)} />&nbsp;{report.title}&nbsp;<FontAwesomeIcon icon={getReportIcon(report.category)} />
                                </a>
                            </li>
                        ))}
                        {latestMissions.map((mission, index) => (
                            <li key={index} style={{ marginBottom: '2%' }}>
                                <a type="button" className="clicky" onClick={() => { navigate(`/missionPage/missionDetails/${mission.id}`) }}>
                                    <FontAwesomeIcon icon={faTruckMedical} />&nbsp;{mission.title}&nbsp;<FontAwesomeIcon icon={faTruckMedical} />
                                </a>
                            </li>
                        ))}
                    </ul>
                </Toast.Body>
            </Toast>
        </div>
    );
}

export default PopUp;
