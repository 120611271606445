import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';



const TimelineItem = ({ year, text, image, link }) => (
    <div className="timeline-item">
      <div className="timeline-year">{year}</div>
      <div className="timeline-content">
        <p>{text}</p>
      </div>
      <img src={image} alt={`Image for ${year}`} className="timeline-image" />
    </div>
  );
  

const TimeLine = (props)  => (
  <div className="container">
    <div className="timeline">
      {props.timelineData?(props.timelineData.map((item, index) => (
        <TimelineItem
          key={index}
          year={item.year}
          text={item.text}
          image={item.image}
          link={item.link}
        />
      ))):null}
    </div>
  </div>
);

export default TimeLine;
