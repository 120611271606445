import React, { useEffect, useState } from "react";
import debounce from 'debounce';
import { Badge, Card, CardGroup, Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faClockFour,
  faCalendarDay,
  faClipboardList,
  faFireExtinguisher,
  faTruckMedical,
  faFireAlt,
  faPersonChalkboard,
  faFireBurner,
  faHouseFire,
  faDumpsterFire,
  faHouseLock,
  faHouseFloodWater,
  faExplosion,
  faHeartCircleBolt,
  faSprayCanSparkles,
  faCarBurst,
  faSuitcaseMedical,
  faPersonSwimming,
  faOilCan,
  faRoadCircleExclamation,
  faTree,
  faTruckArrowRight,
  faScrewdriverWrench,
  faWrench,
  faFire,
  faCircleRadiation,
  faRadiation,
  faTowerCell,
  faCat,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function MissionCarousel(props) {
  const url= process.env.REACT_APP_PUBLIC_BACKEND;
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const [missions, setMissions]=useState([]);
	const navigate=useNavigate();
  const [updatedMissions, setUpdatedMissions] = useState([]);

  // Async function to fetch image URL
  async function findexamplePic(missionCat) {
    try {
      const response = await axios.get(`${url}/image/search/${missionCat}?page=0&size=1`);
      const data = response.data;
      if (data.content && data.content.length > 0) {
        return `${url}/image/image/download/${data.content[0].id}/${data.content[0].urls.split(",")[0]}`;
      } else {
        return 'path_to_default_image'; // Default image path
      }
    } catch (error) {
      console.error('Error in findexamplePic:', error);
      return 'path_to_default_image'; // Default image path
    }
  }

  // useEffect to fetch images when 'missions' changes
  useEffect(() => {
    const fetchAllImages = async () => {
      const missionsWithImages = await Promise.all(missions.map(async mission => {
        const imageUrl = mission.imageUrl.length > 10 
          ? `${url}/missions/image/download/${mission.id}/${mission.imageUrl.split(",")[0]}`
          : await findexamplePic(mission.category);
        return { ...mission, imageUrl };
      }));

      setUpdatedMissions(missionsWithImages);
    };

    if (missions.length > 0) {
      fetchAllImages();
    }
  }, [missions]);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 3;
    } else if ( width >= 576 && width < 768 ) {
      return 3;
    } else if ( width >= 768 && width < 992 ) {
      return 4;
    } else if ( width >= 992 && width < 1200 ) {
      return 4;
    } else if ( width >= 1200 && width < 1440 ) {
      return 5;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }

  useEffect(()=> {  
    findAllMissions();  
  },[]);
  
  function findAllMissions() {
    axios
      .get(
        url+props.filter
      )
      .then((response) => response.data)
      .then((data) => {
        //console.log(data);
       setMissions(data.content);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);
    const [index, setIndex] = useState(0);
  
        const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    function linkInst(element){
      if(element==="Kubschütz"){
        return("https://www.feuerwehr-kubschuetz.de");
      }
      else if(element==="BF Bautzen"){
        return("https://www.feuerwehr-bautzen.de/");
      }
      else if(element==="FFW Hochkirch"){
        return("https://www.hochkirch.de/einrichtungen/feuerwehr");
      }
      else if(element==="FFW Kleinbautzen-Purschwitz-Preititz"){
        return("https://www.facebook.com/Kleinbautzen.Preititz.Purschwitz/");
      }
      return("https://www.feuerwehr-kubschuetz.de");
    }

    function createPill(string){
      const arr=string.split(",");
       return( 
        arr.map((element,i)=>(        
          <a  href={linkInst(element)} target="_blank" rel="noreferrer" className="cardtext">
        <Badge key={i} className="pillinst bottompill" pill bg="light" text="dark">
        {element!="FFW Kleinbautzen-Purschwitz-Preititz"?(element):"FFW KBZ-PW-PR"}     
      </Badge>        
      </a>
        ))
       )
    }
    function getCatIcon (cat){
      if(cat==="Einsatzübung"){
      return(<FontAwesomeIcon icon={faPersonChalkboard} />)
      }
      else if(cat==="Küchenbrand"){
      return(<FontAwesomeIcon icon={faFireBurner} />)
      }
      else if(cat==="Gebäudebrand"){
      return(<FontAwesomeIcon icon={faHouseFire} />)
      }
      else if(cat==="Mülltonnenbrand"){
      return(<FontAwesomeIcon icon={faDumpsterFire} />)
      }
      else if(cat==="Unratbrand"||cat==="Flächenbrand"||cat==="Fahrzeugbrand"){
      return(<FontAwesomeIcon icon={faFireExtinguisher} />)
      }
      else if(cat==="Tierrettung"){
        return(<FontAwesomeIcon icon={faCat} />)
        }
      else if(cat==="Nottüröffnung"){
      return(<FontAwesomeIcon icon={faHouseLock} />)
      }
      else if(cat==="Hochwasser"){
      return(<FontAwesomeIcon icon={faHouseFloodWater} />)
      }
      else if(cat==="First Responder"){
      return(<><FontAwesomeIcon icon={faSuitcaseMedical} />
      <FontAwesomeIcon icon={faHeartCircleBolt} /></>)
      }
      else if(cat==="Chemieunfall"){
      return(<FontAwesomeIcon icon={faSprayCanSparkles} />)
      }
      else if(cat==="Explosion"){
      return(<FontAwesomeIcon icon={faExplosion} />)
      }
      else if(cat==="Verkehrsunfall"){
      return(<FontAwesomeIcon icon={faCarBurst} />)
      }
      else if(cat==="Wasserrettung"){
      return(<FontAwesomeIcon icon={faPersonSwimming} />)
      }
      else if(cat==="Auslaufende Betriebsstoffe"){
      return(<FontAwesomeIcon icon={faOilCan} />)
      }
      else if(cat==="Baum auf Straße"){
      return(<><FontAwesomeIcon icon={faRoadCircleExclamation} />
      <FontAwesomeIcon icon={faTree} /></>)
      }
      else if(cat==="Amtshilfe"||cat==="Unterstützung Rettungsdienst"){
      return(  <FontAwesomeIcon icon={faTruckMedical} />)
      }
      return(<FontAwesomeIcon icon={faTruckArrowRight} />)
      }
 function getEmrgeIcon (cat){
  if(cat==="H"){
    return(
      <FontAwesomeIcon icon={faWrench} style={{margin:'1px'}}/>)
  }
  else if(cat==="H1"){
    return(<>
      <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
      </>)
  }
  else if(cat==="H2"){
    return(<>
    <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
    <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
    </>)
  }
  else if(cat==="H3"){
    return(<>
      <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
      <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
      <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
      </>)
  }
  else if(cat==="B"){
    return(<FontAwesomeIcon icon={faFireAlt} />)
  }
  else if(cat==="B1"){
    return(<FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>)
  }
  else if(cat==="B2"){
    return(<>
      <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
      <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
      </>)
  }
  else if(cat==="B3"){
    return(<>
      <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
      <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
      <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
      </>)
  }
  else if(cat==="ABC"){
    return(<>
      <FontAwesomeIcon icon={faRadiation} style={{margin:'1px'}}/>
      </>)
  }
  else if(cat==="ABC1"){
    return(<>
      <FontAwesomeIcon icon={faCircleRadiation} style={{margin:'1px'}}/>
      </>)
  }
  else if(cat==="ABC2"){
    return(<>
      <FontAwesomeIcon icon={faCircleRadiation} style={{margin:'1px'}}/>
      <FontAwesomeIcon icon={faCircleRadiation} style={{margin:'1px'}}/>
      </>)
  }
  return(<FontAwesomeIcon icon={faTowerCell} />)
   }
    const cardSlides = missions.map((mission, index) => {
      if (index % size === 0) {
        const cardSlide = updatedMissions.slice(index, index + size);
        return (
          <Carousel.Item>
            <CardGroup className="cradcaro cardtext">
              {cardSlide.map((card, i) => (
      <Card key={i} onClick={() => navigate("/einsaetze/details/"+card.id)}  className={(card.category!==("Einsatzübung"||"Sonstiges")?(card.emergency):("Sonstiges"))+" missionCard"} >
      <div className="top2">
      <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">{card.emergency}</Tooltip>}>
      <Badge pill bg="trasparence" text="dark" className="headpill">
      {getEmrgeIcon(card.emergency)}
        </Badge>
        </OverlayTrigger>
        </div>
        <div className="top1">
        <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">{card.category}</Tooltip>}>
        <Badge pill bg="trasparence" text="dark" className="headpill">
        {getCatIcon(card.category)}
        </Badge>
        </OverlayTrigger>
        </div>
      <div className="img">
      <Card.Img  className={"imageSt"} src={card.imageUrl}
       style={{ height: '100%', width:'100%',border: 'solid black 1px' ,borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}/>
      </div>
      <div className="title">{card.title}</div>
      <div className="protIcon"><FontAwesomeIcon icon={faClipboardList}/></div>
      <div className="prot">{card.protocoll}</div>
      <div className="instIcon"><FontAwesomeIcon icon={faTruckMedical} /></div>
      <div className="inst">{createPill(card.institutions)}</div>
      <div className="locIcon"><FontAwesomeIcon icon={faMapLocationDot} /></div>
      <div className="loc">{card.location}</div>
      <div className="calIcon"><FontAwesomeIcon icon={faCalendarDay} /></div>
      <div className="cal">{new Date(card.date).toLocaleDateString('de-DE')}</div>
      <div className="cloIcon"><FontAwesomeIcon icon={faClockFour} /></div>
      <div className="clo">{card.time}&nbsp;Uhr</div>
      </Card>
              ))}
            </CardGroup>
          </Carousel.Item>
        );
      }
    });
  
    return (
      <div style={{padding:'0.5%'}}>
      <Carousel  key={245} indicators={false} pause="hover" interval={null} activeIndex={index} onSelect={handleSelect} touch >
        {missions?(cardSlides):null}
       
      </Carousel>
      </div>
    );
  }