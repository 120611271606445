import React, { useEffect, useRef, useState } from "react";
import jwt_decode from 'jwt-decode';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faDigitalTachograph,
  faHouseUser,
  faTruckFast,
  faHouseChimney,
  faInfo,
  faHelmetSafety,
  faSmoking,
  faInfoCircle,
  faChildren,
  faNewspaper,
  faCalendar,
  faCalendarCheck,
  faImage,
  faImagePortrait,
  faFireFlameSimple,
  faPlus,
  faCropSimple,
  faShuffle,
  faCloud,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { debounce } from "debounce";


export default function NavigationBar() {
  const url= process.env.REACT_APP_PUBLIC_BACKEND;
  const [icon, setIcon] = useState();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const navigate=useNavigate();

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 3;
    } else if ( width >= 992 && width < 1200 ) {
      return 4;
    } else if ( width >= 1200 && width < 1440 ) {
      return 5;
    } else if ( width >= 1440 ) {
      return 6;
    }
  }
  useEffect(() => {
    axios
    .get(
      url+"/image/search/" +
      "$Icon*" +
        "?page=" +
        0 +
        "&size=" +
        1
    )
    .then((response) => response.data)
    .then((data) => {
        setIcon({id: data.content[0].id, url: data.content[0].urls.split(",")[0]});  
    })
    .catch((error) => {
      console.log(error);
    });
	}, []);

  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible]);


  const guestLinks = (
    <>
      <div className="mr-auto"></div>
      <Nav className="navbar-right ">
        <NavDropdown className="navLinko navLinkos" title={<>&nbsp;<FontAwesomeIcon icon={faHouseChimney} />&nbsp;{(size>4||size<2)?("ÜBER UNS"):(<>&nbsp;&nbsp;</>)}</>}>
            <NavDropdown.Item className="navbarColor">
                <Link to={"Ortswehr-Kubschütz"} >
                &nbsp;<FontAwesomeIcon icon={faHouseUser} /> Ortswehr Kubschütz
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"Ortswehr-Rachlau"}>
                &nbsp;<FontAwesomeIcon icon={faHouseUser} /> Ortswehr Rachlau
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"Ortswehr-Purschwitz"}>
                &nbsp;<FontAwesomeIcon icon={faHouseUser} /> Ortswehr Purschwitz
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"Löschgruppe-Soritz"}>
                &nbsp;<FontAwesomeIcon icon={faHouseUser} /> Löschgruppe Soritz
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>
                <Link to={"Fahrzeugtechnik"} >
                &nbsp;<FontAwesomeIcon icon={faTruckFast} /> Fahrzeugtechnik
                </Link>
            </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown className="navLinko" title={<>&nbsp;&nbsp;<FontAwesomeIcon icon={faInfo} />&nbsp;{(size>5||size<2)?("INFORMATIONEN"):(<>&nbsp;&nbsp;</>)}</>}>
            <NavDropdown.Item>
                <Link to={"Rauchmelder"} >
                &nbsp;<FontAwesomeIcon icon={faSmoking} />&nbsp; Rauchmelder
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"Sicherheit-Brandschutz"}>
                &nbsp; <FontAwesomeIcon icon={faHelmetSafety} /> &nbsp;Brandschutz und Sicherheit
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>
                <Link to={"Knoten"}>
                &nbsp; <FontAwesomeIcon icon={faShuffle} /> &nbsp;Knoten bei der Feuerwehr
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"Gedanken-eines-Feuerwehrmannes"}>
                &nbsp; <FontAwesomeIcon icon={faCloud} /> &nbsp;Gedanken eines Feuerwehrmannes
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"Tod-eines-Unschuldigen"}>
                &nbsp; <FontAwesomeIcon icon={faCloud} /> &nbsp;Tod einer Unschuldigen
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>
                <Link to={"Buergerinformationen"}>
                &nbsp; <FontAwesomeIcon icon={faPeopleGroup} /> &nbsp;Bürgerinformationen
                </Link>
                </NavDropdown.Item>
        </NavDropdown>
        <Link to={"einsaetze"} className="nav-link navLinko">
        &nbsp;<FontAwesomeIcon icon={faDigitalTachograph} /> {(size>4||size<2)?("EINSÄTZE"):(<>&nbsp;&nbsp;</>)}
        </Link>
        <Link to={"kalender"}  className="nav-link navLinko">
        &nbsp;<FontAwesomeIcon icon={faCalendarCheck} /> {(size>4||size<2)?("TERMINE"):(<>&nbsp;&nbsp;</>)}
        </Link>
        <Link to={"aktuelles"} className="nav-link navLinko">
        &nbsp;<FontAwesomeIcon icon={faNewspaper} /> {(size>4||size<2)?("AKTUELLES"):(<>&nbsp;&nbsp;</>)}
        </Link>
        <Link to={"Jugendfeuerwehr"}  className="nav-link navLinko">
        &nbsp;<FontAwesomeIcon icon={faChildren} /> {(size>4||size<2)?("JUGENDFEUERWEHR"):(<>&nbsp;&nbsp;</>)}
        </Link>
        <Link to={"login"} className="nav-link navLinko">
        &nbsp;<FontAwesomeIcon icon={faSignInAlt} /> {(size>5||size<2)?("LOGIN"):(<>&nbsp;&nbsp;</>)}
        </Link>
      </Nav>
    </>
  );
  const userLinks = (
  <>
      <Nav className="mr-auto" >
         <Link to={"missionPage"} className="nav-link">
          {"  "}
        </Link>
        {axios.defaults.headers.Authorization?(jwt_decode(axios.defaults.headers.Authorization).sub!=="JFKubschütz"?(
        <NavDropdown title={<>&nbsp;&nbsp;<FontAwesomeIcon icon={faDigitalTachograph} />&nbsp;{(size>5||size<2)?("EINSÄTZE"):(<>&nbsp;&nbsp;</>)}</>}>
            <NavDropdown.Item>
                <Link to={"mission-list"} >
                &nbsp;<FontAwesomeIcon icon={faDigitalTachograph} />&nbsp; Übersicht Einsätze
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"add-mission"}>
                &nbsp; <FontAwesomeIcon icon={faPlus} /> &nbsp;Einsatz hinzufügen
                </Link>
            </NavDropdown.Item>
        </NavDropdown>):null):null}
        {axios.defaults.headers.Authorization?(jwt_decode(axios.defaults.headers.Authorization).sub==="ADMiN"?(
        <NavDropdown title={<>&nbsp;&nbsp;<FontAwesomeIcon icon={faImage} />&nbsp;{(size>5||size<2)?("BILDER"):(<>&nbsp;&nbsp;</>)}</>}>
            <NavDropdown.Item>
                <Link to={"image-list"} >
                &nbsp;<FontAwesomeIcon icon={faImage} />&nbsp; Übersicht Bilder
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"add-image"}>
                &nbsp; <FontAwesomeIcon icon={faPlus} /> &nbsp;Bilder hinzufügen
                </Link>
            </NavDropdown.Item>
        </NavDropdown>):null):null}
        <NavDropdown title={<>&nbsp;&nbsp;<FontAwesomeIcon icon={faNewspaper} />&nbsp;{(size>5||size<2)?("BERICHTE"):(<>&nbsp;&nbsp;</>)}</>}>
            <NavDropdown.Item>
                <Link to={"report-list"} >
                &nbsp;<FontAwesomeIcon icon={faNewspaper} />&nbsp; Übersicht Berichte
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"add-report"}>
                &nbsp; <FontAwesomeIcon icon={faPlus} /> &nbsp;Bericht hinzufügen
                </Link>
            </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title={<>&nbsp;&nbsp;<FontAwesomeIcon icon={faCalendarCheck} />&nbsp;{(size>5||size<2)?("KALENDER"):(<>&nbsp;&nbsp;</>)}</>}>
            <NavDropdown.Item>
                <Link to={"calendar-list"} >
                &nbsp;<FontAwesomeIcon icon={faCalendarCheck} />&nbsp; Übersicht Kalendereinträge
                </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <Link to={"add-calendar"}>
                &nbsp; <FontAwesomeIcon icon={faPlus} /> &nbsp;Kalendereintrag hinzufügen
                </Link>
            </NavDropdown.Item>
        </NavDropdown>
        <Link to={""} className="nav-link">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Link>
      </Nav>
      <Nav className="navbar-right">
        <Link to={"logout/m"} className="nav-link" >
          <FontAwesomeIcon icon={faSignOutAlt} /> {(size>5||size<2)?("LOGOUT"):(<>&nbsp;</>)}
        </Link>
      </Nav>
      </>
  );

  return (
    <>
    {size>3?(
    <>
    {visible? (
    <Navbar variant="dark"  className="fixed-top" style={{zIndex: 3,   top: '15px'}} expand='sm'>
      <Link to={axios.defaults.headers.Authorization ? "home" : ""} className={"altdeutsch"}>
      {icon?(<img 
          src={url+"/image/image/download/"+icon.id+"/"+icon.url}
          width="90"
          height="90"
          onClick={()=>navigate("/")}
          alt="brand"
          top="150px"
          left= "100px"
          style={{  top:"-12px", left: "10px"}}
         
          className=" position-absolute"
        />):null}
      <div className="navText" onClick={()=>navigate("/")}>&emsp;&emsp;&emsp;&ensp;Feuerwehr Kubschütz</div>
      </Link>
      <Navbar.Toggle aria-controls='my-nav' style={{ top: '55px',backgroundColor:"#b71e10",zIndex: 3}}/>
      <Navbar.Collapse id='my-nav' style={{ top: '55px'}}>
      {axios.defaults.headers.Authorization ?  userLinks : guestLinks}
      </Navbar.Collapse>
    </Navbar>):null}
    </>):(<>
    {visible? (
    <Navbar variant="dark" className="fixed-top" expand='sm'>
      <Link to={axios.defaults.headers.Authorization ? "home" : ""} className={"altdeutsch"}>
      {icon?(<img
          src={url+"/image/image/download/"+icon.id+"/"+icon.url}
          width="50"
          height="50"   
          left= "100px"
          style={{  top:"3px", left: "10px"}}
          onClick={()=>navigate("/")}
          className="position-absolute"
        />):null}
      <div className="navText" onClick={()=>navigate("/")}>&emsp;&emsp;&ensp;{size==3||size==1?("Feuerwehr Kubschütz"):null}</div>
      </Link>
      <Navbar.Toggle aria-controls='my-nav' style={{ top: '55px',backgroundColor:"#b71e10",zIndex: 3}}/>
      <Navbar.Collapse id='my-nav' style={{ top: '55px',zIndex: 1}}>
      {axios.defaults.headers.Authorization ?  userLinks : guestLinks}
      </Navbar.Collapse>
    </Navbar>):null}
    </>)}
    </>
  );
}


