import React, { useEffect, useState } from "react";
import jwt_decode from 'jwt-decode';
import {
  Card,
  Table,
  Image,
  ButtonGroup,
  Button,
  InputGroup,
  FormControl,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faEdit,
  faTrash,
  faStepBackward,
  faFastBackward,
  faStepForward,
  faFastForward,
  faSearch,
  faTimes,
  faEyeLowVision,
  faEye,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import MyToast from "../MyToast";
import axios from "axios";
import { debounce } from "lodash";

export default function ReportList(){
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const navigate=useNavigate();
  const [show, setShow]=useState(false);
  const [reports, setReports]=useState([]);
  const [search, setSearch]=useState();
  const [totalElements, setTotalElements]=useState();
  const [totalPages, setTotalPages]=useState();
  const [currentPage, setCurrentPage]=useState(1);
  const [reportsPerPage, setReportsPerPage]=useState(5);
  const [sortDir, setSortDir]=useState("desc");
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  let initialState = {
    id: "",
    title: "",
    protocoll: "",
    imageUrl:"",
    category:"",
    emergency:"",
    location:"",
    report:"",
    map:"",
    institutions:"",
    time: "",
    date: "",
    enable: false,
    links: "",
    person: "",
    author: ""
  };
  const [missionTemp, setMission]=useState(initialState);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  useEffect(()=> {  
    findAllReports(currentPage);  
  },[]);

function sortData(){
  setTimeout(() => {
    sortDir === "asc"
      ? setSortDir("desc")
      : setSortDir("asc");
    findAllReports(currentPage);
  }, 500);
}
  function findAllReports(currentPage) {
    currentPage -= 1;
    if(jwt_decode(axios.defaults.headers.Authorization).sub==="ADMiN"){
    axios
      .get(
        url+"/report?pageNumber=" +
          currentPage +
          "&pageSize=" +
          reportsPerPage +
          "&sortBy=date&sortDir=" +
          sortDir
      )
      .then((response) => response.data)
      .then((data) => {
        //console.log(data);
       setReports(data.content);
       setTotalPages(data.totalPages);
       setTotalElements(data.totalElements);
        setCurrentPage(data.number + 1);     
      })
      .catch((error) => {
        console.log(error);
        axios.defaults.headers.Authorization = null;
        navigate("/");
      });}
      else{
        axios
        .get(
          url+"/report/author/"+jwt_decode(axios.defaults.headers.Authorization).sub+"?pageNumber=" +
            currentPage +
            "&pageSize=" +
            reportsPerPage +
            "&sortBy=date&sortDir=" +
            sortDir
        )
        .then((response) => response.data)
        .then((data) => {
          //console.log(data);
         setReports(data.content);
         setTotalPages(data.totalPages);
         setTotalElements(data.totalElements);
          setCurrentPage(data.number + 1);     
        })
        .catch((error) => {
          console.log(error);
          axios.defaults.headers.Authorization = null;
          navigate("/");
        });
      }
  }

  function deleteReport(id,s){
    setShow(true);
    setTimeout(() => {
    axios
    .delete(url+"/report/" + id)
    .then((response) => {
        findAllReports(currentPage);
    })
    .catch((error) => {
      console.log(error);
    })
  }, 100);
  findAllReports(currentPage);
        setTimeout(() => setShow(false), 3000);
     
  }
function updateReport(report){
    axios
    .put(url+"/report", report)
    .then((response) => {
     console.log(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  
  setTimeout(() => {
  findAllReports(currentPage);
}, 500);
}

  function changePage(event){
    let targetPage = parseInt(event.target.value);
    if (search) {
      searchData(targetPage);
    } else {
        findAllReports(targetPage);
    }
    setCurrentPage(targetPage);
   
  }

  function firstPage(){
    let firstPage = 1;
    if (currentPage > firstPage) {
      if (search) {
        searchData(firstPage);
      } else {
        findAllReports(firstPage);
      }
    }
  }

  function prevPage(){
    let prevPage = 1;
    if (currentPage > prevPage) {
      if (search) {
        searchData(currentPage - prevPage);
      } else {
        findAllReports(currentPage - prevPage);
      }
    }
  }

  function lastPage(){
    let condition = Math.ceil(
      totalElements / reportsPerPage
    );
    if (currentPage < condition) {
      if (search) {
        searchData(condition);
      } else {
        findAllReports(condition);
      }
    }
  }

  function nextPage() {
    if (
      currentPage <
      Math.ceil(totalElements / reportsPerPage)
    ) {
      if (search) {
        searchData(currentPage + 1);
      } else {
        findAllReports(currentPage + 1);
      }
    }
  }

  function searchChange(event){
   setSearch(event.target.value);
  }

  function cancelSearch(){
    setSearch("");
    findAllReports(currentPage);
  }

  function searchData (currentPageIs){
    currentPageIs -= 1;
    if(jwt_decode(axios.defaults.headers.Authorization).sub==="ADMiN"){
    axios
      .get(
        url+"/report/search/" +
        search +
          "?page=" +
          currentPageIs +
          "&size=" +
          reportsPerPage
      )
      .then((response) => response.data)
      .then((data) => {
        
          setReports(data.content);
          setTotalPages(data.totalPages);
          setTotalElements(data.totalElements);
          setCurrentPage(data.number + 1);
       
      });}
      else{
        axios
        .get(
          url+"/report/search/"+jwt_decode(axios.defaults.headers.Authorization).sub+"/" +
          search +
            "?page=" +
            currentPageIs +
            "&size=" +
            reportsPerPage
        )
        .then((response) => response.data)
        .then((data) => {
          
            setReports(data.content);
            setTotalPages(data.totalPages);
            setTotalElements(data.totalElements);
            setCurrentPage(data.number + 1);
         
        });
      }
  }

  
    return (
      <>
        {size>2?(<><br></br><br></br><br></br><br></br></>):null}
      <div>
        <div style={{ display: show ? "block" : "none" }}>
          <MyToast
            show={show}
            message={"Report Deleted Successfully."}
            type={"danger"}
          />
        </div>
        <Card className={"border border-dark bg-dark text-white"} style={{margin: '1%'}}>
          <Card.Header>
            <div style={{ float: "left" }}>
              <FontAwesomeIcon icon={faList} /> Übersicht Berichte
            </div>
            <div style={{ float: "right" }}>
              <InputGroup size="sm" style={{zIndex:'0'}}>
                <FormControl
                  placeholder="Search"
                  name="search"
                  value={search}
                  className={"info-border bg-dark text-white"}
                  onChange={searchChange}
                />
              
                  <Button
                    size="sm"
                    variant="outline-info"
                    type="button"
                    onClick={searchData}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    type="button"
                    onClick={cancelSearch}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
               
              </InputGroup>
            </div>
          </Card.Header>
          <Card.Body>
          <div style={{ overflowX: "auto", marginTop:'20px', padding:'1%'}}>
               <Row className="flex-nowrap">
            <Table bordered hover striped variant="dark">
              <thead>
                <tr>
                  <th>Titel</th>
                  <th>Kategorie</th>
                  <th onClick={sortData}>
                    Datum{" "}
                    <div
                      className={
                        sortDir === "asc"
                          ? "arrow arrow-up"
                          : "arrow arrow-down"
                      }
                    >
                      {" "}
                    </div>
                  </th>
                  <th>Zeit</th>
                  <th>Ort</th>
                  <th>Beteiligte Wehr</th>
                  <th>Aktionen</th>
                </tr>
              </thead>
              <tbody>
                {reports.length === 0 ? (
                  <tr align="center">
                    <td colSpan="7">Keine Berichte vorhanden.</td>
                  </tr>
                ) : (
                  reports.map((mission) => (
                    <tr key={mission.id}>
                      <td>
                        <Image
                         src={mission.imageUrl?(url+"/report/image/download/"+mission.id+"/"+mission.imageUrl.split(",")[0]):null}
                          roundedCircle
                          width="25"
                          height="25"
                        />{" "}
                        {mission.title}
                      </td>
                      <td>{mission.category}</td>
                      <td>{new Date(mission.date).toLocaleDateString('de-DE')}</td>
                      <td>{mission.time}</td>
                      <td>{mission.location}</td>
                      <td>{mission.institutions}</td>
                      <td>
                        <ButtonGroup>
                        <Button
                            size="sm"
                            variant="outline-warning"
                            data-bs-toggle={mission.enable?null:"modal"} 
                            data-bs-target={mission.enable?null:"#myModal"}
                            onClick={(e)=>{ 
                              setMission(mission);
                              if(mission.enable)
                              updateReport({...mission, enable: false});
                            }}
                          >
                            {mission.enable?<FontAwesomeIcon icon={faEye} />:
                            <FontAwesomeIcon icon={faEyeLowVision} />}
                          </Button>{"  "}
                          <Link
                            to={"edit/" + mission.id}
                            className="btn btn-sm btn-outline-primary"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Link>{"  "}
                          <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={(e)=>{setMission(mission)}}
                            data-bs-toggle={"modal"} 
                            data-bs-target={"#deleteModal"}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </ButtonGroup>
                        <div class="modal" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" style={{color: "black"}}>Veröffentlichung Bericht</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body" style={{color: "black"}}>
        <p>Bitte prüfe vor Veröffentlichung:</p>
        <p style={{fontSize:'90%'}}><FontAwesomeIcon icon={faQuestionCircle} /> Text und Bilder dürfen rechtmäßig öffentlich geteilt werden </p>
        <p style={{fontSize:'90%'}}><FontAwesomeIcon icon={faQuestionCircle} /> Rechtschreibung & Grammatik</p>
      </div>
      <div class="modal-footer">
      <button data-bs-dismiss="modal" type="button" onClick={(e)=>{(missionTemp.title.length === 0 || missionTemp.time.length === 0 || missionTemp.location.length === 0 || missionTemp.institutions.length === 0 || missionTemp.category.length === 0 ||missionTemp.date.length === 0||missionTemp.report.length > 2499)?navigate("/report-list/edit/" + missionTemp.id):updateReport({...missionTemp, enable: true})}} class="btn btn-success" >{(missionTemp.title.length === 0 || missionTemp.time.length === 0 || missionTemp.location.length === 0 || missionTemp.institutions.length === 0 || missionTemp.category.length === 0 ||missionTemp.date.length === 0||missionTemp.report.length > 2499)?"Prüfen auf Vollständigkeit":"veröffentlichen"}</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Abbrechen</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="deleteModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" style={{color: "black"}}>Bericht Löschen</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body" style={{color: "black"}}>
        <p>Achtung! Dieser Bericht wird nach Bestätigung unwiderruflich gelöscht!</p>
        <p>Nummer: {missionTemp.id}</p>
      </div>
      <div class="modal-footer">
      <button type="button" data-bs-dismiss="modal" class="btn btn-danger" onClick={(e)=>{deleteReport(missionTemp.id,2)}}>Endgültig Löschen</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Abbrechen</button>
      </div>
    </div>
  </div>
</div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            </Row></div>
          </Card.Body>
          
            <Card.Footer>
              <div style={{ float: "left" }}>
                Seite {currentPage} von {totalPages}
              </div>
              <div style={{ float: "right" }}>
                <InputGroup size="sm">
               
                    <Button
                      type="button"
                      variant="outline-info"
                      disabled={currentPage === 1 ? true : false}
                      onClick={firstPage}
                    >
                      <FontAwesomeIcon icon={faFastBackward} /> Erste
                    </Button>
                    <Button
                      type="button"
                      variant="outline-info"
                      disabled={currentPage === 1 ? true : false}
                      onClick={prevPage}
                    >
                      <FontAwesomeIcon icon={faStepBackward} /> Zurück
                    </Button>
                 
                  <FormControl
                    className={"page-num bg-dark"}
                    name="currentPage"
                    value={currentPage}
                    onChange={changePage}
                  />
               
                    <Button
                      type="button"
                      variant="outline-info"
                      disabled={currentPage === totalPages ? true : false}
                      onClick={nextPage}
                    >
                      <FontAwesomeIcon icon={faStepForward} /> Nächste
                    </Button>
                    <Button
                      type="button"
                      variant="outline-info"
                      disabled={currentPage === totalPages ? true : false}
                      onClick={lastPage}
                    >
                      <FontAwesomeIcon icon={faFastForward} /> Letzte
                    </Button>
                  
                </InputGroup>
              </div>
            </Card.Footer>
         
        </Card>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      </>
    );
  }


