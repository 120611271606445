import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";

const Knoten = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "robinhenke3@web.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$Knoten*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Knoten bei der Feuerwehr
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p>Hier sind einige Knoten, wie sie bei der Feuerwehr verwendet werden. Sie werden so oder unter anderem Namen auch in der Seefahrt genutzt. Allen gemeinsam ist, dass sie hohen Belastungen standhalten und trotzdem leicht wieder zu lösen sind.</p>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{margin:'1%'}}>Achterknoten</p>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[0]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        <p style={{margin:'1%'}}>Halbschlag</p>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[1]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div></div>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{margin:'1%'}}>Pfahlstich </p>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[5]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        <p style={{margin:'1%'}}>Kreuzknoten</p>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[2]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div></div>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{margin:'1%'}}>Mastwurf  </p>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[3]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        <p style={{margin:'1%'}}>Mastwurf gebunden</p>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[4]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div></div>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{margin:'1%'}}>Schotenstich</p>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[6]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
       
        </div></div>
        </div></div> 
         <br></br>
         <br></br>
    </>
  );
};

export default Knoten;
