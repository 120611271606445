import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Image,
  ButtonGroup,
  Button,
  InputGroup,
  FormControl,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faEdit,
  faTrash,
  faStepBackward,
  faFastBackward,
  faStepForward,
  faFastForward,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import MyToast from "../MyToast";
import axios from "axios";
import { debounce } from "lodash";


export default function ImageList(){
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const navigate=useNavigate();
  const [show, setShow]=useState(false);
  const [images, setImages]=useState([]);
  const [search, setSearch]=useState();
  const [totalElements, setTotalElements]=useState();
  const [totalPages, setTotalPages]=useState();
  const [currentPage, setCurrentPage]=useState(1);
  const [imagesPerPage, setImagesPerPage]=useState(5);
  const [sortDir, setSortDir]=useState("desc");
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  useEffect(()=> {  
    findAllImages(currentPage);  
  },[]);

function sortData(){
  setTimeout(() => {
    sortDir === "asc"
      ? setSortDir("desc")
      : setSortDir("asc");
    findAllImages(currentPage);
  }, 500);
}
  function findAllImages(currentPage) {
    console.log("aallll")
    currentPage -= 1;
    axios
      .get(
        url+"/image?pageNumber=" +
          currentPage +
          "&pageSize=" +
          imagesPerPage +
          "&sortBy=name&sortDir=" +
          sortDir
      )
      .then((response) => response.data)
      .then((data) => {
        //console.log(data);
       setImages(data.content);
       setTotalPages(data.totalPages);
       setTotalElements(data.totalElements);
        setCurrentPage(data.number + 1);     
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("jwtToken");
        navigate("/");
      });
  }

  function deleteImages(imageId){
    setShow(true);
    setTimeout(() => {
    axios
    .delete(url+"/image/" + imageId)
    .then((response) => {
      console.log("done")
        findAllImages(currentPage);
    })
    .catch((error) => {
      console.log(error);
    })
  }, 100);
  findAllImages(currentPage);
        setTimeout(() => setShow(false), 3000);
     
  }

  function changePage(event){
    let targetPage = parseInt(event.target.value);
    if (search) {
      searchData(targetPage);
    } else {
        findAllImages(targetPage);
    }
    setCurrentPage(targetPage);
   
  }

  function firstPage(){
    let firstPage = 1;
    if (currentPage > firstPage) {
      if (search) {
        searchData(firstPage);
      } else {
        findAllImages(firstPage);
      }
    }
  }

  function prevPage(){
    let prevPage = 1;
    if (currentPage > prevPage) {
      if (search) {
        searchData(currentPage - prevPage);
      } else {
        findAllImages(currentPage - prevPage);
      }
    }
  }

  function lastPage(){
    let condition = Math.ceil(
      totalElements / imagesPerPage
    );
    if (currentPage < condition) {
      if (search) {
        searchData(condition);
      } else {
        findAllImages(condition);
      }
    }
  }

  function nextPage() {
    if (
      currentPage <
      Math.ceil(totalElements / imagesPerPage)
    ) {
      if (search) {
        searchData(currentPage + 1);
      } else {
        findAllImages(currentPage + 1);
      }
    }
  }

  function searchChange(event){
   setSearch(event.target.value);
  }

  function cancelSearch(){
    setSearch("");
    findAllImages(currentPage);
  }

  function searchData (currentPageIs){
    currentPageIs -= 1;
    axios
      .get(
        url+"/image/search/" +
        search +
          "?page=" +
          currentPageIs +
          "&size=" +
          imagesPerPage
      )
      .then((response) => response.data)
      .then((data) => {
        
          setImages(data.content);
          setTotalPages(data.totalPages);
          setTotalElements(data.totalElements);
          setCurrentPage(data.number + 1);
       
      });
  }

  
    return (
      <>
       {size>1?(<><br></br><br></br><br></br><br></br></>):null}
      <div>
        <div style={{ display: show ? "block" : "none" }}>
          <MyToast
            show={show}
            message={"Images Deleted Successfully."}
            type={"danger"}
          />
        </div>
        <Card className={"border border-dark bg-dark text-white"} style={{margin: '1%'}}>
          <Card.Header>
            <div style={{ float: "left" }}>
              <FontAwesomeIcon icon={faList} /> Image List
            </div>
            <div style={{ float: "right" }}>
              <InputGroup size="sm" style={{zIndex:'0'}}>
                <FormControl
                  placeholder="Search"
                  name="search"
                  value={search}
                  className={"info-border bg-dark text-white"}
                  onChange={searchChange}
                />
              
                  <Button
                    size="sm"
                    variant="outline-info"
                    type="button"
                    onClick={searchData}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    type="button"
                    onClick={cancelSearch}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
               
              </InputGroup>
            </div>
          </Card.Header>
          <Card.Body>
          <div style={{ overflowX: "auto", marginTop:'20px', padding:'1%'}}>
               <Row className="flex-nowrap">
            <Table bordered hover striped variant="dark">
              <thead>
                <tr>
                <th onClick={sortData}>
                    Name{" "}
                    <div
                      className={
                        sortDir === "asc"
                          ? "arrow arrow-up"
                          : "arrow arrow-down"
                      }
                    >
                      {" "}
                    </div>
                  </th>
                  <th>Description</th>
                  <th>Urls</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {images.length === 0 ? (
                  <tr align="center">
                    <td colSpan="7">No Images Available.</td>
                  </tr>
                ) : (
                  images.map((image) => (
                    <tr key={image.id}>
                      <td>
                        <Image
                         src={image.urls?(url+"/image/image/download/"+image.id+"/"+image.urls.split(",")[0]):null}
                          roundedCircle
                          width="25"
                          height="25"
                        />{" "}
                        {image.name}
                      </td>
                      <td>{image.description}</td>
                      <td>{image.urls}</td>
                      <td>
                        <ButtonGroup>
                          <Link
                            to={"edit/" + image.id}
                            className="btn btn-sm btn-outline-primary"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Link>{" "}
                          <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={(e)=>{ 
                                deleteImages(image.id);
                              }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            </Row></div>
          </Card.Body>
          
            <Card.Footer>
              <div style={{ float: "left" }}>
                Showing Page {currentPage} of {totalPages}
              </div>
              <div style={{ float: "right" }}>
                <InputGroup size="sm">
               
                    <Button
                      type="button"
                      variant="outline-info"
                      disabled={currentPage === 1 ? true : false}
                      onClick={firstPage}
                    >
                      <FontAwesomeIcon icon={faFastBackward} /> First
                    </Button>
                    <Button
                      type="button"
                      variant="outline-info"
                      disabled={currentPage === 1 ? true : false}
                      onClick={prevPage}
                    >
                      <FontAwesomeIcon icon={faStepBackward} /> Prev
                    </Button>
                 
                  <FormControl
                    className={"page-num bg-dark"}
                    name="currentPage"
                    value={currentPage}
                    onChange={changePage}
                  />
               
                    <Button
                      type="button"
                      variant="outline-info"
                      disabled={currentPage === totalPages ? true : false}
                      onClick={nextPage}
                    >
                      <FontAwesomeIcon icon={faStepForward} /> Next
                    </Button>
                    <Button
                      type="button"
                      variant="outline-info"
                      disabled={currentPage === totalPages ? true : false}
                      onClick={lastPage}
                    >
                      <FontAwesomeIcon icon={faFastForward} /> Last
                    </Button>
                  
                </InputGroup>
              </div>
            </Card.Footer>
         
        </Card>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      </>
    );
  }


