import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import React, { useEffect, useState, useCallback} from "react";
import { Badge, Card,Button, FormControl,InputGroup, OverlayTrigger, Tooltip, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faEdit,
  faTrash,
  faStepBackward,
  faFastBackward,
  faStepForward,
  faFastForward,
  faSearch,
  faTimes,
  faEyeLowVision,
  faEye,
  faPeopleRoof,
  faPersonChalkboard,
  faHandsHoldingChild,
  faNewspaper,
  faCircleInfo,
  faCalendarCheck,
  faIcons,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import MyToast from "../MyToast";
import axios from "axios";
import { debounce } from "lodash";

export default function CalendarPage(){
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const navigate=useNavigate();
  const [show, setShow]=useState(false);
  const [calendar, setCalendar]=useState([]);
  const [search, setSearch]=useState();
  const [totalElements, setTotalElements]=useState();
  const [totalPages, setTotalPages]=useState();
  const [currentPage, setCurrentPage]=useState(1);
  const [calendarPerPage, setCalendarPerPage]=useState(5);
  const [sortDir, setSortDir]=useState("desc");
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const [date, setDate] = useState(new Date());
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const MemoizedCalendar = React.memo(Calendar);
  const [calendarNow, setCalendarNow]=useState([]);

  const tileClassName = useCallback(({ date, view }) => {
    if (view === 'month') {
      const appointment = calendar.find(appt => {
        if (appt.date && appt.enddate) {
          return date >= appt.date && date <= appt.enddate;
        } else {
          return appt.date.toDateString() === date.toDateString();
        }
      });
  
      if (appointment) {
        return appointment.category!==("Info"||"Sonstige")?("cal"+appointment.category):("calInfo")+`appointment-tile`;
      }
    }
  }, [calendar]);
  
  const tileContent = useCallback(({ date, view }) => {
    if (view === 'month') {
      const appointmentsOnDate = calendar.filter(appt => {
        if (appt.date && appt.enddate) {
          return date >= appt.date && date <= appt.enddate;
        } else {
          return appt.date.toDateString() === date.toDateString();
        }
      });
  
      if (appointmentsOnDate.length > 0) {
       console.log("cat ", appointmentsOnDate);
       return (
        <div  onClick={() => {navigate("/kalender/details/"+appointmentsOnDate[0].id); setSelectedAppointments(appointmentsOnDate);}}>
        <OverlayTrigger onClick={() => {navigate("/kalender/details/"+appointmentsOnDate[0].id); setSelectedAppointments(appointmentsOnDate);}}
  placement="top"
  delay={{ show: 250, hide: 400 }}
  overlay={<Tooltip id="button-tooltip">{appointmentsOnDate[0].title}</Tooltip>}>
    <Badge pill bg="trasparence" text="dark" className="headpill">
    {getCatIcon(appointmentsOnDate[0].category)}
    </Badge>
    </OverlayTrigger>
        </div>
      );
      }
      else {
        return (
          <div>
            <FontAwesomeIcon icon={faCalendar} style={{color: "transparent"}} className="appointment-icon" onClick={() => setSelectedAppointments(appointmentsOnDate)} />
          </div>
        );
      }
    }
  }, [calendar]);

  function onChange(newDate) {
    setDate(newDate);
  }

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 2;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 4;
    } else if ( width >= 1200 && width < 1440 ) {
      return 5;
    } else if ( width >= 1440 ) {
      return 6;
    }
  }

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  useEffect(()=> {  
    findAllCalendar(currentPage);  
    findAllCalendarNow(currentPage); 
  },[]);

function sortData(){
  setTimeout(() => {
    sortDir === "asc"
      ? setSortDir("desc")
      : setSortDir("asc");
    findAllCalendar(currentPage);
  }, 500);
}
function findAllCalendarNow(currentPage) {
  currentPage -= 1;
    axios
      .get(
        url+"/calendar/public?pageNumber=" +
          currentPage +
          "&pageSize=" +
          calendarPerPage +
          "&sortBy=date&sortDir=" +
          "asc"
      )
      .then((response) => response.data)
      .then((data) => {
        //console.log(data);
        setCalendarNow(modifyCalendarData(data.content));
        setTotalPages(data.totalPages);
        setTotalElements(data.totalElements);
         setCurrentPage(data.number + 1);     
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function searchData (currentPageIs){
    currentPageIs -= 1;
    axios
      .get(
        url+"/calendar/public/search/" +
        search +
          "?page=" +
          currentPageIs +
          "&size=" +
          calendarPerPage
      )
      .then((response) => response.data)
      .then((data) => {
        
        setCalendarNow(modifyCalendarData(data.content));
          setTotalPages(data.totalPages);
          setTotalElements(data.totalElements);
          setCurrentPage(data.number + 1);
       
      });
  }
  function findAllCalendar (){
    axios
      .get(
        url+"/calendar/public/search/" +
        "20" +
          "?page=" +
          0 +
          "&size=" +
          100
      )
      .then((response) => response.data)
      .then((data) => {
        
          setCalendar(modifyCalendarData(data.content));    
      });
  }

  function modifyCalendarData(data){
    var arr = [];
  data.forEach((cal) => {
    let startDateComponents = cal.date.split("-");
    let endDateComponents = cal.enddate.split("-");

    // Erstellen des Start- und Enddatums als JavaScript-Date-Objekte
    // Startdatum: Anfang des Tages (00:00:00)
    let startDate = new Date(
      parseInt(startDateComponents[0], 10),
      parseInt(startDateComponents[1], 10) - 1, // Monate sind 0-basiert in JavaScript
      parseInt(startDateComponents[2], 10)
    );

    // Enddatum: Ende des Tages (23:59:59)
    let endDate = new Date(
      parseInt(endDateComponents[0], 10),
      parseInt(endDateComponents[1], 10) - 1, // Monate sind 0-basiert in JavaScript
      parseInt(endDateComponents[2], 10),
      23, 59, 59
    );

    cal.date = startDate;
    cal.enddate = endDate;

    arr.push(cal);
  });

  return arr;
  }
 

  function changePage(event){
    let targetPage = parseInt(event.target.value);
    if (search) {
      searchData(targetPage);
    } else {
        findAllCalendarNow(targetPage);
    }
    setCurrentPage(targetPage);
   
  }

  function firstPage(){
    let firstPage = 1;
    if (currentPage > firstPage) {
      if (search) {
        searchData(firstPage);
      } else {
        findAllCalendarNow(firstPage);
      }
    }
  }

  function prevPage(){
    let prevPage = 1;
    if (currentPage > prevPage) {
      if (search) {
        searchData(currentPage - prevPage);
      } else {
        findAllCalendarNow(currentPage - prevPage);
      }
    }
  }

  function lastPage(){
    let condition = Math.ceil(
      totalElements / calendarPerPage
    );
    if (currentPage < condition) {
      if (search) {
        searchData(condition);
      } else {
        findAllCalendarNow(condition);
      }
    }
  }

  function nextPage() {
    if (
      currentPage <
      Math.ceil(totalElements / calendarPerPage)
    ) {
      if (search) {
        searchData(currentPage + 1);
      } else {
        findAllCalendarNow(currentPage + 1);
      }
    }
  }

  function searchChange(event){
   setSearch(event.target.value);
  }

  function cancelSearch(){
    setSearch("");
    findAllCalendarNow(currentPage);
  }

  function searchData (currentPageIs){
    currentPageIs -= 1;
    axios
      .get(
        url+"/calendar/public/search/" +
        search +
          "?page=" +
          currentPageIs +
          "&size=" +
          calendarPerPage
      )
      .then((response) => response.data)
      .then((data) => {
        
          setCalendarNow(modifyCalendarData(data.content));
          setTotalPages(data.totalPages);
          setTotalElements(data.totalElements);
          setCurrentPage(data.number + 1);
       
      });
  }
  function linkInst(element){
    if(element==="Kubschütz"){
      return("https://www.feuerwehr-kubschuetz.de");
    }
    else if(element==="BF Bautzen"){
      return("https://www.feuerwehr-bautzen.de/");
    }
    else if(element==="FFW Hochkirch"){
      return("https://www.hochkirch.de/einrichtungen/feuerwehr");
    }
    return("https://www.feuerwehr-kubschuetz.de");
  }

  function createPill(string){
    const arr=string.split(",");
     return( 
      arr.map((element,i)=>(        
        <a  key={i} href={linkInst(element)} target="_blank" rel="noreferrer" className="cardtext">
      <Badge key={i} className="pillinst bottompill" pill bg="light" text="dark">
      {element}     
    </Badge>        
    </a>
      ))
     )
  }
  function getCatIcon (cat){
    if(cat==="Jahreshauptversammlung"){
      return(<FontAwesomeIcon key={cat} icon={faPeopleRoof} />)
    }
    else if(cat==="Dienst"){
      return(<FontAwesomeIcon key={cat} icon={faPersonChalkboard} />)
    }
    else if(cat==="Veranstaltung"){
      return(<FontAwesomeIcon key={cat} icon={faIcons} />)
    }
    else if(cat==="Jugendfeuerwehr"){
        return(<FontAwesomeIcon key={cat} icon={faHandsHoldingChild} />)
      }
    else if(cat==="Ereignis"){
      return(<FontAwesomeIcon key={cat}  icon={faCalendarCheck} />)
    }
    return(<FontAwesomeIcon key={cat} icon={faCircleInfo} />)
     }
   
    
        const cardSlides =
            calendarNow.map((card, i) => {
              const cardStyle = {
                backgroundImage: `linear-gradient(to top, rgba(230, 230, 230, 0.9), rgba(230, 230, 230, 0.8)), url(${url+"/calendar/image/download/"+card.id+"/"+card.imageUrl.split(",")[0]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative'
            };
            return ( 
          <div key={i} onClick={() => navigate("/kalender/details/"+card.id)}  className={(card.category!==("Info"||"Sonstige")?("cal"+card.category):("calInfo"))+" containerCalItem calendarPageItem"} style={card.imageUrl?(cardStyle):null}>
      <div class="iconCal"> <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">{card.category}</Tooltip>}>
            <Badge pill bg="trasparence" text="dark" className="headpill">
            {getCatIcon(card.category)}
            </Badge>
            </OverlayTrigger></div>
      <div class="titleCal">{card.title}</div>
      <div class="DateCal">{card.date.toLocaleDateString()===card.enddate.toLocaleDateString()?(card.location+", "+card.date.toLocaleDateString()+" "+card.time+" Uhr"):(card.location+", "+card.date.toLocaleDateString()+" "+card.time+" Uhr bis "+card.enddate.toLocaleDateString()+" "+card.endtime+" Uhr")}</div>
      <div class="InstiCal">{createPill(card.institutions)}</div>
    </div>
        );
            })
  
    return (
      <>
      {size>3?(<><br></br><br></br><br></br><br></br></>):null}
      <div id="324" className="ConUni3">
        <div id="34522" className="titleMain">
            <div style={{ float: "left" }}>
              <FontAwesomeIcon icon={faList} /> Übersicht Kalendereinträge
            </div>
            <div style={{ float: "right" }}>
            <InputGroup size="sm" style={{ zIndex:'0', padding:'2%', maxWidth: size<4?('170px'):('400px')}}>
                <FormControl
                  placeholder="Search"
                  name="search"
                  value={search}
                  className={"info-border bg-light "}
                  onChange={searchChange}
                  onKeyPress={(e)=>{if(e.key === 'Enter'){searchData(currentPage)}}}
                />
              
                  <Button
                    size="sm"
                    variant="dark"
                    type="button"
                    onClick={searchData}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={cancelSearch}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
               
              </InputGroup>
            </div>
          </div>

        
          <div id="672" className="MainParty3" style={{padding:"1%"}}>
          <div class="containerCalPart">
  <div class="calendarPart">
        <MemoizedCalendar
        value={date}
        onChange={onChange}
        tileClassName={tileClassName}
        tileContent={tileContent} 
      />
      </div>
      <div class="CalendarItemsPart">
          {calendarNow?(cardSlides):null}   
          </div>
          </div> 
          </div>
          <div className="footerPageCon">
          <div style={{ float: "left", fontSize:"80%", marginLeft: '1.5%'}}>
                Seite {currentPage} von {totalPages}
              </div>
              <div style={{ float: "right" , marginRight: '1.5%',marginLeft: '1.5%'}}>
                <InputGroup size="sm">
               
                    <Button
                      type="button"
                      variant="outline-ligt"
                      disabled={currentPage === 1 ? true : false}
                      onClick={firstPage}
                    >
                      <FontAwesomeIcon icon={faFastBackward} /> Erste
                    </Button>
                    <Button
                      type="button"
                      variant="outline-light"
                      disabled={currentPage === 1 ? true : false}
                      onClick={prevPage}
                    >
                      <FontAwesomeIcon icon={faStepBackward} /> Zurück
                    </Button>
                 
                  <FormControl
                    className={"page-num bg-light"}
                    name="currentPage"
                    value={totalElements}
                    readOnly={true}
                  />
               
                    <Button
                      type="button"
                      variant="outline-light"
                      disabled={currentPage === totalPages ? true : false}
                      onClick={nextPage}
                    >
                      <FontAwesomeIcon icon={faStepForward} /> Nächste
                    </Button>
                    <Button
                      type="button"
                      variant="outline-light"
                      disabled={currentPage === totalPages ? true : false}
                      onClick={lastPage}
                    >
                      <FontAwesomeIcon icon={faFastForward} /> Letze
                    </Button>
                  
                </InputGroup>
              
        </div></div>
          </div>
      <br></br>
      <br></br>
      <br></br>
      </>
    );
  }


