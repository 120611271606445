import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Datenschutz = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "web@feuerwehr-kubschuetz.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();

  const handleEmailClick = () => {
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

 





  return (
    <>
    {size>1?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni">
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Datenschutzerklärung
        </div>
        <div id="672" className="MainParty2" >
        <p style={{paddingLeft:"1%",paddingRight:"1%",paddingTop:"1%"}}>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>
        <p style={{paddingLeft:"1%",paddingRight:"1%"}}>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
        <p style={{paddingLeft:"1%",paddingRight:"1%"}}>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
        <p style={{paddingLeft:"1%",paddingRight:"1%"}}>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
        <p style={{paddingLeft:"1%",paddingRight:"1%"}}>I. Informationen über uns als Verantwortliche</p>
        <p style={{paddingLeft:"1%",paddingRight:"1%"}}>II. Rechte der Nutzer und Betroffenen</p>
        <p style={{paddingLeft:"1%",paddingRight:"1%" ,paddingBottom:"1%"}}>III. Informationen zur Datenverarbeitung</p>
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2">
        I. Informationen über uns als Verantwortliche
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p style={{color:"red"}}>Es handelt sich bei der Website http://www.feuerwehr-kubschuetz.de um eine rein private Homepage ohne kommerziellen Hintergrund, auf der die Arbeit der Freiwilligen Feuerwehr Kubschütz dokumentiert wird.</p>
        <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
        <p> Freiwillige Feuerwehr Kubschütz</p>  
        <p>Wehrleitung</p>
        <p>Mittelweg 3</p>
        <p>02627 Kubschütz</p>
        <p>E-Mail: web(at)feuerwehr-kubschuetz.de</p>

<Button onClick={handleEmailClick}>
<FontAwesomeIcon icon={faMailBulk} />&nbsp;E-Mail senden
    </Button> 
        </div></div>
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2">
        II. Rechte der Nutzer und Betroffenen
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}} >
        <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>
        <p><FontAwesomeIcon icon={faHandPointRight}/>&nbsp;auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</p>
        <p><FontAwesomeIcon icon={faHandPointRight}/>&nbsp;auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</p>
        <p><FontAwesomeIcon icon={faHandPointRight}/>&nbsp;auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</p>
        <p><FontAwesomeIcon icon={faHandPointRight}/>&nbsp;auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</p>
        <p><FontAwesomeIcon icon={faHandPointRight}/>&nbsp;auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</p>
        <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</p>
        <p style={{fontWeight:"bold"}}>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</p>
        </div></div> 
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2">
        III. Informationen zur Datenverarbeitung
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>
        <p style={{fontWeight:"bold"}}>Cookies</p>
        <p>a) Sitzungs-Cookies/Session-Cookies</p>
        <p>Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet.</p>
        <p>Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.</p>
        <p>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
        <p>Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</p>
        <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
        <p>b) Drittanbieter-Cookies</p>
        <p>Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.</p>
        <p>Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
        <p>c) Beseitigungsmöglichkeit</p>  
        <p>Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.</p>
        <p>Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</p>
        <p style={{fontWeight:"bold"}}>Kontaktanfragen / Kontaktmöglichkeit</p>
        <p>Sofern Sie per E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich – ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.</p>
        <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
        <p>Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.</p>
        <p style={{fontWeight:"bold"}}>Serverdaten</p>
        <p>Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.

Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.

Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.

Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</p>
<p style={{fontWeight:"bold"}}>CloudFlare</p>
<p>Zur Absicherung unseres Internetauftritts sowie zur Optimierung der Ladezeiten setzen wir den Dienst CloudFlare als sog. CDN (Content-Delivery-Network) ein. Es handelt sich hierbei um einen Dienst der Cloudflare Inc., 101 Townsend Street, San Francisco, California 94107, USA, nachfolgend nur „CloudFlare“ genannt.

Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in dem sicheren Betrieb unseres Internetauftritts sowie in dessen Optimierung.

Sofern Sie unseren Internetauftritt aufrufen, werden Ihre Anfragen über den Server von CloudFlare geleitet. Hierbei werden statistische Zugriffsdaten über den Besuch unseres Internetauftritts erhoben und durch CloudFlare ein Cookie über Ihren Internet-Browser auf Ihrem Endgerät gespeichert. Zu den Zugriffsdaten zählen

– Ihre IP-Adresse,

– die von Ihnen aufgerufene(n) Internetseite(n) unseres Internetauftritts,

– Typ und Version des von Ihnen genutzten Internet – Browsers,

– das von Ihnen genutzte Betriebssystem,

– die Internetseite, von der sie auf unseren Internetauftritt gewechselt haben (Referrer- URL),

– Ihre Verweildauer auf unserem Internetauftritt und

– die Häufigkeit des Aufrufs unserer Internetseiten.

Die Daten werden durch CloudFlare zum Zwecke statistischer Auswertungen der Zugriffe sowie zur Sicherheit und Optimierung des Angebots verwendet.

Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.

CloudFlare bietet unter

<a href="https://www.cloudflare.com/privacypolicy/">https://www.cloudflare.com/privacypolicy/</a>

weitere Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren Rechten und Möglichkeiten zum Schutz Ihrer Privatsphäre an.</p>
        <p style={{fontWeight:"bold"}}>facebook</p>
        <p>Meta Platforms Ireland Limited 4 Grand Canal Square Grand Canal Harbour Dublin 2 Ireland</p>
        <p>Datenschutzerklärung: <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a></p>
        <p style={{fontWeight:"bold"}}>twitter</p>
        <p>Twitter International Unlimited Company Attn: Data Protection Officer One Cumberland Place, Fenian Street Dublin 2, D02 AX07, IRLAND</p>
        <p>Datenschutzerklärung:  <a href="https://twitter.com/privacy">https://twitter.com/privacy</a></p>
        <p style={{fontWeight:"bold"}}>instagram</p>
        <p>Meta Platforms Ireland Limited 4 Grand Canal Square Grand Canal Harbour Dublin 2 Ireland</p>
        <p>Datenschutzerklärung:  <a href="https://privacycenter.instagram.com/policy">https://privacycenter.instagram.com/policy</a></p>
        <p style={{fontWeight:"bold"}}>FontAwesome</p>
        <p>Um Schriften und visuelle Elemente unseres Internetauftritts darstellen zu können, setzen wir die externen Schriftarten von FontAwesome ein. Bei FontAwesome handelt es sich um einen Dienst der</p>
        <p>Fonticons Inc., 6 Porter Road, Apartment 3R, Cambridge, MA 02140, USA, nachfolgend nur „FontAwesome“ genannt.</p>
        <p>Bei dem Aufruf unseres Internetauftritts wird eine Verbindung zum Server von FontAwesome in den USA aufgebaut, um so die Darstellung von Schriften und visuellen Elemente zu ermöglichen und zu aktualisieren.</p>
        <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p> 
        <p>Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zum Server von FontAwesome kann FontAwesome ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Darstellung der Schrift zu übermitteln ist.</p>
        <p>FontAwesome bietet unter: <a href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a></p>
        <p>weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>
        <p>&nbsp; </p>
        <p style={{fontWeight:"lighter"}}><a href="https://www.xn--generator-datenschutzerklrung-pqc.de/">Muster-Datenschutzerklärung der Anwaltskanzlei Weiß & Partner</a></p>
        </div></div> 
        </div></div>   
         <br></br>
         <br></br>
    </>
  );
};

export default Datenschutz;
