import { Card, Row, Form, Button, Col,FormControl, InputGroup, Image, Container, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSave,
  faPlusSquare,
  faUndo,
  faList,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import MyToast from "../MyToast";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState, useMemo, useRef} from "react";
import axios from "axios";
import {useDropzone} from 'react-dropzone'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { icon } from 'leaflet';
import { debounce } from "lodash";

export default function ImageConfig(){

    const url= process.env.REACT_APP_PUBLIC_BACKEND;
    const {id}=useParams();
    const navigate=useNavigate();
    const [image, setImage]=useState();
    const [show, setShow]=useState(null);
    const [methode,setMethode]=useState("success");
    const [change, setChange]=useState(false);
  
  const [ImagePath, setImagePath]=useState([]);  
    let initialState = {
    id: "",
    name: "",
    description:"",
    link:"",
    urls:""
  };
  const [imageTemp, setImageTemp]=useState(initialState);
  const [files, setFiles]=useState([]);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

useEffect(()=>{
  console.log('tempmission',imageTemp);
  console.log('image',image);
  console.log('id',id);
});
  useEffect(()=> {
    if(id==null){
    setImageTemp({...imageTemp, id: generateUuid()})}
    if(id!=null){
      setTimeout(() => {
   axios.get(url+"/image/" + id)
    .then((response) => {
      return response.data;
    })
    .then((data)=>{
      if(data.urls){
        if(data.urls.length>0) setImagePath(data.urls.split(","));
        setImage(data);
        setImageTemp(data);
      }
      else{setImageTemp({...data, urls: ""});setImage({...data, urls: ""});}
    })
    .catch((error) => {
      console.log(error);
    }); }, 500);}
  },[]);

  function handleCloseOld(index){
    const updatedImages = [...ImagePath];
    updatedImages.splice(index, 1);
    setImagePath(updatedImages);
    setImageTemp({...imageTemp, urls: updatedImages.join(",")})
    setChange(true);
  };
  function handleCloseClick(index){
    const updatedImages = [...files];
    updatedImages.splice(index, 1);
    setFiles(updatedImages);
    setChange(true);
  };
  
  function onReset(){
   try{
    if(id){
      setImageTemp(image);
      setChange(false);
      setFiles([]);       
      setImagePath(image.imageUrl.split(","));
      setShow("Reset");
      setTimeout(() => {
        setShow(null);
      }, 3000);
    }
    else{
      setImageTemp(initialState);
      setImageTemp({...initialState, id: generateUuid()})
      setChange(false);
      setFiles([]);
      setImagePath([]);
      setChange(false);
      setShow("Reset");
      setTimeout(() => {
        setShow(null);
  }, 3000);
    }
  }catch{}
   }
   async function updateImage(){
    var count=0;
    if(image.urls.length>0){
    const urls=ImagePath.join(",");
    image.urls.split(",").map((img, index) => {
      count++;
      if(urls.indexOf(img)===-1){
        setTimeout(() => {
      axios.delete(url+"/image/image/"+imageTemp.id+"/"+img)
     .then((response) => {
      console.log(response);
      })
    .catch((error) => {
    console.log(error);
    setMethode("danger");
    setShow("Image picture updated failed");
    })}, 1000+count*500);}
    }
    );}
  
 if(files){
  var count1=0;
  files.forEach(img=>{
    const formData = new FormData();
    count1++;
    setTimeout(() => {
    formData.append("file",img);
    axios.post(url+"/image/"+imageTemp.id+"/image/upload",formData,{headers:{"Content-Type": "multipart/form-data"}})
  .then((response) => {
    console.log("upload succsessfully!")
})
.catch((error) => {
  setMethode("danger");
  setShow("Image picture updated failed");
console.log(error);

});
}, 1000+count1+1000);})}
    setImage(imageTemp);
    setMethode("put");
    setTimeout(() => {
    axios
    .put(url+"/image", imageTemp)
    .then((response) => {
     // console.log(response.data)
     setMethode("success");
      setShow("Image updated successfully");
    })
    .catch((error) => {
      setMethode("danger");
      setShow("Image update failed");
      console.log(error);
    });
  }, 500);
  setTimeout(() => {
    setShow(null);
    navigate("/image-list");
  }, 3000);
   }
   async function submitImage(){
    setImage(imageTemp);
    
    axios
    .post(url+"/image", imageTemp)
    .then((response) => {
      setShow(true);
    })
    .catch((error) => {
      setShow(null);
      console.log(error);
    });
  
 if(files){
  var count=0;
  files.forEach(img=>{
    const formData = new FormData();
    count++;
    setTimeout(() => {
    formData.append("file",img);
    axios.post(url+"/image/"+imageTemp.id+"/image/upload",formData,{headers:{"Content-Type": "multipart/form-data"}})
  .then((response) => {
    console.log("upload succsessfully!");
    setShow("Image submited successfully");
    setMethode("success");
})
.catch((error) => {
console.log(error);
});
}, 1000+count*1000);})
setTimeout(() => {
  setShow(null);
  onReset();
}, 3000);
}


   }
   function generateUuid () {
    return Math.floor(Math.random() * 1000000000).toString();
  }
   function Dropzone() {
   
      const onDrop=useCallback(acceptedFiles=>{
      setFiles(files.concat(acceptedFiles));
      setChange(true);
    }, []);


    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
    )
  }

    return (<>
     {size>1?(<><br></br><br></br><br></br><br></br></>):null}
      <Container fluid style={{top:'60px'}}>
        <div style={{ display: show ? "block" : "none" }}>
          <MyToast
            show={show ? true:false}
            message={
             show
            }
            type={methode}
            onClose={() => setShow(null)} dismissible
          />
        </div>
        <Card className={"border border-dark bg-dark text-white"} style={{margin: '1%'}}>
          <Card.Header>
            <FontAwesomeIcon icon={id ? faEdit : faPlusSquare} />{" "}
            {id ? "Update Image-Set" : "Add New Image-Set"}
          </Card.Header>
          <Form style={{ backgroundColor: '#343a40', color: 'white' }}
            onReset={onReset}
  
            id="bookFormId"
          >
            <Card.Body>
              <Row>
                <Form.Group as={Col} controlId="formGridTitle">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    autoComplete="off"
                    type="test"
                    name="name"
                    value={imageTemp.name}
                    onChange={(e)=>{    
                      setChange(true);
                      setImageTemp({...imageTemp, name: e.target.value})
                    }}
                    className={"bg-dark text-white"}
                    placeholder="Enter Image-Set name"
                  />
                </Form.Group>
              </Row>
              <br></br>
              <Row>
              <Form.Group as={Col} controlId="formGridPrice">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea" 
                    rows={8}
                    required
                    autoComplete="off"
                    type="test"
                    name="description"
                    value={imageTemp.description}
                    onChange={(e)=>{
                      setChange(true);
                      setImageTemp({...imageTemp,  description: e.target.value})
                    }}
                    className={"bg-dark text-white"}
                    placeholder="Enter Image-Set description"
                  />
                </Form.Group>
              </Row>
              <br></br>
              <Row>
                <Form.Group as={Col} controlId="formGridLink">
                  <Form.Label>Link</Form.Label>
                  <Form.Control
                    required
                    autoComplete="off"
                    type="test"
                    name="link"
                    value={imageTemp.link}
                    onChange={(e)=>{    
                      setChange(true);
                      setImageTemp({...imageTemp, link: e.target.value})
                    }}
                    className={"bg-dark text-white"}
                    placeholder="Enter Image-Set Link"
                  />
                </Form.Group>
              </Row>

              <br></br>
              Images
              <br></br>
              <Row style={{border: 'solid white 1px', background: 'black',borderRadius: "6px",margin:'2px', marginTop:'8px'}}>
                <Dropzone/>

              </Row>
              <div style={{ overflowX: "auto", marginTop:'20px'}}>
               <Row className="flex-nowrap">
        {
          files.map((img, index) => (
          <Col key={index} className="mb-3" style={{ width: "100%" }}>
            <div style={{ width: "200px"}}>
              <Button
                variant="danger"
                className="p-0"
                style={{ position: "absolute", right: "1", top: "0" }}
                onClick={() => handleCloseClick(index)}
              >
                &times;
              </Button>
              <Image 
                className="imagestyle"
                src={URL.createObjectURL(img)} 
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
    <div style={{ overflowX: "auto", marginTop:'20px'}}>
               <Row className="flex-nowrap">
        {
          ImagePath.map((img, index) => (
          <Col key={index} className="mb-3" style={{ width: "100%" }}>
            <div style={{  width: "200px"}}>
              <Button
                variant="danger"
                className="p-0"
                style={{ position: "absolute", right: "1", top: "0" }}
                onClick={() => handleCloseOld(index)}
              >
                &times;
              </Button>
              <Image className="imagestyle"
                src={url+"/image/image/download/"+imageTemp.id+"/"+img}
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
    <br></br> 
            </Card.Body>
  
            <Card.Footer style={{ textAlign: "right" }}>
              <Button onClick={id ? updateImage : submitImage} size="sm" variant="success" disabled={change === false || imageTemp.name.length === 0 || imageTemp.description.length === 0}>
                <FontAwesomeIcon icon={faSave} />{" "}
                {id ? "Update" : "Save"}
              </Button>{" "}
              <Button size="sm" variant="info" type="reset" disabled={change === false}>
                <FontAwesomeIcon icon={faUndo} /> Reset
              </Button>{" "}
              <Button
                size="sm"
                variant="info"
                type="button"
                onClick={() => navigate("/image-list")}
              >
                <FontAwesomeIcon icon={faList} /> Image-Set List
              </Button>
            </Card.Footer>
          </Form>
        </Card>
        </Container>
       

               
      <br></br>
      <br></br>
        </>
    );
  }