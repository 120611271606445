import React, { useEffect, useState } from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import Welcome from "./components/Welcome";
import Login from "./components/User/Login";
import Footer from "./components/Footer";
import Home from "./components/Home";


import MissionList from "./components/Mission/MissionList";
import Mission from "./components/Mission/Mission";
import ImageList from "./components/Images/ImageList";
import ReportList from "./components/Report/ReportList";
import CalendarList from "./components/Calendar/CalendarList";
import CalendarItem from "./components/Calendar/CalendarItem";
import Report from "./components/Report/Report";
import MissionPage from "./components/Mission/MissionPage";
import ImageConfig from "./components/Images/ImageConfig";
import { debounce } from "debounce";
import MissionDetailsPage from "./components/Mission/MissionDetailsPage";
import ReportPage from "./components/Report/ReportPage";
import ReportDetailsPage from "./components/Report/ReportDetailsPage";
import CalendarPage from "./components/Calendar/CalendarPage";
import CalendarDetailsPage from "./components/Calendar/CalendarDetailsPage";
import CookieBanner from "./components/Warnings/CookieBanner";
import Impressum from "./components/Impressum/Impressum";
import Datenschutz from "./components/Impressum/Datenschutz";
import Jugendfeuerwehr from "./components/InformationSites/Jugendfeuerwehr";
import OrtswehrKubschütz from "./components/InformationSites/OrtswehrKubschütz";
import OrtswehrRachlau from "./components/InformationSites/OrtswehrRachlau";
import OrtswehrPurschwitz from "./components/InformationSites/OrtswehrPurschwitz";
import Fahrzeugtechnik from "./components/InformationSites/Fahrzeugtechnik";
import Rauchmelder from "./components/InformationSites/Rauchmelder";
import SicherheitBrandschutz from "./components/InformationSites/SicherheitBrandschutz";
import Knoten from "./components/InformationSites/Knoten";
import GedankenFeuerwehrmann from "./components/InformationSites/GedankenFeuerwehrmann";
import TodUnschuldigen from "./components/InformationSites/TodUnschuldigen";
import Buergerinformationen from "./components/InformationSites/Buergerinformationen";
import LöschgruppeSoritz from "./components/InformationSites/LöschgruppeSoritz";

const App = () => {
  const[state, setState]=useState('');
  window.onbeforeunload = (event) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue = "";
    }
    return "";
  };
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  
  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);


  return (
    <BrowserRouter>
       <div className="gradient-custom">
      {size<3?( size<2?(<Container style={{height:'56px'}}/>):(<Container style={{height:'63px'}}/>)):(null)}
      <CookieBanner/>
       <NavigationBar />
   
       
            <Routes>
              
           
              <Route path="/einsaetze" element={ <MissionPage/> }></Route>
              <Route path="/aktuelles" element={ <ReportPage/> }></Route>
              <Route path="/kalender" element={ <CalendarPage/> }></Route>
              <Route path="/einsaetze/details/:id" element={ <MissionDetailsPage /> }></Route>
              <Route path="/aktuelles/details/:id" element={ <ReportDetailsPage /> }></Route>
              <Route path="/kalender/details/:id" element={ <> <CalendarDetailsPage/><CalendarPage/> </>}></Route>




              <Route path="/" element={ <Welcome/> }> </Route>
              <Route path="/home" element={ <Home/>}> </Route>
              <Route path="/add-mission" element={ <Mission/> }> </Route>
              <Route path="/mission-list/edit/:id"  element={ <Mission check={true}/> }> </Route>
              <Route path="/mission-list" element={ <MissionList/> }> </Route>
              <Route path="/image-list"  element={ <ImageList/> }/>
              <Route path="/report-list"  element={ <ReportList/> }/>
              <Route path="/calendar-list"  element={ <CalendarList/> }/>
              <Route path="/add-image"  element={ <ImageConfig/> }/>
              <Route path="/add-report"  element={ <Report/> }/>
              <Route path="/add-calendar"  element={ <CalendarItem/> }/>
              <Route path="/report-list/edit/:id"  element={ <Report check={true}/> }/>
              <Route path="/image-list/edit/:id"  element={ <ImageConfig/> }/>
              <Route path="/calendar-list/edit/:id"  element={ <CalendarItem check={true}/> }/>
              <Route path="/register" element={ <MissionList/> }></Route>
              <Route path="/missionPage" element={ <MissionPage/> }></Route>
              <Route path="/reportPage" element={ <ReportPage/> }></Route>
              <Route path="/calendarPage" element={ <CalendarPage/> }></Route>
              <Route path="/missionPage/missionDetails/:id" element={ <MissionDetailsPage /> }></Route>
              <Route path="/reportPage/reportDetails/:id" element={ <ReportDetailsPage /> }></Route>
              <Route path="/calendarPage/calendarDetails/:id" element={ <> <CalendarDetailsPage/><CalendarPage/> </>}></Route>
              <Route path="/Login" element={ <Login stateChanger={setState}/> }></Route>
              <Route path="/Impressum" element={<Impressum/> }></Route>
              <Route path="/Datenschutzerklärung" element={<Datenschutz/> }></Route>
              <Route path="/Jugendfeuerwehr" element={<Jugendfeuerwehr/> }></Route>
              <Route path="/Ortswehr-Kubschütz" element={<OrtswehrKubschütz/> }></Route>
              <Route path="/Ortswehr-Rachlau" element={<OrtswehrRachlau/> }></Route>
              <Route path="/Ortswehr-Purschwitz" element={<OrtswehrPurschwitz/> }></Route>
              <Route path="/Löschgruppe-Soritz" element={<LöschgruppeSoritz/> }></Route>
              <Route path="/Fahrzeugtechnik" element={<Fahrzeugtechnik/> }></Route>
              <Route path="/Rauchmelder" element={<Rauchmelder/> }></Route>
              <Route path="/Sicherheit-Brandschutz" element={<SicherheitBrandschutz/> }></Route>
              <Route path="/Knoten" element={<Knoten/> }></Route>
              <Route path="/Gedanken-eines-Feuerwehrmannes" element={<GedankenFeuerwehrmann/> }></Route>
              <Route path="/Tod-eines-Unschuldigen" element={<TodUnschuldigen/> }></Route>
              <Route path="/Buergerinformationen" element={<Buergerinformationen/> }></Route>
              <Route path="/New/" element={<Welcome/> }></Route>
              <Route path="/New/index.php" element={<Welcome/> }></Route>
              <Route path="/New/index.php/einsaetze" element={<Welcome/> }></Route>
              <Route path="/New/index.php/aktuelles" element={<Welcome/> }></Route>
              <Route path="/New/index.php/ow-kubschuetz" element={<Welcome/> }></Route>
              <Route
                path="/logout/:message"
                element={
                  <Login stateChanger={setState}/>
                }
              />
            </Routes>
           
    
      <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
