import React, { useState } from "react";
import { Accordion,AccordionCollapse, Alert, Button, Card } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { useNavigate } from "react-router-dom";
import WarningFire from "./Warnings/WarningFire";
import Warnings from "./Warnings/Warnings";
import LeafletWarn from "./Warnings/LeafletWarn";
import axios from "axios";
import jwt_decode from 'jwt-decode'; 

const Home = () => {
  const [show, setShow]=useState(null);
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const navigate=useNavigate();
  return (
    <>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    {axios.defaults.headers.Authorization?(jwt_decode(axios.defaults.headers.Authorization).sub!=="JFKubschütz"||jwt_decode(axios.defaults.headers.Authorization).sub!=="Atemschutzbeauftragter"?(
   <Button size="xl" style={{height: '20%', width:'50%', borderRadius:"10px", border:'4pxsolid', position:'absolute', top:'20%', right:'25%',fontSize:'120%', fontWeight:'bold'}}
                            variant="outline-warning"
                            onClick={(e)=>{navigate("/add-mission") }}>Einsatzbericht</Button>
                            ):null):null}
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
   
    </>
  );
};

export default Home;
