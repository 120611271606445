import { faCookie, faCookieBite } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const navigate=useNavigate();


  const handleAcceptCookies = () => {
    setShowBanner(false);
    localStorage.setItem('cookieConsent', true);
    // set a cookie to remember user's preference
  }

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    setShowBanner(!consent);
  }, []);

  return (
    showBanner && (
      <div className="cookie-banner" style={{zIndex:"10"}} >
          <div id="324" className="ConUni">
        <div id="34522" className="titleMain2">
          Cookies <FontAwesomeIcon icon={faCookieBite} />
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p>Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten. Weitere Details dazu finden Sie im Impressum.</p>
        <ButtonGroup>
        <a><Button variant="success" onClick={handleAcceptCookies}>Cookies akzeptieren <FontAwesomeIcon icon={faCookie}/></Button></a>
        <a ><Button  variant="danger" onClick={handleAcceptCookies}>ablehnen <FontAwesomeIcon icon={faCookie}/></Button></a>
        </ButtonGroup>
        </div></div>   
      </div>
    )
  );
};

export default CookieBanner;