import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";
import ReportCarousel from "../Report/ReportCarousel";
import MissionCarousel from "../Mission/MissionCarousel";

const OrtswehrRachlau = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "robinhenke3@web.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$OrtswehrRach*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Ortswehr Rachlau
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p>Im Jahr 1922 gründeten die Mitglieder der "Brandschutzgruppe Rachlau" die Freiwillige Feuerwehr Rachlau. Damals bestand die Mannschaft aus 24 Mitgliedern. Mit Zusammenschluss der Gemeinden Rachlau und Pielitz erhöht sich die Mitgliederzahl im Jahr 1936 auf  41. Während des zweiten Weltkrieges stieg die Mitgliederzahl noch mal auf 75, da ein damals erlassenes Gesetz die Bildung einer Pflichtfeuerwehr vorsah. Ende der 60er Jahre begann die Tätigkeit einer Frauengruppe der Feuerwehr. Diese war vorwiegend für den vorbeugenden Brandschutz in der Gemeinde zuständig.*
Heute zählt die FFw Rachlau 25 Kameraden und 3 Kameradinnen</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[3]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div></div>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p>Nach Gründung der Feuerwehr 1922 besaß diese noch nicht viel technische Ausrüstung. Lediglich eine Handdruckspritze war vorhanden. In den 30er Jahren kam eine tragbare Motorspritze dazu. Ein erstes Zugfahrzeug für Hänger und Motorspritze gab es bereits kurz vor dem 2. Weltkrieg. Dies war ein Mannschaftswagen vom Typ "Stoewer".*
Im Jahr 1969 wurde für die Feuerwehr Rachlau ein Löschfahrzeug vom Typ "Garant K30", Baujahr 1960, angeschafft. Ergänzt wurde es durch einen Schlauchhänger aus dem Jahr 1965 und einem Spritzenanhänger mit TS 8.</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[1]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
         </div></div>
      <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p>Der gute alte K30 ist nun endgültig in Pension geschickt. Seit dem Frühjahr 2010 rücken die Rachlauer Kameraden mit dem ehemals in Kubschütz stationierten TSF-W/z aus. Für Mannschaftstransporte und Fahrten zu Wettkämpfen steht ihnen ein MTW zur Vefügung.</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[0]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
         </div></div>
      <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p>Dieses Foto zeigt die Wettkampfgruppe der Rachlauer Feuerwehr. Sie haben bei zahlreichen Wettkämpfen in der Disziplin Löschangriff vordere Plätze erreicht.</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[2]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div></div>
        <p>* Auszug aus der "Festschrift zum 80 jährigen Bestehen der Freiwilligen Feuerwehr Rachlau" von 2002</p>
        </div></div> 
        <br></br>
         <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Einsätze der Ortswehr Rachlau
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
         <MissionCarousel filter={"/missions/public/institution/"+"FFW Rachlau"+"?page="+0+"&size="+100+"&sortBy=date&sortDir="+"asc"}/>
         </div></div>
         <br></br>
         <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Aktuelles von der Ortswehr Rachlau
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
         <ReportCarousel filter={"/report/public/institution/"+"FFW Rachlau"+"?page="+0+"&size="+10+"&sortBy=date&sortDir="+"asc"}/>
         </div></div>
         <br></br>
         <br></br>
         <br></br>
    </>
  );
};

export default OrtswehrRachlau;
