import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";
import PDFViewer from "../Report/PDFViewer";

const Buergerinformationen = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "robinhenke3@web.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
  const [file, setFile]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$DokumenteBuergerInfo*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    <PDFViewer show={show ? true:false} url={url}  id={ImagePath.id} file={file}  onClose={() => setShow(null)}/>
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Informationen für Bürger
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%", margin:' 1%'}}>
        
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"1%",margin:' 1%'}}>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Allgemeine Informationen
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p><Button  variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[8]);}}>Merkblatt Sirenensignale</Button></p>
        <p><Button variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[4]);}}>Feuerwehrsatzung der Gemeinde Kubschütz</Button></p>  
        <p><Button variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[3]);}}>Entschädigungssatzung für die Feuerwehr</Button></p>          
        <p><Button variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[2]);}}>Sächsisches Gesetz über Brandschutz Rettungsdienst und Katastrophenschutz (SächsBRKG)</Button></p>     
        </div></div>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%", margin:' 1%'}}>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Sicherheitstipps
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p><Button variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[9]);}}>Notruffax für gehörlose Bürger</Button></p>
        <p><Button variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[6]);}}>Unfallfrei ins neue Jahr - Merkblatt für Silvester</Button></p>  
        <p><Button variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[7]);}}>Was tun bei Schornsteinbrand</Button></p>      
        </div></div>
        </div></div>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"1%"}}>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Für Kinder
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <Button variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[1]);}}>Bastelbogen Feuerwehr</Button>   
        </div></div>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Feuerwehr
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <Button variant="outline-dark" onClick={()=>{setShow(true); setFile(ImagePath.urlsPage[0]);}}>Airbagmerkblatt</Button>  
        </div></div>
        </div></div>
        </div></div> 
         <br></br>
         <br></br>
         <br></br>
         <br></br>
    </>
  );
};

export default Buergerinformationen;
