import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";

const Jugendfeuerwehr = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "robinhenke3@web.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$JFalt*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urls: data.content[0].urls.split(",").slice(2), urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
            
        {ImagePath.urls?(<img 
                      className="imagestyle2"
                      style={{ position:"center"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[0]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}</div>
        
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
       <p>Jeden 2. Samstag im Monat treffen wir uns mit Kinder und Jugendlichen, die Interesse an der Feuerwehr haben. Es kann jeder an der Ausbildung bei uns teilnehmen. Kinder und Jugendliche ab 8 Jahren sind herzlich willkommen. Du bist Teamfähig und möchtest mit anderen Kindern eine gemeinsame Zeit verbringen. Dabei erfahrt Ihr viele spannende Dinge über die Feuerwehr. Sport, Spiel und Spaß wird dabei groß geschrieben.</p>
       <p><a style={{fontWeight:"bold"}}>Jugendfeuerwehrwart:</a> Wilfried Kramer</p>
       <p><a style={{fontWeight:"bold"}}>Stellv. Jugendfeuerwehrwart:</a> Marcus Menzel</p>
     
       <ImageView
            show={show ? true:false}
            url={url+"/image"}
            img={image}
            id={ImagePath.id}
            onClose={() => setShow(null)} 
          />
        {ImagePath.id?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {ImagePath.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = ImagePath.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%'}} onClick={() => {setShow(true); setImg(img);}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+ImagePath.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={ImagePath.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div> 
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%", placeItems:"center"}}>
        Das erwartet dich bei den Diensten der Jugendfeuerwehr
       </div> 
       <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%", textAlign:'left'}}>
          <p>- Vermittlung von Grundlagen über die Feuerwehr und deren Fahrzeuge</p>
          <p>- Euch wird beigebracht, wie man sich bei einem Feuer richtig verhält</p>
          <p>- Was bei einem Unfall alles zu beachten ist und vieles mehr</p>
          <p>- Teilnahme an Wettkämpfen und gemeinsame Ausflüge</p>
          <p>- Es können Mädchen und Jungen bei uns mitmachen</p>
          <br></br>
          <br></br>
        <p style={{fontWeight:"bold",fontSize:"160%",textAlign:'center'}}>Kommt einfach vorbei und schaut euch einmal einen  Dienst an. Die komplette Jugendfeuerwehrkleidung wird Euch kostenlos zur Verfügung gestellt. </p>
        
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ position:"relative"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[3]}  
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div></div>
        </div></div> 
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%", placeItems:"center"}}>
        Online Feuerwehr spielen
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p>Hier geht es zum letzten verfügbaren Spiel im Internet, dem „Swissfire - Game“.
Legt schnell die verschiedenen Schläuche, richtet die Strahlrohre aus und löscht das Feuer. Viel Spaß dabei!</p>
<p>Zum Spielen aufs Bild klicken:)</p>    
{ImagePath.urls?(<a href="https://www.cultimedia.ch/swissfire/index-d.html" target="_blank" rel="noopener noreferrer">
          
        <img 
                      className=""
                      style={{ position:"relative"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[1]} 
                      alt={ImagePath.description}
                    /></a>):("Jugendfeuerwehr")}
        </div></div> 
         <br></br>
         <br></br>
    </>
  );
};

export default Jugendfeuerwehr;
