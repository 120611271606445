import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  FormControl,
  Button,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faEnvelope,
  faLock,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import jwt_decode from 'jwt-decode';
import { debounce } from "lodash";


export default function Login ({stateChanger}) {
  const AUTH_URL = process.env.REACT_APP_PUBLIC_BACKEND+"/token";
  const [error, setError] = useState(null);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn]=useState(false);
  const [messages, setMessage]=useState(null);
  const {message}=useParams();
  const initialState = {
    email: "",
    password: "",
  };
  const [user, setUser] = useState(initialState);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  useEffect(()=> {
    logoutUser();
    if(message){ setMessage("You are logged out!");
    logoutUser();
    setShow(true);
   setTimeout(() => {setShow(false); 
    setMessage(null);
  }, 3000);}
},[]);


  function credentialChange(event) {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  function logoutUser () {
      localStorage.clear();
      axios.defaults.headers.Authorization = null;
      setLoggedIn(false);
      stateChanger('New datas');
    };
  

  function validateUser (){ 
      axios.post(AUTH_URL, {
        username: user.email,
        password: user.password,
      }).then((response) => {    
    //  console.log(JSON.stringify(response.status));
      setLoggedIn(true);
      localStorage.setItem("IsLoggedIn", true);
    //  console.log(response.data);
      axios.defaults.headers.Authorization = `Bearer ${response.data}`;
      console.log(jwt_decode(axios.defaults.headers.Authorization).sub); //username
      console.log(jwt_decode(axios.defaults.headers.Authorization).scope); //role
      Promise.resolve(response);
      stateChanger('New data');
      return navigate('/home');
      })
      .catch ((error) => {
      resetLoginForm();
      setError("Invalid email and password");
      setShow(true);
      setTimeout(() => {setShow(false); 
        setError(null);
      }, 3000);
    });
  };
   
  function resetLoginForm(){
    setUser(initialState);
  };
function validateUserByKey(event){
  console.log(event.key);
  if (event.key === 'Enter') {
    validateUser();
  }
}
  return (
    <>
     {size>2?(<><br></br><br></br><br></br><br></br></>):null}
     <br></br>
    <Row style={{justifyContent:'center'}}>
      <Col xs={5}>
        {show && messages && (
          <Alert variant="success" onClose={() => setShow(false)} dismissible>
            {messages}
          </Alert>
        )}
        {show && error && (
          <Alert variant="danger" onClose={() => setShow(false)} dismissible>
            {error}
          </Alert>
        )}
        <Card className={"border border-dark bg-dark text-white"} style={{width:'100%',minWidth:'120%'}}>
          <Card.Header>
            <FontAwesomeIcon icon={faSignInAlt} /> Login
          </Card.Header>
          <Card.Body>
           
              <Form.Group as={Col} style={{marginBottom:"1%"}}>
                <InputGroup>
                 
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                  
                  <FormControl
                    required
                    autoComplete="off"
                    type="text"
                    name="email"
                    value={user.email}
                    onChange={credentialChange}
                    onKeyPress={(e)=>{if(user.email.length !== 0 && user.password.length !== 0)validateUserByKey(e);}}
                    className={"bg-dark text-white"}
                    placeholder="Enter Email Address"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <InputGroup>
              
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faLock} />
                    </InputGroup.Text>
      
                  <FormControl
                    required
                    autoComplete="off"
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={credentialChange}
                    onKeyPress={(e)=>{if(user.email.length !== 0 && user.password.length !== 0)validateUserByKey(e);}}
                    className={"bg-dark text-white"}
                    placeholder="Enter Password"
                  />
                </InputGroup>
              </Form.Group>
         
          </Card.Body>
          <Card.Footer style={{ textAlign: "right" }}>
            <Button
              size="sm"
              type="button"
              variant="success"
              onClick={validateUser}
              disabled={user.email.length === 0 || user.password.length === 0}
            >
              <FontAwesomeIcon icon={faSignInAlt} /> Login
            </Button>{" "}
            <Button
              size="sm"
              type="button"
              variant="info"
              onClick={resetLoginForm}
              disabled={user.email.length === 0 && user.password.length === 0}
            >
              <FontAwesomeIcon icon={faUndo} /> Reset
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
    </>
  );
}

