import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  InputGroup,
  FormControl,
  Badge,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  Carousel,
} from "react-bootstrap";
import { renderToString } from 'react-dom/server';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faClockFour,
  faCalendarDay,
  faClipboardList,
  faFireExtinguisher,
  faTruckMedical,
  faFireAlt,
  faPersonChalkboard,
  faFireBurner,
  faHouseFire,
  faDumpsterFire,
  faHouseLock,
  faHouseFloodWater,
  faExplosion,
  faHeartCircleBolt,
  faSprayCanSparkles,
  faCarBurst,
  faSuitcaseMedical,
  faPersonSwimming,
  faOilCan,
  faRoadCircleExclamation,
  faTree,
  faTruckArrowRight,
  faScrewdriverWrench,
  faWrench,
  faFire,
  faCircleRadiation,
  faRadiation,
  faTowerCell,
  faPeopleRoof,
  faHandsHoldingChild,
  faNewspaper,
  faCircleInfo,
  faIcons,
  faCalendarCheck
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import L from 'leaflet';
  import { useNavigate, useParams } from "react-router-dom";
  import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
  import { icon } from 'leaflet';
import { debounce } from "debounce";
import ImageView from "../ImageView";
  
  export default function CalendarDetailsPage(){
  
      const url= process.env.REACT_APP_PUBLIC_BACKEND;
      const {id}=useParams();
      const navigate=useNavigate();
      const [calendarItem, setCalendarItem]=useState();
      const [links, setLinkFields] = useState([]);
      const [ImagePath, setImagePath]=useState([]);  
      const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
      const [show, setShow]=useState(null);
      const [image, setImg]=useState(null);

      function resolveBreakpoint( width){
        if ( width < 576 ) {
          return 1;
        } else if ( width >= 576 && width < 768 ) {
          return 1;
        } else if ( width >= 768 && width < 992 ) {
          return 2;
        } else if ( width >= 992 && width < 1200 ) {
          return 2;
        } else if ( width >= 1200 && width < 1440 ) {
          return 3;
        } else if ( width >= 1440 ) {
          return 3;
        }
      }
    
      useEffect(() => {
        const calcInnerWidth = debounce(function () {
          setSize(resolveBreakpoint(window.innerWidth));
        }, 200);
        window.addEventListener('resize', calcInnerWidth);
        
        return () => window.removeEventListener('resize', calcInnerWidth);
        
      }, []);

      const [exPic, setExPic] = useState();
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$ExamplePicture*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setExPic({id: data.content[0].id, url: data.content[0].urls.split(",")[0]});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);
        useEffect(()=> {
          if(id!=null){
            axios.get(
                url+"/calendar/public/"+id+"?pageNumber=" +
                  0 +
                  "&pageSize=" +
                  1 +
                  "&sortBy=date&sortDir=" +
                  "asc"
              )
          .then((response) => {
            return response.data;
          })
          .then((data)=>{
            setCalendarItem(data.content[0]);
            if(data.content[0].imageUrl.length>0) setImagePath(data.content[0].imageUrl.split(","));
            handleGenerateFields(data.content[0].links);
          })
          .catch((error) => {
            console.log(error);
          }); }
        },[]); 
      useEffect(()=> {
      if(calendarItem){
      if(id!=null&&id!=calendarItem.id){
        axios.get(
            url+"/calendar/public/"+id+"?pageNumber=" +
              0 +
              "&pageSize=" +
              1 +
              "&sortBy=date&sortDir=" +
              "asc"
          )
      .then((response) => {
        return response.data;
      })
      .then((data)=>{
        setCalendarItem(data.content[0]);
        if(data.content[0].imageUrl.length>0) setImagePath(data.content[0].imageUrl.split(","));
        handleGenerateFields(data.content[0].links);
      })
      .catch((error) => {
        console.log(error);
      });} }
    }); 

    const handleGenerateFields = (string) => {
        if(string.length>0){
        const newFields = string.split(',').map((fieldString) => {
          const [value1, value2] = fieldString.split('|');
          return { value1, value2 };
        });
        setLinkFields(newFields);}
        else{setLinkFields([]);}
      };
    function linkInst(element){
        if(element==="Kubschütz"){
          return("https://www.feuerwehr-kubschuetz.de");
        }
        else if(element==="BF Bautzen"){
          return("https://www.feuerwehr-bautzen.de/");
        }
        else if(element==="FFW Hochkirch"){
          return("https://www.hochkirch.de/einrichtungen/feuerwehr");
        }
        return("https://www.feuerwehr-kubschuetz.de");
      }
    
      function createPill(string){
        const arr=string.split(",");
         return( 
          arr.map((element,i)=>(        
            <a  href={linkInst(element)} target="_blank" rel="noreferrer" className="cardtext">
          <Badge key={i} className="pillinst bottompill" pill bg="light" text="dark">
          {element}     
        </Badge>        
        </a>
          ))
         )
      }
      function createLinkPill(){
         return( 
          links.map((element,i)=>(        
            <a  href={linkInst(element.value2)} target="_blank" rel="noreferrer" className="cardtext">
          <Badge key={i} className="pillinst bottompill" pill bg="light" text="dark">
          {element.value1}     
        </Badge>        
        </a>
          ))
         )
      }
      function getCatIcon (cat){
        if(cat==="Jahreshauptversammlung"){
          return(<FontAwesomeIcon icon={faPeopleRoof} />)
        }
        else if(cat==="Dienst"){
          return(<FontAwesomeIcon icon={faPersonChalkboard} />)
        }
        else if(cat==="Veranstaltung"){
          return(<FontAwesomeIcon icon={faIcons} />)
        }
        else if(cat==="Jugendfeuerwehr"){
            return(<FontAwesomeIcon icon={faHandsHoldingChild} />)
          }
        else if(cat==="Ereignis"){
          return(<FontAwesomeIcon icon={faCalendarCheck} />)
        }
        return(<FontAwesomeIcon icon={faCircleInfo} />)
         }
        

      const picSlides = ImagePath.slice(1).map((mission, index) => {
        if (index % size === 0) {
           const picSlide = ImagePath.slice(1).slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%'}} onClick={() => {setShow(true); setImg(img);}}>
                    <img 
                      className="imagestyle"
                      src={url+"/calendar/image/download/"+id+"/"+img} style={{maxHeight:'400px'}}
                 
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          });
return(
<>
{size>2?(<><br></br><br></br><br></br><br></br></>):null}
{calendarItem?(
    <>
    <div class="containerDetailsRepo">
  <div className={(calendarItem.category!==("Info"||"Sonstige")?("cal"+calendarItem.category):("calInfo"))+" titleRepo"}>
    <div class="IconTitle">
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">{calendarItem.category}</Tooltip>}>
        <Badge pill bg="trasparence" text="dark" className="headpill">
        {getCatIcon(calendarItem.category)}
        </Badge>
        </OverlayTrigger> 
    </div>
    <div class="titleTextRepo">{calendarItem.title}</div>
  </div>
  <div class="textAreaRepo">
    <div class="InfoRepo">{calendarItem.location+", "+new Date(calendarItem.date).toLocaleDateString('de-DE')}</div>
    <div class="InstiRepo">{createPill(calendarItem.institutions)}</div>
    {calendarItem.imageUrl.length>0?(
    <div class="ImagaRepo" onClick={() => {setShow(true); setImg(calendarItem.imageUrl.split(",")[0]);}}><img src={url+"/calendar/image/download/"+calendarItem.id+"/"+calendarItem.imageUrl.split(",")[0]}
className="imagestyle rounded-3"/></div>
):null}
     {links.length>0?(
    <div class="LinksRepo">Weiterführende Links:<br></br>{createLinkPill()}<br></br><br></br></div>):null}
    <div class="textRepo" style={{zIndex:'0'}}>
    <Form.Group controlId="formGridPrice">
                  <Form.Control
                     height="100%"
                     width="100%"
                     rows={15}
                   value={calendarItem.report}
                   required
                    as="textarea" 
                    name="report"
                    disabled
                  />
                </Form.Group> 
        </div>
  </div>
  <div class="ImageConRepo">
  {ImagePath.length>1?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {picSlides}
            </Carousel>
           </>
          ):(<><br></br></>)}
  </div>
</div>
  <ImageView
            show={show ? true:false}
            url={url+"/calendar"}
            img={image}
            id={id}
            onClose={() => setShow(null)} 
          />

</>
):(null)}
 </>
);}