import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Impressum = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "web@feuerwehr-kubschuetz.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();

  const handleEmailClick = () => {
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

 





  return (
    <>
    {size>1?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni">
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Impressum
        </div>
        <div id="672" className="MainParty2" >
        <p style={{padding:"1%"}}>Dies ist keine offizielle Seite der Gemeinde Kubschütz sondern eine private Homepage.</p>
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2">
        Herausgeber
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p>Kameraden/-innen der Freiwillige Feuerwehr Kubschütz</p>
        <br></br>
        <p> Für diese Präsentation im Sinne des geltenden Rechts ist verantwortlich:
Robin Henke</p>
<Button onClick={handleEmailClick}>
      E-Mail senden
    </Button> 
        </div></div>
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2">
        Haftungsausschluss
        </div>
        <div id="672" className="MainParty2" >
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2" style={{backgroundColor:"#ef4f40", fontSize:"90%"}}>
        Haftung für Inhalte
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p> Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
        </div></div> 
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2" style={{backgroundColor:"#ef4f40", fontSize:"90%"}}>
        Haftung für Links
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p> Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
        </div></div> 
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2" style={{backgroundColor:"#ef4f40", fontSize:"90%"}}>
        Urheberrecht
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p> Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
        </div></div> 
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2" style={{backgroundColor:"#ef4f40", fontSize:"90%"}}>
        Datenschutz
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p> Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
        <Button onClick={()=>navigate("/Datenschutzerklärung")}>
      Datenschutzerklärung
    </Button> 
        </div></div> 
        </div></div>  
        <div id="324" className="ConUni">
        <div id="34522" className="titleMain2">
        Lizenzbestimmungen der Webinhalte
        </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}} >
        <p style={{fontWeight:"bold"}}>Font Awesome Free Icons - CC BY 4.0 License </p>   
        <p>Die verwendeten Icons auf dieser Webseite sind von Font Awesome.</p>
        <p><a href="https://fontawesome.com/license/free">Font Awesome Free</a> ist kostenlos, Open Source und GPL-freundlich. Sie können für kommerzielle Projekte und Open-Source-Projekte benutzt werden. </p>
        <p>Hier ist die Lizenz zu finden: <a href="https://creativecommons.org/licenses/by/4.0/">https://creativecommons.org/licenses/by/4.0/</a></p>
        <p style={{fontWeight:"bold"}}>OpenStreetMap und Leaflet</p>  
        <p>OpenStreetMap® ist offene Daten, lizenziert unter der <a href="https://opendatacommons.org/licenses/odbl/">Open Data Commons Open Database License </a> (ODbL) von der <a href="https://osmfoundation.org/">OpenStreetMap Stiftung</a>  (OSMF).</p>
        <p>Es steht Ihnen frei, unsere Daten zu kopieren, zu verbreiten, zu übertragen und anzupassen, solange Sie OpenStreetMap und seine Mitwirkenden nennen. Wenn Sie unsere Daten ändern oder darauf aufbauen, dürfen Sie das Ergebnis nur unter derselben Lizenz verbreiten.</p>
        <p>Der vollständige <a href="https://opendatacommons.org/licenses/odbl/1.0/">Rechtstext</a> erläutert Ihre Rechte und Pflichten. Unsere Dokumentation ist unter der Lizenz <a href="https://creativecommons.org/licenses/by-sa/2.0/">Creative Commons Attribution-ShareAlike 2.0</a> (CC BY-SA 2.0) lizenziert.</p>
        <p>Zur Darstellung von den Inhalten von OpenStreetMap wird <a href="https://leafletjs.com/">Leaflet</a> verwendet - eine Open-Source-JavaScript-Bibliothek
für mobilfreundliche interaktive Karten</p>
        </div></div>   
        </div></div>   
         <br></br>
         <br></br>
    </>
  );
};

export default Impressum;
