import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";

const TodUnschuldigen = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "robinhenke3@web.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$SicherheitBrandschutz*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Tod der Unschuldigen
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p style={{fontWeight:'lighter'}}>Zeilen, die uns nachdenklich stimmen sollten...</p>
        <p style={{marginTop:'3%'}}>Ich ging zu einer Party, <a style={{fontWeight:'bold'}}>Mama</a>, ich erinnerte mich, was Du sagtest.</p>
        <p>Du ermahntest mich, nicht zu trinken, <a style={{fontWeight:'bold'}}>Mama</a>, also trank ich Soda stattdessen.</p>
<p>Ich fühlte mich richtig stolz, <a style={{fontWeight:'bold'}}>Mama</a>, so wie Du es mir vorausgesagt hattest.</p>
<p>Ich habe nicht getrunken, um dann zu fahren, <a style={{fontWeight:'bold'}}>Mama</a>, obwohl mir die anderen sagten, es sei nichts dabei.</p>
<p>Ich weiß, ich tat das Richtige, <a style={{fontWeight:'bold'}}>Mama</a>, ich weiß, Du hast immer recht.</p>
<p>Nun ist die Party zu Ende, <a style={{fontWeight:'bold'}}>Mama</a>, und alle fahren sie fort.</p>
<p>Als ich in meinen Wagen stieg, <a style={{fontWeight:'bold'}}>Mama</a>, wusste ich, ich würde rasch nach Hause fahren,</p>
<p><a style={{fontWeight:'bold'}}>weil Du mich so erzogen hast: Verantwortungsbewusst und lieb.</a></p>
<p>Ich fuhr also los, <a style={{fontWeight:'bold'}}>Mama</a>, aber als ich auf die Straße auffuhr,</p>
<p>sah mich der andere Wagen nicht, <a style={{fontWeight:'bold'}}>Mama</a>, er fuhr einfach über mich drüber.</p>
<p>Als ich lag auf dem Asphalt, <a style={{fontWeight:'bold'}}>Mama</a>, hörte ich den Polizisten sagen,</p>
<p>"Der andere Typ war betrunken", <a style={{fontWeight:'bold'}}>Mama</a>, Und nun bin ich es, die bezahlen wird.</p>
<p>Ich liege hier sterbend, <a style={{fontWeight:'bold'}}>Mama</a>...  Ich wünsche Du wärst bald hier.</p>
<p>Wie konnte das geschehen, <a style={{fontWeight:'bold'}}>Mama</a>? Mein Leben zerplatzte wie ein Ballon.</p>
<p>Überall um mich ist Blut, <a style={{fontWeight:'bold'}}>Mama</a>, das meiste davon ist meins.</p>
<p>Ich höre den Arzt sagen, bald werde ich sterben.</p>
<p>Ich wollte Dir nur sagen, <a style={{fontWeight:'bold'}}>Mama</a>, ich schwöre, ich hab nichts getrunken.</p>
<p>Es waren die anderen, <a style={{fontWeight:'bold'}}>Mama</a>, die anderen haben nicht nachgedacht.</p>
<p>Er war wohl auf derselben Party wie ich.</p>
<p>Der einzige Unterschied ist, <a style={{fontWeight:'bold'}}>er</a> hat was getrunken</p>
<p>Und ich werde sterben.</p>
<p>Warum betrinken sich die Leute, <a style={{fontWeight:'bold'}}>Mama</a>? Es kann ihr ganzes Leben zerstören.</p>
<p><a style={{fontWeight:'bold'}}>Jetzt fühl ich heftige Schmerzen. Es sticht wie ein Messer.</a></p>
<p>Der Typ, der mich anfuhr, der geht, <a style={{fontWeight:'bold'}}>Mama</a>, und ich denke, das ist nicht fair.</p>
<p>Ich liege hier im Sterben</p>
<p>und alles, was er kann, ist, zu starren.</p>
<p>Sag meinem Bruder, er soll nicht weinen, <a style={{fontWeight:'bold'}}>Mama!</a> Schreibt "Papas Mädchen" auf mein Grab.</p>
<p>Jemand hätte ihm sagen sollen, <a style={{fontWeight:'bold'}}>Mama</a>, kein Alkohol hinter dem Steuer.</p>
<p>Wenn sie es ihm bloß gesagt hätten, <a style={{fontWeight:'bold'}}>Mama</a>, wäre ich noch am Leben.</p>
<p>Mein Atem wird kürzer, <a style={{fontWeight:'bold'}}>Mama</a>, ich bekomme solche Angst.</p>
<p>Bitte, weine nicht um mich, <a style={{fontWeight:'bold'}}>Mama</a>. Du warst immer da, als ich Dich brauchte.</p>
<p>Ich hab nur noch eine letzte Frage, <a style={{fontWeight:'bold'}}>Mama</a>, bevor ich mich verabschiede.</p>
        <p style={{marginTop:'1.5%', color: 'red',fontSize:"120%", fontWeight:'bold'}}>Ich bin nicht betrunken gefahren, also warum bin ich diejenige, die sterben muss?</p>
        <p></p>

        
        
      
        </div></div> 
         <br></br>
         <br></br>
         <br></br>

    </>
  );
};

export default TodUnschuldigen;
