import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";
import TimeLine from "./TimeLine";

const OrtswehrKubschütz = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "robinhenke3@web.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$OrtswehrKub*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
           Ortswehr Kubschütz 
       </div>
        <div id="672" className="MainParty2" >
        <TimeLine 
    timelineData={ImagePath.urlsPage?([
      {
        year: 1926,
        text: 'Die Geschichte der Freiwilligen Feuerwehr Kubschütz geht zurück ins Jahr 1926. Die Gemeinde Kubschütz plante, nachdem der erste Weltkrieg vorbei war und das Leben wieder in geordneten Bahnen lief, die Gründung einer Feuerwehr. Am 11. Februar 1926 wurde die Wehr, "nach reiflicher Überlegung" wie es hieß, gegründet. Bereits am 25. Mai 1926 konnte die FFw eine zweirädrige Motorspritze ihr Eigen nennen. In den ersten Monaten des Bestehens schufen die Kameraden ein Spritzenhaus, welches am 05.09.1926 festlich eingeweiht wurde. Nebenstehendes Foto zeigt das damalige Spritzenhaus mit den Kameraden zur Einweihung. Im Vordergrund ist die Motorspritze zu erkennen. Am 14.09.1926 wurde die, bis dahin 31 Mann starke, Feuerwehr in den Landesverband sächsischer Feuerwehren aufgenommen. Leider sind aus der Zeit bis Ende des 2. Weltkrieges nur wenige Aufzeichnungen erhalten geblieben, da viel durch den Krieg zerstört wurde.*',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[2],
        alt: ImagePath.description
      },
      {
        year: 1928,
        text: 'Die Feuerwehrmännner mussten im Einsatz schnell vielen Herausforderungen gerecht werden. Ereignisse, wie der Brand des Sägewerkswerkes Hoffmann in Oberkubschütz 1928 und der Bahnhofsgaststätte Täschner 1932 gingen in die Geschichte. Ein Bericht vom Brand des Stallgebäudes bei Hettmann im Jahre 1939 schildert wie die umliegenden Kameraden mit Pferdegespann und Handdruckspritze zur Hilfe eilten.*',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[7],
        alt: ImagePath.description
      },
      {
        year: 1946,
        text: 'Nach Kriegsende wurde die FFw 1946 neu gegründet. Erste Aufgabe war das Spritzenhaus, welches starke Zerstörungen davongetragen hatte, in Ordnung zu bringen. Mangels Technik wurde bis Mitte der 1950er Jahre zu Einsätzen mit Zugmaschine und offenem Anhänger gefahren. Erst danach bekam die Wehr einen TSA. Von der deutschen Post, dem Patenbetrieb der FFw, übernahm die Wehr ein ausgesondertes Postauto. Mit diesem konnten die Männer schneller zum Einsatzort gelangen. Am 15.04.1969 gründete sich eine Frauengruppe, deren Hauptaufgabe im vorbeugenden Brandschutz lag. Im gleichen Jahr wurde auch das Löschfahrzeug LF8-LO in Dienst gestellt, welches heut als Oldtimer in liebevolle Hände von Fahrzeugfreunden gekommen ist.*',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[4],
        alt: ImagePath.description
      },
      {
        year: 1969,
        text: 'Durch den Rat des Lreises Bautzen wurde der FFw ein neues Löschfahrzeug -der ELO- zur Nutzung übergeben. In diesem Jahr entstand am 15.04. innerhalb der FFw eine Frauengruppe. Ihre Hauptaufgabe war der vorbeugende Brandschutz. Dazu gehörten u.a. Kontrollen in Wohnstätten und Betrieben. Gewissenhaft wiesen die Frauen die Bewohner und Arbeiter auf mögliche Brandgefahren hin. Vorallem während der Erntezeit waren die Kontrollen in den Scheunen und anderen landwirtschaftlichen Gebäuden von großen Nutzen*',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[6],
        alt: ImagePath.description
      },
      {
        year: 1972,
        text: 'Im Jahr 1972 erfolgte der Umbau einer Scheune zum Gerätehaus für die Kubschützer Feuerwehr. Dabei wurden auch viele freiwillige Einsatzstunden von den Kameraden geleistet. Dieses Gerätehaus war der Stützpunkt der Kubschützer, bis am 31.10.2003 das neue moderne Gerätehaus übergeben wurde.',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[8],
        alt: ImagePath.description
      },
      {
        year: 1991,
        text: 'Durch die Auflösung des NVA-Flugplatzes Litten war die FFw in der glücklichen Lage einen LKW W 50-Tanker (Baujahr 1976) günstig in Besitz zu nehmen. Der verbundene bessere Ausrüstungsstandard bedeutete wesentliche Erleichterungen für die Männer bei den Einsätzen.*',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[5],
        alt: ImagePath.description
      },
      {
        year: 1993,
        text: 'Durch die Gemeinde konnte noch ein zusätzlicher Tanker W50 TLF 16 (Baujahr 1986) für 5000,- DM vom Strafvollzug erworben werden*',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[10],
        alt: ImagePath.description,
        link:'http://localhost:3000/Fahrzeugtechnik#tlf16'
      },
      {
        year: 1996,
        text: 'Das alte Gerätehaus aus dem Jahre 1926 wurde 1996 rekonstruiert und zum Jugendklub um- und ausgebaut.',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[3],
        alt: ImagePath.description
      },
      {
        year: 2002,
        text: 'Am 04.03.2002 war Beginn der Bauarbeiten für das langersehnte neue Gerätehaus der FFw. Die Grundsteinlegung erfolgte feierlich am 05.04.2002',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[11],
        alt: ImagePath.description
      },
      {
        year: 2003,
        text: 'Am 31.10.2003 wurde das neue moderne Gerätehaus der Freiwilligen Feuerwehr Kubschütz eingeweiht. Desweiteren konnte der Fuhrpark durch ein modernes Tragkraftspritzenfahrzeug TSF-W/z erweitert werden',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[1],
        alt: ImagePath.description,
        link:'#tsfw'
      },
      {
        year: 2009,
        text: 'Durch die Unterstützung der Gemeinde wurde ein Hilfeleistungslöschgruppenfahrzeug 10 (kurz: HLF 10) für die FFw kubschütz angeschafft. Der einwandfreie TSF-W/z wurde in diesem Zug zu der Ortswehr Rachlau gegeben.',
        image: url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[9],
        alt: ImagePath.description,
        link:'#hlf10'
      },
      // Add more timeline items as needed
    ]):null}
    />
       
        <p>* Auszug aus der "Chronik anlässlich des 70 jährigen Bestehens der FFw Kubschütz"</p>
        </div></div> 
         <br></br>
         <br></br>
         <br></br>
    </>
  );
};

export default OrtswehrKubschütz;
