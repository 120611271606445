import React, { useEffect, useState } from "react";
import debounce from 'debounce';
import { Badge, Button, Card, CardGroup, Carousel, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faClockFour,
  faCalendarDay,
  faClipboardList,
  faFireExtinguisher,
  faTruckMedical,
  faFireAlt,
  faPersonChalkboard,
  faFireBurner,
  faHouseFire,
  faDumpsterFire,
  faHouseLock,
  faHouseFloodWater,
  faExplosion,
  faHeartCircleBolt,
  faSprayCanSparkles,
  faCarBurst,
  faSuitcaseMedical,
  faPersonSwimming,
  faOilCan,
  faRoadCircleExclamation,
  faTree,
  faTruckArrowRight,
  faScrewdriverWrench,
  faWrench,
  faFire,
  faCircleRadiation,
  faRadiation,
  faTowerCell,
  faAngleDown,
  faAngleUp,
  faCircleInfo,
  faPeopleRoof,
  faHandsHoldingChild,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function ReportCarousel(props) {
  const url= process.env.REACT_APP_PUBLIC_BACKEND;
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const [missions, setMissions]=useState([]);
	const navigate=useNavigate();
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(3);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 3;
    } else if ( width >= 576 && width < 768 ) {
      return 3;
    } else if ( width >= 768 && width < 992 ) {
      return 4;
    } else if ( width >= 992 && width < 1200 ) {
      return 5;
    } else if ( width >= 1200 && width < 1440 ) {
      return 6;
    } else if ( width >= 1440 ) {
      return 7;
    }
  }

  useEffect(()=> {  
    findAllMissions();  
  },[]);
  
  function findAllMissions() {
    axios
      .get(
        url+props.filter
      )
      .then((response) => response.data)
      .then((data) => {
        //console.log(data);
       setMissions(data.content);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);
    const [index, setIndex] = useState(0);
  
        const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
   

      const handlePrevClick = () => {
        if (startIndex > 0) {
          setStartIndex(startIndex - 1);
          setEndIndex(endIndex - 1);
        }
      };
    
      const handleNextClick = () => {
        if (endIndex < missions.length) {
          setStartIndex(startIndex + 1);
          setEndIndex(endIndex + 1);
        }
      };
    function linkInst(element){
      if(element==="Kubschütz"){
        return("https://www.feuerwehr-kubschuetz.de");
      }
      else if(element==="BF Bautzen"){
        return("https://www.feuerwehr-bautzen.de/");
      }
      else if(element==="FFW Hochkirch"){
        return("https://www.hochkirch.de/einrichtungen/feuerwehr");
      }
      return("https://www.feuerwehr-kubschuetz.de");
    }

    function createPill(string){
      const arr=string.split(",");
       return( 
        arr.map((element,i)=>(        
          <a  href={linkInst(element)} target="_blank" rel="noreferrer" className="cardtext">
        <Badge key={i} className="pillinst bottompill" pill bg="light" text="dark">
        {element}     
      </Badge>        
      </a>
        ))
       )
    }
 function getCatIcon (cat){
if(cat==="Jahreshauptversammlung"){
  return(<FontAwesomeIcon icon={faPeopleRoof} />)
}
else if(cat==="Dienstbericht"){
  return(<FontAwesomeIcon icon={faPersonChalkboard} />)
}
else if(cat==="Jugendfeuerwehr"){
  return(<FontAwesomeIcon icon={faHandsHoldingChild} />)
}
else if(cat==="News"){
  return(<FontAwesomeIcon icon={faNewspaper} />)
}
return(<FontAwesomeIcon icon={faCircleInfo} />)
 }




    const cardSlides =
        missions.slice(startIndex, endIndex).map((card, i) => {
        return ( 
      <div key={i} onClick={() => navigate("/aktuelles/details/"+card.id)}  className={(card.category!==("Info"||"Sonstige")?("report"+card.category):("reportInfo"))+" containerReport"} >

       <div className="dateReport">
       {card.location+", "+new Date(card.date).toLocaleDateString('de-DE')}
       </div>

        <div className="catReport">
        <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">{card.category}</Tooltip>}>
        <Badge pill bg="trasparence" text="dark" className="headpill">
        {getCatIcon(card.category)}
        </Badge>
        </OverlayTrigger>
        </div>

      <div className="ImageReport">
      <img  src={url+"/report/image/download/"+card.id+"/"+card.imageUrl.split(",")[0]} className={"ImgStyleReport"}/>
      </div>

      <div className="titleReport">{card.title}</div>

      <div className="textBegin">{card.report.substring(0,200)+"..."}</div>
 
      <div className="instiReport">{createPill(card.institutions)}</div>
      </div> 
    );
        })


  
    return (
      <div style={{paddingBottom:'0.5%',paddingTop:'0.5%'}}>
       <Container
      fluid
      className="reportslide"
    >
        {missions?(cardSlides):null}
    <div className="d-flex justify-content-between align-items-center">
    <Button  className="controlTop" variant="transparent" onClick={handlePrevClick} disabled={startIndex === 0}><FontAwesomeIcon icon={faAngleUp} style={{color: "grey"}}/></Button>
      <Button className="controlBottom" variant="transparent" onClick={handleNextClick} disabled={endIndex === missions.length}><FontAwesomeIcon icon={faAngleDown} style={{color: "grey"}}/></Button>
      </div>
      </Container>
      </div>
    
    );
  }