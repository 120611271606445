import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";

const Rauchmelder = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$Rauchmelder*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
       Rauchmelder
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>

        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"110%"}}>
        Warum Rauchmelder installieren?
       </div> 
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{fontWeight:"bold"}}>Brandtote sind Rauchtote</p>
        <p>Noch immer sterben bundesweit jährlich mehr als 600 Menschen bei Haus- und Wohnungsbränden. 2/3 dieser Brände entstehen nachts, wenn die Bewohner schlafen. Dabei sterben die Bewohner nicht durch die Flammen, sondern an den Folgen einer Rauchvergiftung. Diese Rauchtote haben im Schlaf keine Chance, weil dichter Rauch sich sekundenschnell ausbreitet und bereits wenige Atemzüge zum Tode führen können. Gleichwohl ist der Geruchsinn im Schlaf "ausgeschalten". Man wird durch "Gestank" nicht munter, wie sehr viele Menschen glauben!</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[0]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div></div>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{fontWeight:"bold"}}>Gefährlicher Irrtum</p>
        <p>Umfragen haben gezeigt, dass die Bundesbürger nur sehr unzureichend über die Risiken bei Haus- und Wohnungsbränden informiert sind.Das kann fatale Folgen haben. So glaubte eine Mehrheit der Befragten, im Brandfall mehr als zehn Minuten Zeit zu haben um Haus oder Wohnung zu verlassen. Ein lebensgefährlicher Irrtum, denn durchschnittlich bleiben gerade einmal vier Minuten um dem Feuer unverletzt zu entkommen. Auch die weit verbreitete Meinung, offenes Feuer oder glühende Zigarettenkippen seien stets die Ursache von Wohnungsbränden trügt. Denn häufig war ein technischer Defekt die Ursache für die Tragödie - und das mit gefährlich steigender Tendenz. Viele Geräte, wie z.B. TV- und Videoanlagen, Computer aber auch einfache Haushaltsgeräte wie Kaffeemaschinen und Toaster stehen mittlerweile rund um die Uhr unter schleichendem Strom. Und genau da lauert die Gefahr!</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"3%"}}>
        <p style={{fontWeight:"bold"}}>Rauchmelder retten Leben</p>
        <p>Ein Rauchmelder warnt rechtzeitig - auch im Schlaf! Mit einem durchdringenden Alarmton verschafft das kleine Gerät die nötige Zeit um dem Feuer zu entkommen, die Feuerwehr zu rufen oder möglicherweise den Brand selbst zu löschen.</p>
        <p>Die Statistik aus England belegt sehr eindrucksvoll die lebensrettende Wirkung von Rauchmeldern: Während der Anteil mit Rauchmeldern ausgestatteter Haushalte von ca. 8% auf 82% stieg, ging die Zahl der Brandopfer von 731 auf unter 500 zurück.</p>
        </div></div>
        </div></div>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"110%"}}>
        Wie funktioniert ein Rauchmelder?
       </div> 
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p>Heimrauchmelder sind batteriebetrieben und bieten dadurch zwei entscheidende Vorteile: Sie können von jedermann in wenigen Minuten installiert werden und die Geräte funktionieren auch bei Stromausfall. Foto-optische Rauchmelder erkennen bereits kleinste Partikel des gefährlichen Brandrauchs in der Raumluft (siehe Grafik) und alarmieren mit einem extrem lauten Alarmton, der auch im Schlaf hörbar ist.</p>
        <p style={{fontWeight:"bold"}}>Optische Rauchmelder arbeiten praktisch wartungsfrei.</p>
        <p>Ein Testknopf ermöglicht die regelmäßige Überprüfung der Funktionsbereitschaft. Der fällige Batteriewechsel (bei Alkaline-Batterien nach 2 - 3 Jahren) wird durch einen regelmäßig wiederkehrenden Piepton gemeldet. Gute Markengeräte leisten dieses Warnsignal vier Wochen lang, um den notwendigen Batteriewechsel auch bei längerer Abwesenheit nicht zu versäumen.</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ height:"80%", width: "80%"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[3]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
        </div></div>
        </div></div>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"110%"}}>
        Wo sollte ein Rauchmelder installiert werden?
       </div> 
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p>Experten empfehlen als Mindestschutz einen Rauchmelder pro Etage. Dieser sollte an zentraler Stelle (gewöhnlich im Flur) installiert werden. Rauchmelder gehören grundsätzlich an die Decke (weil Brandrauch nach oben steigt) und sollten möglichst in der Mitte des Raumes positioniert werden.</p>
        <p style={{fontWeight:"bold"}}>Darüber hinaus empfiehlt sich die Installation von Rauchmeldern insbesondere in Räumen in denen Elektrogeräte betrieben werden, sowie in Schlaf- und Kinderzimmern.</p>
        <p>Auch im Keller und auf dem Dachboden sollte ein Rauchmelder vorhanden sein, da hier oft Brände unbemerkt entstehen. In Küche und Bad werden wegen der auftretenden Dämpfe keine Rauchmelder installiert. Für diese Räume gibt es sogenannte Wärmemelder, die nicht auf Rauch sondern auf Temperaturanstieg reagieren.</p>
        <p style={{fontWeight:"bold"}}> Heimrauchmelder sind schnell und einfach installiert.</p>
        <p>Wichtig: Gute Markengeräte verfügen über einen Montageteller, auf dem das Gerät arretiert wird. Eine langlebige Alkaline-Batterie sowie das Befestigungsmaterial (Schrauben und Dübel) sind im Lieferumfang enthalten.</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ height:"80%", width: "80%"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[2]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
                    <p style={{marging:"1%"}}>Mehrstöckiges Haus</p>
        </div></div>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{maxHeight:"350px", height:"80%", width: "80%"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[1]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
                        <p style={{marging:"1%"}}>Kleinwohnung</p>
                    </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{maxHeight:"350px", height:"80%", width: "80%"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[5]} 
                      alt={ImagePath.description}
                    />):("Jugendfeuerwehr")}
                    <p style={{marging:"1%"}}>3-Zimmer-Wohnung</p>
        </div></div>
        </div></div>


        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"110%"}}>
        Was ist beim Kauf von Rauchmeldern zu beachten?
       </div> 
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{fontWeight:"bold"}}>Rauchmelder ist nicht gleich Rauchmelder!</p>
        <p>Feuerwehren und Versicherungen empfehlen ausdrücklich nur Heimrauchmelder, deren einwandfreie Funktion von der <a href="https://vds.de/">VdS Schadenverhütung</a> gemäß prEN 12239 geprüft und zertifiziert wurde. Manche ungeprüften Billiggeräte schlagen erst bei einer Rauchkonzentration von 30% Alarm: im Ernstfall also viel zu spät. Achten Sie daher auf Rauchmelder, die das VdS-Prüfzeichen tragen, das eine Alarmmeldung schon bei etwa 1,1% Rauchdichte garantiert.</p>
        <p style={{fontWeight:"bold"}}> Diese Geräte erkennen Sie eindeutig am <a style={{fontWeight:"bold"}}>VdS-Prüfzeichen in Verbindung mit der VdS-Prüfnummer</a> auf der Verpackung.
VdS-geprüfte Qualitätsmelder lösen zudem durch Insekten- und Lichtschutz sowie ihre moderne Technik kaum Fehlalarme aus.</p>
        
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        <p style={{fontWeight:"bold"}}>Achten Sie darüber hinaus auf folgende Qualitätsmerkmale:</p>
        <p>+ Optisches bzw. foto-elektrisches Detektionsverfahren</p>
        <p>+ Lauter durchdringender Alarmton (85dBA/3m)</p>
        <p>+ Testknopf zur Kontrolle der Funktionsbereitschaft</p>
        <p>+ Batteriewechselsignal</p>
        <p>+ Rauch kann von allen Seiten gut in den Melder eindringen</p>
        <p style={{fontWeight:"lighter"}}>Auf den Internetseiten der <a href="https://vds.de/">VdS-Schadenverhütung</a> finden Sie alle geprüften Heimrauchmelder mit Prüfnummer dargestellt.</p>
        <p></p>
        {ImagePath.id?(<a href="https://vds.de/"><img 
                      className="imagestyle"
                      style={{ height:"15%", width: "6%", marginBottom:'2.5%'}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[4]} 
                      alt={ImagePath.description}
                    /></a>):("Jugendfeuerwehr")}
                    
        </div></div>
    
        </div></div>
        </div></div> 
        
         <br></br>
         <br></br>
    </>
  );
};

export default Rauchmelder;
