import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [fullYear, setFullYear] = useState();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const [icon, setIcon] = useState();
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const navigate=useNavigate();

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  useEffect(() => {
    setFullYear(new Date().getFullYear());
  }, [fullYear]);

 useEffect(() => {
    axios
    .get(
      url+"/image/search/" +
      "$GemeindeKub*" +
        "?page=" +
        0 +
        "&size=" +
        1
    )
    .then((response) => response.data)
    .then((data) => {
        setIcon({id: data.content[0].id, link:  data.content[0].link,url: data.content[0].urls.split(",")[0]});  
    })
    .catch((error) => {
      console.log(error);
    });
	}, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      setVisible(prevScrollPos < currentScrollPos || currentScrollPos < 10);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible]);



  return (
    <>
    {visible?(
    <Navbar style={{maxHeight: '50px'}} fixed="bottom" bg="dark" variant="dark">
      <Container>
        <Col>
        <ButtonGroup style={{color:"white"}}>
        <Button variant="transparent" 
        style={{fontSize: size<2?('60%'):('100%') }}
                className="text-center text-muted"
                onClick={() =>( navigate("/Impressum"))}>
                  
        Impressum
        </Button>
        <Button variant="transparent"
                className="text-center text-muted"
                style={{fontSize: size<2?('60%'):('100%') }}
                onClick={() =>(navigate("/Datenschutzerklärung"))}>
                 
        Datenschutzerklärung
        </Button>
       
        </ButtonGroup>
        </Col>
        <Col  className="text-center text-muted">
          <div style={{fontSize: size<4?('40%'):('90%') }}>
          {size>3?(<><FontAwesomeIcon icon={faCopyright} />&nbsp;{fullYear}-{fullYear + 1}, FREIWILLIGE FEUERWEHR KUBSCHÜTZ</>):(<><FontAwesomeIcon icon={faCopyright} />&nbsp;{fullYear} FFW KUBSCHÜTZ</>)}
          </div>
        </Col>
        <Col>
        <div className="socialmediaFooter">
        <a href="https://de-de.facebook.com/ffwkubschuetz/" target="_blank" rel="noopener noreferrer" style={{marginRight:"2%"}}>
          <FontAwesomeIcon icon={faFacebook} size={size < 3 ? "1x" : "2x"} className="facebook rounded-5 text-muted" />
        </a>
        <a href="https://twitter.com/FF_Kubschuetz" target="_blank" rel="noopener noreferrer" style={{marginRight:"2%"}}>
          <FontAwesomeIcon icon={faTwitter} size={size < 3 ? "1x" : "2x"} className="twitter rounded-5 text-muted"/>
        </a>
        <a href="https://www.instagram.com/ffw.kubschuetz/" target="_blank" rel="noopener noreferrer" style={{marginRight:"2%"}}>
          <FontAwesomeIcon icon={faInstagram} size={size < 3 ? "1x" : "2x"} className="instagram rounded-4 text-muted"/>
        </a>
        </div>
        </Col>
      </Container>
    </Navbar>):null}
    </>
  );
};

export default Footer;
