import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";


const ImageView = (props) => {
    const toastCss = {
        position: "fixed",
        top: "0px",
        left:"0px",
        bottom: "0px",
        right: "0px",
        zIndex: "8",
        backgroundColor: "rgba(121, 121, 121, 0.8",
      };
      const handleClose = () => {
        if (props.onClose) {
          props.onClose();
        }
      };

      return (
        <>
        {(props.show)?(
        <div style={props.show ? toastCss : null}>
         <Button variant="danger" size="xl" onClick={handleClose} style={{position: 'absolute', top: '1%',right:'1%'}}>
           <FontAwesomeIcon icon={faSquareXmark} />
          </Button>
          <img 
                      className="imagestyle"
                      src={props.url+"/image/download/"+props.id+"/"+props.img} />
        </div>
        ):(null)}
        </>
      );
    };
  
  export default ImageView;
