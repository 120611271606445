import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";

const Fahrzeugtechnik = () => {
  const [sizeWindow, setSizeWindow]=useState();
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const navigate=useNavigate();
 
  const [HLF, setHLF]=useState();
  const [TLF, setTLF]=useState();
  const [TLF3000, setTLF3000]=useState();
  const [MTWK, setMTWK]=useState();
  const [MTWR, setMTWR]=useState(); 
  const [TSFW, setTSFW]=useState(); 
  const [LF8TS, setLF8TS]=useState(); 
  const [HLFKB, setHLFKB]=useState(); 
  const [KLF, setKLF]=useState(); 

 
  const [show, setShow]=useState(null);
  const [id, setID]=useState(null);
  
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$FZHLF10*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setHLF({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        setTimeout(() => {
        axios
        .get(
          url+"/image/search/" +
          "$FZTLF16*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setTLF({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});    
        })
        .catch((error) => {
          console.log(error);
        });
        }, 200);
        setTimeout(() => {
          axios
          .get(
            url+"/image/search/" +
            "$FZTLF3000*" +
              "?page=" +
              0 +
              "&size=" +
              1
          )
          .then((response) => response.data)
          .then((data) => {
              setTLF3000({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});    
          })
          .catch((error) => {
            console.log(error);
          });
          }, 200);
        setTimeout(() => {
        axios
        .get(
          url+"/image/search/" +
          "$FZMTWKUB" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setMTWK({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});   
        })
        .catch((error) => {
          console.log(error);
        });
    }, 400);
        setTimeout(() => {
        axios
        .get(
          url+"/image/search/" +
          "$FZMTWRA*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setMTWR({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
    }, 600);
    setTimeout(() => {
        axios
        .get(
          url+"/image/search/" +
          "$FZTSFW*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setTSFW({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
    }, 600);
    setTimeout(() => {
        axios
        .get(
          url+"/image/search/" +
          "$FZLF8TS*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setLF8TS({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
    }, 600);
    setTimeout(() => {
        axios
        .get(
          url+"/image/search/" +
          "$FZKLF*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setKLF({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
    }, 600);
    setTimeout(() => {
        axios
        .get(
          url+"/image/search/" +
          "$FZHLF10KB*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            setHLFKB({id: data.content[0].id, urls: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
    }, 600);
        
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      setSizeWindow(1);
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      setSizeWindow(2);
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      setSizeWindow(2);
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      setSizeWindow(3);
      return 2;
    } else if ( width >= 1200 && width < 1440 ) {
      setSizeWindow(4);
      return 2;
    } else if ( width >= 1440 ) {
      setSizeWindow(5);
      return 2;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {sizeWindow>2?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Überblick Fahrzeugtechnik der Gemeinde Kubschütz
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>


        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="hlf10" className="titleMain2" style={{ fontSize:"110%"}}>
            HLF10/6 der Ortswehr Kubschütz</div>
       
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a> HLF 10/6</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a> Florian Kubschütz 40/1</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a> IVECO MAGIRUS FF 100 E 25 4X4 (perm. Allradantrieb), 251 PS</p>
        <p><a style={{fontWeight:"bold"}}>Baujahr: </a>  2009</p>
        <p><a style={{fontWeight:"bold"}}>Aufbau: </a> IVECO MAGIRUS Brandschutztechnik Görlitz GmbH</p>
        <p><a style={{fontWeight:"bold"}}>Besatzung: </a> 1:8</p>
        <p><a style={{fontWeight:"bold"}}>Tankinhalt: </a> 1000l</p>
        <p><a style={{fontWeight:"bold"}}>Pumpe: </a> MAGIRUS Feuerlöschpumpe FP 10-1000</p>
        <p><a style={{fontWeight:"bold"}}>Besonderheiten: </a>Lichtmast mit Xenonscheinwerfer, hydraulischer Rettungssatz</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        <ImageView
            show={show ? true:false}
            url={url+"/image"}
            img={image}
            id={id}
            onClose={() => setShow(null)} 
          />
        {HLF?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {HLF.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = HLF.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%'}} onClick={() => {setShow(true); setImg(img); setID(HLF.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+HLF.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={HLF.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="tlf16" className="titleMain2" style={{ fontSize:"110%"}}>
            TLF3000 der Ortswehr Kubschütz </div>
       
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a> TLF 3000</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a> Florian Kubschütz 11/26/01</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a> MAN TGM 13.290, 290PS, 14t</p>
        <p><a style={{fontWeight:"bold"}}>Antrieb: </a> MAN Automatik 4x4 (permanent Allrad)</p>
        <p><a style={{fontWeight:"bold"}}>Baujahr: </a>  2024</p>
        <p><a style={{fontWeight:"bold"}}>Aufbau: </a> Magirus Ulm </p>
        <p><a style={{fontWeight:"bold"}}>Besatzung: </a> 1:2</p>
        <p><a style={{fontWeight:"bold"}}>Tankinhalt: </a> 3500l</p>
        <p><a style={{fontWeight:"bold"}}>Pumpe: </a> 2.650l/min bei 10 bar</p>
        <p><a style={{fontWeight:"bold"}}>Besonderheiten: </a>Festmontierter Frontmonitor (Wasserwerfer) 2000 Liter/min, ⁠Heckwarneinrichtung, ⁠pneumatischer Lichtmast (6x 24V LED Scheinwerfer), ⁠Rettungssäge, ⁠Bodensprühanlage Voder- und Hinterachse, ⁠Stab-Fast System, ⁠Wärmebildkamera</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
       
        {TLF3000?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {TLF3000.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = TLF3000.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%', margin:'2%'}} onClick={() => {setShow(true); setImg(img); setID(TLF3000.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+TLF3000.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={TLF3000.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>

        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"110%"}}>
        MTW der Ortswehr Kubschütz</div>
       
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a>  MTW</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a> Florian Kubschütz 10/1</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a>  VW T4 Synchro</p>
        <p><a style={{fontWeight:"bold"}}>Baujahr: </a>  1995</p>
        <p><a style={{fontWeight:"bold"}}>Besonderheiten: </a>ehemaliges Fahrzeug der Polizei, für die Feuerwehr umgerüstet </p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {MTWK?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {MTWK.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = MTWK.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%', margin:'2%'}} onClick={() => {setShow(true); setImg(img); setID(MTWK.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+MTWK.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={MTWK.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>

        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="tsfw" className="titleMain2" style={{ fontSize:"110%"}}>
        TSF-W der Ortswehr Rachlau</div>
       
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a>   TSF-W/z</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a>  Florian Rachlau 46/1</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a>  MB 815D Allrad</p>
        <p><a style={{fontWeight:"bold"}}>Baujahr: </a>  2003</p>
        <p><a style={{fontWeight:"bold"}}>Aufbau: </a> Brandschutztechnik Görlitz GmbH</p>
        <p><a style={{fontWeight:"bold"}}>Besatzung: </a> 1:5</p>
        <p><a style={{fontWeight:"bold"}}>Tankinhalt: </a> 750 Liter</p>
        <p><a style={{fontWeight:"bold"}}>Pumpe: </a>  Hochdruckeinrichtung für Schnellangriff, angetrieben
  über Fahrzeugmotor</p>
        <p><a style={{fontWeight:"bold"}}>Besonderheiten: </a>TS8 von Magirus auch im verladenen Zustand
  einsetzbar, Lichtmast, akkubetriebener Rettungssatz,
  Hochleistungslüfter</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {TSFW?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {TSFW.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = TSFW.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%', margin:'2%'}} onClick={() => {setShow(true); setImg(img); setID(TSFW.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+TSFW.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={TSFW.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>

        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"110%"}}>
        MTW der Ortswehr Rachlau</div>
       
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a>  MTW</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a> Florian Rachlau 10/1</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a>  VW T4 Synchro</p>
        <p><a style={{fontWeight:"bold"}}>Baujahr: </a>  1997</p>
        <p><a style={{fontWeight:"bold"}}>Besonderheiten: </a>ehemaliges Fahrzeug der Polizei,
 durch die Rachlauer Kameraden in
 vielen Stunden Freizeit für die Feuerwehr umgebaut </p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {MTWR?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {MTWR.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = MTWR.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%', margin:'2%'}} onClick={() => {setShow(true); setImg(img); setID(MTWR.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+MTWR.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={MTWR.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="656" className="titleMain2" style={{ fontSize:"110%"}}>
        LF8-TS der Ortswehr Kubschütz</div>
       
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a> LF8-TS</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a> Florian Baschütz 43/1</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a> Robur LO 3000</p>
        <p><a style={{fontWeight:"bold"}}>Besatzung: </a> 1:8</p>
        <p><a style={{fontWeight:"bold"}}>Besonderheiten: </a>Neu aufgebaut mit Deutz Motor und neuer Bremsanlage</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        
        {LF8TS?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {LF8TS.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = LF8TS.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%', margin:'2%'}} onClick={() => {setShow(true); setImg(img); setID(LF8TS.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+LF8TS.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={LF8TS.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"110%"}}>
        HLF10 Kleinbautzen-Preititz-Purschwitz</div>
       
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a>   HLF 10</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a>  Florian Kleinbautzen 40/1</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a> MAN TGM 13.290 4x4</p>
        <p><a style={{fontWeight:"bold"}}>Baujahr: </a>  2021</p>
        <p><a style={{fontWeight:"bold"}}>Aufbau: </a>Magirus</p>
        <p><a style={{fontWeight:"bold"}}>Besatzung: </a> 1:8</p>
        <p><a style={{fontWeight:"bold"}}>Tankinhalt: </a>  1000 l</p>
        <p><a style={{fontWeight:"bold"}}>Pumpe: </a>  1000 l/min bei 10 bar</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {HLFKB?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {HLFKB.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = HLFKB.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%', margin:'2%'}} onClick={() => {setShow(true); setImg(img); setID(HLFKB.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+HLFKB.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={HLFKB.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="klf" className="titleMain2" style={{ fontSize:"110%"}}>
        KLF Barkas der Löschgruppe Soritz</div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a>   KLF</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a>  Florian Soritz 41/1</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a> Barkas</p>
        <p><a style={{fontWeight:"bold"}}>Pumpe: </a>  TS8 (DDR)</p>
        <p><a style={{fontWeight:"bold"}}>Besonderheiten: </a>kommt selten zu Einsatz, da die Soritzer Kameraden aktiv die OW Kubschütz unterstützen</p>
        </div>
        <div id="5511" className="PicPartUni" style={{padding:"1%"}}>
        {KLF?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {KLF.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = KLF.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%', margin:'2%'}} onClick={() => {setShow(true); setImg(img); setID(KLF.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+KLF.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={KLF.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>
        <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="tlf16" className="titleMain2" style={{ fontSize:"110%"}}>
            TLF/16 der Ortswehr Kubschütz (außer Dienst)</div>
       
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p><a style={{fontWeight:"bold"}}>Bezeichnung: </a> TLF 16</p>
        <p><a style={{fontWeight:"bold"}}>Funkrufname: </a> Florian Kubschütz 23/1</p>
        <p><a style={{fontWeight:"bold"}}>Fahrzeug: </a> IFA W50</p>
        <p><a style={{fontWeight:"bold"}}>Baujahr: </a>  1985</p>
        <p><a style={{fontWeight:"bold"}}>Aufbau: </a> IVECO MAGIRUS Brandschutztechnik Görlitz GmbH</p>
        <p><a style={{fontWeight:"bold"}}>Besatzung: </a> 1:5</p>
        <p><a style={{fontWeight:"bold"}}>Tankinhalt: </a> 2200l</p>
        <p><a style={{fontWeight:"bold"}}>Pumpe: </a> FPH16 (1600l/min)</p>
        <p><a style={{fontWeight:"bold"}}>Besonderheiten: </a>Bodensprühdüsen vorn, Stromerzeuger </p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
       
        {TLF?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {TLF.urls.map((mission,index) => {
        if (index % size === 0) {
           const picSlide = TLF.urls.slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%', margin:'2%'}} onClick={() => {setShow(true); setImg(img); setID(TLF.id)}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/image/image/download/"+TLF.id+"/"+img} style={{maxHeight:'300px'}}
                      alt={TLF.description}
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          })}
            </Carousel>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        </div></div>

        </div></div> 
         <br></br>
    </>
  );
};

export default Fahrzeugtechnik;
