import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageView from "../ImageView";

const GedankenFeuerwehrmann = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "robinhenke3@web.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$SicherheitBrandschutz*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Geanken eines Feuerwehrmannes
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        <p>Ein Gedicht aus dem großen Netz, das vor allem die Kritiker unserer Zunft, aber auch einfach Außenstehende zum Nachdenken anregen sollte. Es vermittelt einen Eindruck der seelischen Situation unserer Kameraden nach solchen Einsätzen und wir denken,es dürfte sich jeder ehrliche Feuerwehrmann in mindestens einem Vers wieder finden.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>den Kummer des Geschäftsmannes sehen, als sein Lebenswerk in Flammen aufging
oder die Familie, die nach Hause kam, nur um ihr Haus und ihre Habseligkeiten beschädigt oder sogar zerstört vorzufinden.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>fühlen, wie es ist, ein brennendes Schlafzimmer nach eingeschlossenen Kindern abzusuchen; die Flammen schlagen über deinen Kopf hinweg, während des Kriechens schmerzen deine Handflächen und Knie, der Fußboden gibt unter deinem Gewicht nach, wenn die Küche unter dir zu brennen anfängt. </p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte du könntest</p>
        <p>die Furcht in den Augen einer Ehefrau um 3 Uhr morgens sehen, wenn ich ihrem 40 Jahre altem Ehemann den Puls fühle und keinen finde, ich beginne mit der Herz-Lungen-Wiederbelebung, hoffe wider besseren Wissens ihn zurückzuholen, aber ich weiß, dass es zu spät ist. Aber seiner Frau und seiner Familie muss ich das Gefühl geben, dass alles Mögliche getan wurde.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>den unvergleichlichen Geruch von brennenden Isolierungen, den Geschmack vonRuß auf deinen Schleimhäuten, das Gefühl der intensiven Hitze, die durch deine Ausrüstung dringt, das Geräusch der lodernden Flammen und die Beklemmung absolut nichts durch diesen dichten Rauch zu sehen, nachempfinden - "Sensationen, an die ich mich zu sehr gewöhnt habe, mit denen ich zu sehr vertraut geworden bin."</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>verstehen, wie es ist, am Morgen zur Schule oder zur Arbeit zu gehen, nachdem du den Großteil der Nacht, heiß und wieder nass durchgeschwitzt, bei einem Großfeuer verbracht hast.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>meine Gedanken lesen, wenn ich zu einem entstehenden Feuer gerufen werde, "Ist es ein falscher Alarm oder ein fortgeschrittenes, atmendes Feuer? Wie ist das Gebäude konstruiert? Welche Gefahren erwarten mich? Sind Menschen eingeschlossen?"</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>in der Notaufnahme dabei sein, wenn der Arzt das hübsche 5 Jahre alte Mädchen für tot erklärt, nachdem ich es zuvor 25 Minuten lang versucht habe am Leben zu halten; sie wird nie zu ihrem ersten Date gehen können oder jemals wieder die Worte "Ich liebe dich, Mama" sagen können.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>die Frustration im Führerhaus des Löschfahrzeuges fühlen. Der Maschinist drückt seinen Fuß fest auf die Bremse, mein Daumen drückt wieder und wieder den Schalter des Presslufthorns, wenn du vergeblich versuchst, dir Vorfahrt an einer vorfahrtberechtigten Kreuzung zu verschaffen oder im dichten Verkehrsstau. Wenndu uns brauchst, wann auch immer es ist, deine ersten Worte nach unserem Eintreffen werden sein: "Es hat fast eine Ewigkeit gedauert bis ihr hier wart!"</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>meine Gedanken lesen, wenn ich helfe, eine junge Frau aus den zertrümmertenResten ihres Wagens zu ziehen, "Was wäre, wenn es meine Schwester, meine Freundin oder eine Bekannte ist? Wie werden ihre Eltern reagieren, wenn vor ihrer Tür ein Polizist steht, der seine Mütze in den Händen hält?"</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>wissen, wies es sich anfühlt nach Hause zu kommen, meine Eltern und Familie zubegrüßen, aber nicht das Herz zu haben ihnen zu erzählen, dass ich beinahe von meinem letzten Einsatz nicht zurückgekommen wäre.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>die physische, emotionale und mentale Belastung von stehen gelassenem Essen,verlorenem Schlaf und verpasster Freizeit vorstellen, zusammen mit all den Tragödien, die meine Augen gesehen haben.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>die Kameradschaft und die Befriedigung, Leben gerettet oder jemandes Eigentumgeschützt zu haben, erfahren, da zu sein zur richtigen Zeit am richtigen Ort, in der Gefahr oder aus der Hektik und dem Chaos heraus Ordnung zu schaffen.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p>verstehen, wie es ist, einen kleinen Jungen auf deinem Arm zu tragen, der fragt, "Ist meine Mama O.K.?", und es ist dir unmöglich, ihm in die Augen zu schauen, ohne dass dir die Tränen in die Augen steigen und weißt nicht, was du sagen sollst. Oder wie es ist, einen alten Freund zurückzuhalten, der mit ansehen muss, wie seinbester Kumpel in den Rettungswagen getragen wird, und du weißt genau, dass er nicht angeschnallt war.</p>
        <p style={{marginTop:'1%', color: 'red', fontWeight:'bold'}}>Ich wünschte, du könntest</p>
        <p></p>
        <p style={{marginTop:'1%', color: 'red',fontSize:"120%", fontWeight:'bold'}}>Solange du dieses Leben nicht durchgemacht hast, wirst du niemals wirklich verstehen oder einschätzen können, wer ich bin, was wir sind oder was unsere Arbeit wirklich bedeutet.</p>
        <p></p>

        
        
      
        </div></div> 
         <br></br>
         <br></br>
         <br></br>

    </>
  );
};

export default GedankenFeuerwehrmann;
