import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import React, { useEffect, useState, useCallback} from "react";
import debounce from 'debounce';

import { Badge, Button, Card, CardGroup, Carousel, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faClockFour,
  faCalendarDay,
  faClipboardList,
  faFireExtinguisher,
  faTruckMedical,
  faFireAlt,
  faPersonChalkboard,
  faFireBurner,
  faHouseFire,
  faDumpsterFire,
  faHouseLock,
  faHouseFloodWater,
  faExplosion,
  faHeartCircleBolt,
  faSprayCanSparkles,
  faCarBurst,
  faSuitcaseMedical,
  faPersonSwimming,
  faOilCan,
  faRoadCircleExclamation,
  faTree,
  faTruckArrowRight,
  faScrewdriverWrench,
  faWrench,
  faFire,
  faCircleRadiation,
  faRadiation,
  faTowerCell,
  faAngleDown,
  faAngleUp,
  faCircleInfo,
  faPeopleRoof,
  faHandsHoldingChild,
  faNewspaper,
  faCalendarCheck,
  faIcons,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CalendarCarousel() {
  const url= process.env.REACT_APP_PUBLIC_BACKEND;
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const [calendar, setCalendar]=useState([]);
	const navigate=useNavigate();
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(3);
    const [date, setDate] = useState(new Date());
    const [selectedAppointments, setSelectedAppointments] = useState([]);
    const MemoizedCalendar = React.memo(Calendar);
    const [calendarNow, setCalendarNow]=useState([]);

    const tileClassName = useCallback(({ date, view }) => {
        if (view === 'month') {
          const appointment = calendar.find(appt => {
            if (appt.date && appt.enddate) {
              return date >= appt.date && date <= appt.enddate;
            } else {
              return appt.date.toDateString() === date.toDateString();
            }
          });
      
          if (appointment) {
            return appointment.category!==("Info"||"Sonstige")?("cal"+appointment.category):("calInfo")+`appointment-tile`;
          }
        }
      }, [calendar]);
      
      const tileContent = useCallback(({ date, view }) => {
        if (view === 'month') {
          const appointmentsOnDate = calendar.filter(appt => {
            if (appt.date && appt.enddate) {
              return date >= appt.date && date <= appt.enddate;
            } else {
              return appt.date.toDateString() === date.toDateString();
            }
          });
      
          if (appointmentsOnDate.length > 0) {
           console.log("cat ", appointmentsOnDate);
           return (
            <div  onClick={() => {navigate("/calendarPage/calendarDetails/"+appointmentsOnDate[0].id); setSelectedAppointments(appointmentsOnDate);}}>
             <OverlayTrigger onClick={() => {navigate("/kalender/details/"+appointmentsOnDate[0].id); setSelectedAppointments(appointmentsOnDate);}}
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">{appointmentsOnDate[0].title}</Tooltip>}>
        <Badge pill bg="trasparence" text="dark" className="headpill">
        {getCatIcon(appointmentsOnDate[0].category)}
        </Badge>
        </OverlayTrigger>
            </div>
          );
          }
          else {
            return (
              <div>
                <FontAwesomeIcon icon={faCalendar} style={{color: "transparent"}} className="appointment-icon" onClick={() => setSelectedAppointments(appointmentsOnDate)} />
              </div>
            );
          }
        }
      }, [calendar]);
  
    function onChange(newDate) {
      setDate(newDate);
    }
  

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 3;
    } else if ( width >= 576 && width < 768 ) {
      return 3;
    } else if ( width >= 768 && width < 992 ) {
      return 4;
    } else if ( width >= 992 && width < 1200 ) {
      return 5;
    } else if ( width >= 1200 && width < 1440 ) {
      return 6;
    } else if ( width >= 1440 ) {
      return 7;
    }
  }

  useEffect(()=> {  
    findAllCalendar();  
    findAllCalendarNow();
  },[]);
  
  function findAllCalendarNow() {
    axios
      .get(
        url+"/calendar/public?pageNumber=" +
          0 +
          "&pageSize=" +
          10 +
          "&sortBy=date&sortDir=" +
          "asc"
      )
      .then((response) => response.data)
      .then((data) => {
        //console.log(data);
        setCalendarNow(modifyCalendarData(data.content));
       
       
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function findAllCalendar (){
    axios
      .get(
        url+"/calendar/public/search/" +
        "20" +
          "?page=" +
          0 +
          "&size=" +
          100
      )
      .then((response) => response.data)
      .then((data) => {
        
          setCalendar(modifyCalendarData(data.content));    
      });
  }

  function modifyCalendarData(data){
    var arr = [];
    data.forEach((cal) => {
      let startDateComponents = cal.date.split("-");
      let endDateComponents = cal.enddate.split("-");
  
      // Erstellen des Start- und Enddatums als JavaScript-Date-Objekte
      // Startdatum: Anfang des Tages (00:00:00)
      let startDate = new Date(
        parseInt(startDateComponents[0], 10),
        parseInt(startDateComponents[1], 10) - 1, // Monate sind 0-basiert in JavaScript
        parseInt(startDateComponents[2], 10)
      );
  
      // Enddatum: Ende des Tages (23:59:59)
      let endDate = new Date(
        parseInt(endDateComponents[0], 10),
        parseInt(endDateComponents[1], 10) - 1, // Monate sind 0-basiert in JavaScript
        parseInt(endDateComponents[2], 10),
        23, 59, 59
      );
  
      cal.date = startDate;
      cal.enddate = endDate;
  
      arr.push(cal);
    });
  
    return arr;
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);
    const [index, setIndex] = useState(0);
  
        const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    

      const handlePrevClick = () => {
        if (startIndex > 0) {
          setStartIndex(startIndex - 1);
          setEndIndex(endIndex - 1);
        }
      };
    
      const handleNextClick = () => {
        if (endIndex < calendarNow.length) {
          setStartIndex(startIndex + 1);
          setEndIndex(endIndex + 1);
        }
      };
    function linkInst(element){
      if(element==="Kubschütz"){
        return("https://www.feuerwehr-kubschuetz.de");
      }
      else if(element==="BF Bautzen"){
        return("https://www.feuerwehr-bautzen.de/");
      }
      else if(element==="FFW Hochkirch"){
        return("https://www.hochkirch.de/einrichtungen/feuerwehr");
      }
      return("https://www.feuerwehr-kubschuetz.de");
    }

    function createPill(string){
      const arr=string.split(",");
       return( 
        arr.map((element,i)=>(        
          <a  href={linkInst(element)} target="_blank" rel="noreferrer" className="cardtext">
        <Badge key={i} className="pillinst bottompill" pill bg="light" text="dark">
        {element}     
      </Badge>        
      </a>
        ))
       )
    }
 function getCatIcon (cat){
if(cat==="Jahreshauptversammlung"){
  return(<FontAwesomeIcon icon={faPeopleRoof} />)
}
else if(cat==="Dienst"){
  return(<FontAwesomeIcon icon={faPersonChalkboard} />)
}
else if(cat==="Veranstaltung"){
  return(<FontAwesomeIcon icon={faIcons} />)
}
else if(cat==="Jugendfeuerwehr"){
    return(<FontAwesomeIcon icon={faHandsHoldingChild} />)
  }
else if(cat==="Ereignis"){
  return(<FontAwesomeIcon icon={faCalendarCheck} />)
}
return(<FontAwesomeIcon icon={faCircleInfo} />)
 }


    const cardSlides =
    calendarNow.slice(startIndex, endIndex).map((card, i) => {
      const cardStyle = {
        backgroundImage: `linear-gradient(to top, rgba(230, 230, 230, 0.9), rgba(230, 230, 230, 0.8)), url(${url+"/calendar/image/download/"+card.id+"/"+card.imageUrl.split(",")[0]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative'
    };
        return ( 
      <div key={i} onClick={() => navigate("/kalender/details/"+card.id)}  className={(card.category!==("Info"||"Sonstige")?("cal"+card.category):("calInfo"))+" containerCalItem"}  style={card.imageUrl?(cardStyle):null} >
  <div className="card-overlay"></div>
  <div className="iconCal"> <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">{card.category}</Tooltip>}>
        <Badge pill bg="trasparence" text="dark" className="headpill">
        {getCatIcon(card.category)}
        </Badge>
        </OverlayTrigger></div>
  <div className="titleCal">{card.title}&emsp;&emsp;</div>
  <div className="textcalBegin">{card.report.substring(0,100)+"..."}</div>
  <div className="DateCal">{card.date.toLocaleDateString()===card.enddate.toLocaleDateString()?(card.location+", "+card.date.toLocaleDateString()+" "+card.time+" Uhr"):(card.location+", "+card.date.toLocaleDateString()+" "+card.time+" Uhr bis "+card.enddate.toLocaleDateString()+" "+card.endtime+" Uhr")}</div>
  <div className="InstiCal">{createPill(card.institutions)}</div>
  
</div>
    );
        })


  
    return (
      <div style={{padding:'1%'}}>
       <div class="containerCalPart">
  <div class="calendarPart">
        <MemoizedCalendar
        value={date}
        onChange={onChange}
        tileClassName={tileClassName}
        tileContent={tileContent} 
      />
        </div>
        <div class="CalendarItemsPart">
       <Container
      fluid
      className="reportslide"
      
    >
        {calendarNow?(cardSlides):null}
    <div className="d-flex justify-content-between align-items-center">
    <Button  className="controlTop" variant="transparent" onClick={handlePrevClick} disabled={startIndex === 0}><FontAwesomeIcon icon={faAngleUp} style={{color: "grey"}}/></Button>
      <Button className="controlBottom" variant="transparent" onClick={handleNextClick} disabled={endIndex === calendarNow.length}><FontAwesomeIcon icon={faAngleDown} style={{color: "grey"}}/></Button>
      </div>
      </Container>
      </div>
      </div>
      </div>
    
    );
  }