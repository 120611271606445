import { Card, Row, Form, Button, Col, FormControl, InputGroup, Image, Container, Dropdown, Accordion, AccordionCollapse, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwt_decode from 'jwt-decode';
import {
  faSave,
  faPlusSquare,
  faUndo,
  faList,
  faEdit,
  faCalendarAlt,
  faWarning,
  faCheckCircle,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import MyToast from "../MyToast";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import { icon } from 'leaflet';
import { debounce } from "lodash";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";

const Mission = (props) => {

  const url = process.env.REACT_APP_PUBLIC_BACKEND;
  const { id } = useParams();
  const navigate = useNavigate();
  const [mission, setMission] = useState();
  const [category, setCategory] = useState([]);
  const [emergency, setEmergency] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [show, setShow] = useState(null);
  const [methode, setMethode] = useState("success");
  const [change, setChange] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(props.check ? props.check : false);
  const [selectedTime, setSelectedTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
  const customIcon = icon({
    iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  const [position, setPosition] = useState([51.164981, 14.5066482]);
  const [ImagePath, setImagePath] = useState([]);
  let initialState = {
    id: "",
    title: "",
    protocoll: new Date().toISOString().slice(0, 4) + "-",
    imageUrl: "",
    category: "",
    emergency: "",
    location: "",
    report: "",
    map: "51.164981,14.5066482",
    institutions: "",
    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    date: new Date().toISOString().slice(0, 10),
    enable: false,
    links: "",
    person: "",
    author: jwt_decode(axios.defaults.headers.Authorization).sub
  };
  const [missionTemp, setMissionTemp] = useState(initialState);
  const [files, setFiles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [linkFields, setLinkFields] = useState([]);
  const [personFields, setPersonFields] = useState([]);
  const [roles, setRoles] = useState(["frei", "GF", "AGT-Führer", "AGT-Mann"]);
  const [vehicle, setVehicle] = useState(["Reserve", "HLF10/6", "TLF16", "MTW-Kub", "TSF-W/z", "MTW-Rach", "LF8-TS", "KLF", "HLF10-KBZ"]);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  function resolveBreakpoint(width) {
    if (width < 576) {
      return 1;
    } else if (width >= 576 && width < 768) {
      return 2;
    } else if (width >= 768 && width < 992) {
      return 2;
    } else if (width >= 992 && width < 1200) {
      return 3;
    } else if (width >= 1200 && width < 1440) {
      return 4;
    } else if (width >= 1440) {
      return 5;
    }
  }
  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  const handleAddLinkFieldTemp = (val1, val2) => {
    if (linkFields.length < 6) {
      setLinkFields([...linkFields, { value1: val1, value2: val2 }]);
    }
  };
  const handleChangeLinkFieldsTemp = (index, inputIndex, e) => {
    const newFields = [...linkFields];
    newFields[index][`value${inputIndex}`] = e.target.value;
    setLinkFields(newFields);
  };
  function handleGenerateString() {
    const filledFields = linkFields.filter((field) => field.value1 && field.value2);
    const fieldString = filledFields.map((field) => `${field.value1}|${field.value2}`).join(',');
    return fieldString;
  };
  const handleGenerateFields = (string) => {
    if (string.length > 0) {
      const newFields = string.split(',').map((fieldString) => {
        const [value1, value2] = fieldString.split('|');
        return { value1, value2 };
      });
      setLinkFields(newFields);
    } else { setLinkFields([]); }
  };
  const handleAddLinkFieldTempPersonPerson = (index) => {
    if (personFields[index].persons.length < 15) {
      const newFields = [...personFields];
      newFields[index][`${'persons'}`] = [...personFields[index].persons, { person: '', role: 'frei', starttime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), startpressure: '', endtime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), endpressure: '' }];
      setPersonFields(newFields);
    }
  };

  const handleAddLinkFieldTempPerson = (val1, val2) => {
    if (personFields.length < 6) {
      setPersonFields([...personFields, { vehicle: val1, persons: [{ person: '', role: 'frei', starttime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), startpressure: '', endtime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), endpressure: '' }] }]);
    }
  };
  const handleChangeLinkFieldsTempPerson = (index, inputIndex, e) => {
    const newFields = [...personFields];
    if (newFields[index][`${inputIndex}`] !== e) {
      newFields[index][`${inputIndex}`] = e;
      setPersonFields(newFields);
    }
  };
  const handleChangeLinkFieldsTempPersonPerson = (index, indexperson, inputIndex, e) => {
    const newFields = [...personFields];
    if (newFields[index].persons[indexperson][`${inputIndex}`] !== e) {
      const newFields = [...personFields];
      newFields[index].persons[indexperson][`${inputIndex}`] = e;
      setPersonFields(newFields);
    }
  };
  const handleChangeLinkFieldsTempPersonPersonP = (index, indexperson, inputIndex, e) => {
    const newFields = [...personFields];
    if (newFields[index].persons[indexperson][`${inputIndex}`] !== e.target.value) {
      const newFields = [...personFields];
      newFields[index].persons[indexperson][`${inputIndex}`] = e.target.value;
      setPersonFields(newFields);
    }
  };
  function handleGenerateStringPerson() {
    const filteredArray2 = personFields.map(item => {
      if (item.vehicle === null) {
        return null;
      }

      const personsWithNames = item.persons.filter(person => person.person !== "");
      return personsWithNames.length === item.persons.length ? item : { ...item, persons: personsWithNames };
    }).filter(item => item && item.persons.length > 0);

    const array2String = JSON.stringify(filteredArray2);

    return array2String;
  };
  const handleGenerateFieldsPerson = (string) => {
    if (string.length > 0) {
      setPersonFields(JSON.parse(string));
    } else { setPersonFields([]); }
  };

  const handleSelect = (e) => {
    if (!selectedOptions.includes(e)) {
      if (selectedOptions.length > 0) setMissionTemp({ ...missionTemp, institutions: selectedOptions + "," + e });
      else { setMissionTemp({ ...missionTemp, institutions: e }); }
      setSelectedOptions([...selectedOptions, e]);
      setChange(true);
    }
  }
  const handleDeselect = (e) => {
    const arg = selectedOptions.filter(option => option !== e);
    setSelectedOptions(selectedOptions.filter(option => option !== e));
    setMissionTemp({ ...missionTemp, institutions: arg.join(",") });
    setChange(true);
  }

  const handleSelectCat = (e) => {
    if (!missionTemp.category !== e) {
      setMissionTemp({ ...missionTemp, category: e });
      setChange(true);
    }
  }
  const handleDeselectCat = () => {
    setMissionTemp({ ...missionTemp, category: "" });
    setChange(true);
  }

  const handleSelectE = (e) => {
    if (!missionTemp.category !== e) {
      setMissionTemp({ ...missionTemp, emergency: e });
      setChange(true);
    }
  }
  const handleDeselectE = () => {
    setMissionTemp({ ...missionTemp, emergency: "" });
    setChange(true);
  }
  useEffect(() => {
    console.log('tempmission', missionTemp);
    console.log('mission', mission);
    console.log('id', id);
    console.log('path', handleGenerateStringPerson());
  });
  useEffect(() => {
    if (id != null) {
      setTimeout(() => {
        axios.get(url + "/missions/" + id)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            if (data.imageUrl) {
              if (data.imageUrl.length > 0) setImagePath(data.imageUrl.split(","));
              setMission(data);
              setMissionTemp(data);
            }
            else { setMission({ ...data, imageUrl: "" }); setMissionTemp({ ...data, imageUrl: "" }); }
            if (data.institutions.length > 0) setSelectedOptions(data.institutions.split(","));
            if (data.imageUrl.length > 0) setImagePath(data.imageUrl.split(","));
            setPosition(data.map.split(','));
            if (data.links.length > 0) { handleGenerateFields(data.links); }
            if (data.person.length > 0) { handleGenerateFieldsPerson(data.person); }
            setSelectedDate(data.date);
            setSelectedTime(data.time);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 500);
    }
    setTimeout(() => {
      axios.get(url + "/missions/category")
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setCategory(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);
    setTimeout(() => {
      axios.get(url + "/missions/emergency")
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setEmergency(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);
    setTimeout(() => {
      axios.get(url + "/missions/institutions")
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setInstitutions(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);
  }, []);

  const ChangePositionOnClick = () => {
    useMapEvents({
      click: (e) => {
        setMissionTemp({ ...missionTemp, map: e.latlng.lat + "," + e.latlng.lng })
        setPosition(e.latlng);
        setChange(true);
      },
    });
    return null;
  };

  function DraggableMarker() {
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setMissionTemp({ ...missionTemp, map: marker.getLatLng().lat + "," + marker.getLatLng().lng })
            setPosition(marker.getLatLng());
            setChange(true);
          }
        },
      }),
      [],
    )
    return (
      <Marker
        icon={customIcon}
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
        <Popup minWidth={90}>
          Marker is draggable
        </Popup>
      </Marker>
    )
  }

  const filterVehicle = vehicle.filter(item2 => {
    return !personFields.some(item1 =>
      item1.vehicle === item2
    );
  });

  function handleCloseOld(index) {
    const updatedImages = [...ImagePath];
    updatedImages.splice(index, 1);
    setImagePath(updatedImages);
    setMissionTemp({ ...missionTemp, imageUrl: updatedImages.join(",") })
    setChange(true);
  };
  function handleCloseClick(index) {
    const updatedImages = [...files];
    updatedImages.splice(index, 1);
    setFiles(updatedImages);
    setChange(true);
  };
  function handleTimeChange(event) {
    setChange(true);
    setSelectedTime(event.target.value);
    setMissionTemp({ ...missionTemp, time: event.target.value })
  };
  function handleDateChange(event) {
    setChange(true);
    setSelectedDate(event.target.value);
    setMissionTemp({ ...missionTemp, date: event.target.value })
  };
  function onReset() {
    try {
      if (id) {
        setMissionTemp(mission);
        setChange(false);
        setFiles([]);
        handleGenerateFieldsPerson(mission.person);
        handleGenerateFields(mission.links);
        setSelectedOptions(mission.institutions.split(","));
        setImagePath(mission.imageUrl.split(","));
        setPosition(mission.map.split(','));
        setSelectedDate(mission.date);
        setSelectedTime(mission.time);
        setTimeout(() => {
          setShow(null);
        }, 3000);
      } else {
        setMissionTemp(initialState);
        handleGenerateFields("");
        handleGenerateFieldsPerson("");
        setChange(false);
        setFiles([]);
        setImagePath([]);
        setSelectedDate(new Date().toISOString().slice(0, 10));
        setSelectedTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        setSelectedOptions([]);
        setPosition([51.164981, 14.5066482]);
        setChange(false);
        setTimeout(() => {
          setShow(null);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function updateMission() {
    setLoading(true);
    var count1 = 0;
    if (mission.imageUrl.length > 0) {
      const urls = ImagePath.join(",");
      mission.imageUrl.split(",").map((img, index) => {
        count1++;
        if (urls.indexOf(img) === -1) {
          setTimeout(() => {
            axios.delete(url + "/missions/image/" + missionTemp.id + "/" + img)
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
                setMethode("danger");
                setShow("Mission picture updated failed");
              }, 1000 + count1 * 600);
          })
        }
      });
    }

    if (files) {
      var count2 = 0;
      files.forEach(async (img) => {
        const formData = new FormData();
        count2++;
        setTimeout(async () => {
          const compressedFile = await compressImage(img);
          formData.append("file", compressedFile);
          axios.post(url + "/missions/" + missionTemp.id + "/image/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
              console.log("upload successfully!")
            })
            .catch((error) => {
              setMethode("danger");
              setShow("Mission picture updated failed");
              console.log(error);
            });
        }, 1000 + count2 * 1000);
      });
    }
    const missionup = { ...missionTemp, links: handleGenerateString(), person: handleGenerateStringPerson() };
    setMission({ ...missionTemp, links: handleGenerateString(), person: handleGenerateStringPerson() });
    setMethode("put");
    setTimeout(() => {
      axios
        .put(url + "/missions", missionup)
        .then((response) => {
          setMethode("success");
          setShow("Mission updated successfully");
        })
        .catch((error) => {
          setMethode("danger");
          setShow("Mission update failed");
          console.log(error);
        });
    }, 1000);
    setTimeout(() => {
      setLoading(false);
      setShow(null);
      navigate("/mission-list");
    }, 3000 + count2 * 500);
  }
  async function submitMission() {
    setLoading(true);
    const mission = { ...missionTemp, id: generateUuid(), links: handleGenerateString(), person: handleGenerateStringPerson() };
    setMissionTemp({ ...missionTemp, id: generateUuid(), links: handleGenerateString(), person: handleGenerateStringPerson() });
    setMission({ ...missionTemp, id: generateUuid(), links: handleGenerateString(), person: handleGenerateStringPerson() });
    axios
      .post(url + "/missions", mission)
      .then((response) => {
        setShow(true);
      })
      .catch((error) => {
        setShow(null);
        console.log(error);
      });

    if (files) {
      var count = 0;
      files.forEach(async (img) => {
        const formData = new FormData();
        count++;
        setTimeout(async () => {
          const compressedFile = await compressImage(img);
          formData.append("file", compressedFile);
          axios.post(url + "/missions/" + mission.id + "/image/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
              console.log("upload Image " + count + " successfully!");
              setShow("Mission submitted successfully");
              setMethode("success");
            })
            .catch((error) => {
              console.log(error);
            });
        }, 1000 + count * 1000);
      });

      setTimeout(() => {
        setLoading(false);
        setShow(null);
        onReset();
      }, 3000 + count * 500);
    }
  }
  function generateUuid() {
    var uid = missionTemp.date + missionTemp.time + Math.floor(Math.random() * 100);
    uid = uid.toString();
    uid = uid.replace("-", "");
    uid = uid.replace(":", "");
    uid = uid.replace("-", "");
    return uid;
  }

  async function compressImage(image) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    try {
      const compressedFile = await imageCompression(image, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  function Dropzone() {
    const onDrop = useCallback(async acceptedFiles => {
      const compressedFiles = await Promise.all(
        acceptedFiles.map(file => compressImage(file))
      );
      setFiles(files.concat(compressedFiles));
      setChange(true);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
    )
  }

  return (<>
    {size > 2 ? (<><br></br><br></br><br></br><br></br></>) : null}
    <Container fluid>
      <div style={{ display: show ? "block" : "none" }}>
        <MyToast
          show={show ? true : false}
          message={
            show
          }
          type={methode}
          onClose={() => setShow(null)} dismissible
        />
      </div>
      <Card className={"border border-dark bg-dark text-white"} style={{ margin: '1%' }}>
        <Card.Header>
          <FontAwesomeIcon icon={id ? faEdit : faPlusSquare} />{" "}
          {id ? "Update Einsatz" : "Neuen Einsatz hinzufügen"}&nbsp;&nbsp;&nbsp;&nbsp;{axios.defaults.headers.Authorization ? (jwt_decode(axios.defaults.headers.Authorization).sub === "ADMiN" || jwt_decode(axios.defaults.headers.Authorization).sub === missionTemp.author ? ("") : (<><FontAwesomeIcon icon={faWarning} style={{ color: 'orange' }} /><a style={{ color: 'orange', fontWeight: 'bold' }}>&nbsp;Nur Leserechte</a> für Änderung an {missionTemp.author} oder den Admin wenden</>)) : null}
        </Card.Header>
        <Form style={{ backgroundColor: '#343a40', color: 'white' }}
          onReset={onReset}

          id="bookFormId"
        >
          <Card.Body>
            <Accordion alwaysOpen="false">
              <AccordionItem>
                <AccordionHeader>
                  Einsatzfakten
                </AccordionHeader>
                <AccordionCollapse >
                  <AccordionBody>
                    <Row>
                      <Form.Group as={Col} controlId="formGridTitle">
                        <Form.Label>Titel</Form.Label>
                        <Form.Control
                          required
                          autoComplete="off"
                          type="test"
                          name="title"
                          value={missionTemp.title}
                          onChange={(e) => {
                            setChange(true);
                            setMissionTemp({ ...missionTemp, title: e.target.value })
                          }}
                          className={check ? (missionTemp.title.length < 2 || missionTemp.title.length > 250 ? "border-danger bg-dark text-white" : "bg-dark text-white") : "bg-dark text-white"}
                          placeholder="Hier Titel eingeben"
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridAuthor">
                        <Form.Label>Ort</Form.Label>
                        <Form.Control
                          required
                          autoComplete="off"
                          type="test"
                          name="location"
                          value={missionTemp.location}
                          onChange={(e) => {
                            setChange(true);
                            setMissionTemp({ ...missionTemp, location: e.target.value })
                          }}
                          className={check ? (missionTemp.location.length < 2 || missionTemp.location.length > 250 ? "border-danger bg-dark text-white" : "bg-dark text-white") : "bg-dark text-white"}
                          placeholder="Hier Ort eingeben"
                        />
                      </Form.Group>

                    </Row>
                    <Row style={{ marginTop: '1%' }}>
                      <Form.Group as={Col} controlId="formCalendar">
                        <Form.Label> Datum  </Form.Label>

                        <InputGroup>
                          <FormControl type="date" value={selectedDate} className={check ? (missionTemp.date.length < 2 ? "border-danger bg-secondary text-white" : "bg-secondary text-white") : "bg-secondary text-white"}
                            placeholder="dd.mm.yyyy" onChange={handleDateChange} />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formTime">
                        <Form.Label >   Uhrzeit  </Form.Label>
                        <InputGroup>
                          <FormControl className={check ? (missionTemp.time.length < 2 ? "border-danger bg-secondary text-white" : "bg-secondary text-white") : "bg-secondary text-white"} type="time" value={selectedTime} onChange={handleTimeChange} />
                        </InputGroup>

                      </Form.Group>
                    </Row>
                    <br></br>
                    <Row>
                      <Form as={Col}>
                        <Form.Label>Beteiligte Wehren</Form.Label>
                        <Dropdown onSelect={handleSelect}>
                          <Dropdown.Toggle variant="secondary" className={check ? (missionTemp.institutions.length < 2 ? "border-danger" : "") : ""} id="dropdown-basic">
                            {selectedOptions !== null ? "Option auswählen" : selectedOptions.join(', ')}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {institutions.map(option => (
                              <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Form.Text>
                          {selectedOptions.map(option => (
                            <div key={option} onClick={() => handleDeselect(option)} style={{ texborderColor: 'red', borderRadius: "5px", padding: '1px', margin: '2px', border: 'solid white 1px', cursor: 'pointer' }}>
                              &emsp;{option} &times;
                            </div>
                          ))}
                        </Form.Text>
                      </Form>
                      <Form as={Col}>
                        <Form.Label>Einsatzkategorie</Form.Label>
                        <Dropdown onSelect={handleSelectCat}>
                          <Dropdown.Toggle variant="secondary" className={check ? (missionTemp.category.length < 2 ? "border-danger" : "") : ""} id="dropdown-basic">
                            {missionTemp.category !== null ? "Option auswählen" : selectedOptions.join(', ')}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {category.map(option => (
                              <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Form.Text>
                          {missionTemp.category ?
                            <div key={1} onClick={() => handleDeselectCat()} style={{ borderColor: 'red', borderRadius: "5px", padding: '1px', margin: '2px', border: 'solid white 1px', cursor: 'pointer' }}>
                              &emsp;{missionTemp.category} &times;
                            </div>
                            : null}
                        </Form.Text>
                      </Form>
                      <Form as={Col}>
                        <Form.Label>Einsatzart</Form.Label>
                        <Dropdown onSelect={handleSelectE}>
                          <Dropdown.Toggle variant="secondary" className={check ? (missionTemp.emergency.length < 2 ? "border-danger" : "") : ""} id="dropdown-basic">
                            {missionTemp.emergency !== null ? "Option auswählen" : selectedOptions.join(', ')}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {emergency.map(option => (
                              <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Form.Text>
                          {missionTemp.emergency ?
                            <div key={1} onClick={() => handleDeselectE()} style={{ borderColor: 'red', borderRadius: "5px", padding: '1px', margin: '2px', border: 'solid white 1px', cursor: 'pointer' }}>
                              &emsp;{missionTemp.emergency} &times;
                            </div>
                            : null}
                        </Form.Text>
                      </Form>
                    </Row>
                  </AccordionBody>
                </AccordionCollapse>
              </AccordionItem>
            </Accordion>

            <br></br>
            <Accordion alwaysOpen="false">
              <AccordionItem>
                <AccordionHeader>
                  Bericht
                </AccordionHeader>
                <AccordionCollapse >
                  <AccordionBody>
                    <Row>
                      <Form.Group as={Col} controlId="formGridPrice">
                        <Form.Label>Bericht <a style={{ fontWeight: 'lighter', fontSize: 'small' }}>({missionTemp.report.length}/2000)</a></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={8}
                          required
                          autoComplete="off"
                          type="test"
                          name="report"
                          value={missionTemp.report}
                          onChange={(e) => {
                            setChange(true);
                            setMissionTemp({ ...missionTemp, report: e.target.value })
                          }}
                          className={check ? (missionTemp.report.length < 5 || missionTemp.report.length > 2499 ? "border-danger bg-dark text-white" : "bg-dark text-white") : "bg-dark text-white"}
                          placeholder="Beschreibe den Einsatzverlauf..."
                        />
                      </Form.Group>
                    </Row>
                  </AccordionBody>
                </AccordionCollapse>
              </AccordionItem>
            </Accordion>
            <br></br>
            <Accordion alwaysOpen="false">
              <AccordionItem>
                <AccordionHeader>
                  Bilder
                </AccordionHeader>
                <AccordionCollapse >
                  <AccordionBody>
                    <Row style={{ margin: '2px', fontWeight: 'lighter' }}>
                      <p><FontAwesomeIcon icon={faWarning} />&emsp;Das erste Bild, welches hinzugefügt wird, ist das Titel Bild - bitte nur querformat!</p>
                    </Row>

                    <Row style={{ border: 'solid grey 1px', background: 'black', borderRadius: "6px", margin: '2px', marginTop: '8px' }}>
                      <Dropzone />

                    </Row>
                    <div style={{ overflowX: "auto", marginTop: '20px' }}>
                      <Row className="flex-nowrap">
                        {
                          files.map((img, index) => (
                            <Col key={index} className="mb-3" style={{ width: "100%" }}>
                              <div style={{ width: "200px" }}>
                                <Button
                                  variant="danger"
                                  className="p-0"
                                  style={{ position: "absolute", right: "1", top: "0" }}
                                  onClick={() => handleCloseClick(index)}
                                >
                                  &emsp;&times;&emsp;
                                </Button>
                                <Image
                                  className="imagestyle"
                                  src={URL.createObjectURL(img)}
                                />
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </div>
                    <div style={{ overflowX: "auto", marginTop: '20px' }}>
                      <Row className="flex-nowrap">
                        {
                          ImagePath.map((img, index) => (
                            <Col key={index} className="mb-3" style={{ width: "100%" }}>
                              <div style={{ width: "200px" }}>
                                <Button
                                  variant="danger"
                                  className="p-0"
                                  style={{ position: "absolute", right: "1", top: "0" }}
                                  onClick={() => handleCloseOld(index)}
                                >
                                  &emsp;&times;&emsp;
                                </Button>
                                <Image className="imagestyle"
                                  src={url + "/missions/image/download/" + missionTemp.id + "/" + img}
                                />
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  </AccordionBody>
                </AccordionCollapse>
              </AccordionItem>
            </Accordion>

            <br></br>
            <Accordion alwaysOpen="false">
              <AccordionItem>
                <AccordionHeader>
                  Karte, Links, Protokollnummer
                </AccordionHeader>
                <AccordionCollapse >
                  <AccordionBody>
                    <Row>
                      <Form.Group as={Col} controlId="formGridPrice">
                        <Form.Label>Position auf Karte</Form.Label>
                        <Form.Control
                          required
                          autoComplete="off"
                          type="test"
                          name="report"
                          value={position}
                          disabled
                          className={"bg-dark text-white"}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridISBNNumber">
                        <Form.Label>Protokollnummer</Form.Label>
                        <Form.Control
                          required
                          autoComplete="off"
                          type="test"
                          name="protocollNumber"
                          value={missionTemp.protocoll}
                          onChange={(e) => {
                            setChange(true);
                            setMissionTemp({ ...missionTemp, protocoll: e.target.value })
                          }}
                          className={check ? (missionTemp.protocoll.length < 6 ? "border-danger bg-dark text-white" : "bg-dark text-white") : "bg-dark text-white"}
                          placeholder='Hier Protokollnummer eingeben Bsp.: "2023-1"'
                        />
                      </Form.Group>
                    </Row>
                    <br></br>
                    Links
                    <Button
                      variant="transparent"
                      className=""
                      onClick={() => (handleAddLinkFieldTemp('', ''))}
                    >
                      <FontAwesomeIcon icon={faPlusSquare} style={{ color: "white" }} />
                    </Button>
                    <br></br>
                    {linkFields.length > 0 ? (
                      linkFields.map((field, index) => (
                        <Row>
                          <Form.Group as={Col} controlId={index}>
                            <Form.Label>Anzeigetext</Form.Label>
                            <Form.Control
                              required
                              autoComplete="off"
                              type="test"
                              name="title"
                              value={field.value1}
                              onChange={(e) => {
                                setChange(true);
                                handleChangeLinkFieldsTemp(index, 1, e);
                              }}
                              className={"bg-dark text-white"}
                              placeholder="Hier Titel für Link hinzufügen"
                            />
                          </Form.Group>
                          <Form.Group as={Col} controlId={index}>
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                              required
                              autoComplete="off"
                              type="test"
                              name="title"
                              value={field.value2}
                              onChange={(e) => {
                                setChange(true);
                                handleChangeLinkFieldsTemp(index, 2, e);
                              }}
                              className={"bg-dark text-white"}
                              placeholder="Hier Link hinzufügen z.B: https://www.google.de"
                            />
                          </Form.Group>
                        </Row>
                      ))) : null}
                    <br></br>
                    <Row>
                      <MapContainer onClick={((e) => (""))} center={position} zoom={12.5} scrollWheelZoom={false} style={{ width: '100%', height: '400px', border: 'solid grey 2px', background: 'black', borderRadius: "8px", padding: '1%' }}>
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <ChangePositionOnClick />
                        <DraggableMarker />
                      </MapContainer>
                    </Row>
                  </AccordionBody>
                </AccordionCollapse>
              </AccordionItem>
            </Accordion>
            <br></br>
            <Accordion alwaysOpen="false">
              <AccordionItem>
                <AccordionHeader>
                  Einsatzkräfte
                </AccordionHeader>
                <AccordionCollapse >
                  <AccordionBody>
                    <br></br>


                    Fahrzeug
                    <Button
                      variant="transparent"
                      className=""
                      onClick={() => (handleAddLinkFieldTempPerson('', ''))}
                    >
                      <FontAwesomeIcon icon={faPlusSquare} style={{ color: "white" }} />
                    </Button>

                    <br></br>
                    {personFields.length > 0 ? (
                      personFields.map((field, index) => (
                        <Row style={{ marginTop: '0.5%', border: '1px solid black', padding: '0.1%', paddingBottom: "0.8%", borderRadius: '4px' }}>
                          <Form.Group as={Col} controlId={index}>
                            <Form.Label></Form.Label>
                            <Dropdown onSelect={(e) => {
                              setChange(true);
                              handleChangeLinkFieldsTempPerson(index, "vehicle", e);
                            }}>
                              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                {personFields[index].vehicle.length < 2 ? "Option auswählen" : personFields[index].vehicle}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {filterVehicle.map(option => (
                                  <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                                ))}
                              </Dropdown.Menu></Dropdown>
                          </Form.Group>
                          <Form.Group as={Col} controlId={index}>
                            <Form.Label>Personen<Button
                              variant="transparent"
                              className=""
                              onClick={() => (handleAddLinkFieldTempPersonPerson(index))}
                            >
                              <FontAwesomeIcon icon={faPlusSquare} style={{ color: "white" }} />
                            </Button></Form.Label>
                            {personFields[index].persons.length > 0 ? (
                              personFields[index].persons.map((field, indexPerson) => (

                                <Row style={{ marginTop: '0.5%', border: '1px solid grey', padding: '0.1%', paddingBottom: "0.8%", borderRadius: '4px' }}>
                                  <Form.Group as={Col} controlId={indexPerson} >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                      required
                                      autoComplete="off"
                                      type="test"
                                      name="title"
                                      value={personFields[index].persons[indexPerson].person}
                                      onChange={(e) => {
                                        setChange(true);
                                        handleChangeLinkFieldsTempPersonPersonP(index, indexPerson, "person", e);
                                      }}
                                      className={"bg-dark text-white"}
                                      placeholder="Hier Name der"
                                    />
                                  </Form.Group>
                                  {personFields[index].vehicle !== 'Reserve' ? (
                                    <>
                                      <Form.Group as={Col} controlId={indexPerson} >
                                        <Form.Label>Rolle</Form.Label>
                                        <Dropdown onSelect={(e) => {
                                          setChange(true);
                                          handleChangeLinkFieldsTempPersonPerson(index, indexPerson, "role", e);
                                        }}>
                                          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                            {personFields[index].persons[indexPerson].role.length < 2 ? "Option auswählen" : personFields[index].persons[indexPerson].role}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {roles.filter(item2 => {
                                              return !personFields[index].persons.some(item1 =>
                                                item1.role === item2 && item1.role == "GF");
                                            }).map(option => (
                                              <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu></Dropdown>
                                      </Form.Group>

                                      {personFields[index].persons[indexPerson].role === 'AGT-Mann' || personFields[index].persons[indexPerson].role === 'AGT-Führer' ? (
                                        <>
                                          <Row>

                                            <Form.Group as={Col} controlId="formTime">
                                              <Form.Label >   Startuhrzeit  </Form.Label>
                                              <InputGroup>
                                                <FormControl type="time" value={personFields[index].persons[indexPerson].starttime} onChange={(e) => { handleChangeLinkFieldsTempPersonPersonP(index, indexPerson, "starttime", e); handleChangeLinkFieldsTempPersonPersonP(index, indexPerson, "endtime", e) }} />
                                              </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId={indexPerson}>
                                              <Form.Label>Anfangsdruck (in bar)</Form.Label>
                                              <Form.Control
                                                required
                                                autoComplete="off"
                                                type="test"
                                                name="title"
                                                value={personFields[index].persons[indexPerson].startpressure}
                                                onChange={(e) => {
                                                  setChange(true);
                                                  handleChangeLinkFieldsTempPersonPersonP(index, indexPerson, "startpressure", e);
                                                }}
                                                className={"bg-dark text-white"}
                                                placeholder="Druckzahl 0-300"
                                              />
                                            </Form.Group>


                                          </Row>
                                          <Row>

                                            <Form.Group as={Col} controlId="formTime">
                                              <Form.Label >   Enduhrzeit  </Form.Label>
                                              <InputGroup>
                                                <FormControl type="time" value={personFields[index].persons[indexPerson].endtime} onChange={(e) => (handleChangeLinkFieldsTempPersonPersonP(index, indexPerson, "endtime", e))} />
                                              </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId={indexPerson}>
                                              <Form.Label>Enddruck (in bar)</Form.Label>
                                              <Form.Control
                                                required
                                                autoComplete="off"
                                                type="test"
                                                name="title"
                                                value={personFields[index].persons[indexPerson].endpressure}
                                                onChange={(e) => {
                                                  setChange(true);
                                                  handleChangeLinkFieldsTempPersonPersonP(index, indexPerson, "endpressure", e);
                                                }}
                                                className={"bg-dark text-white"}
                                                placeholder="Druckzahl 0-300"
                                              />
                                            </Form.Group>


                                          </Row></>) : null}
                                    </>
                                  ) : null}
                                </Row>
                              ))) : null}</Form.Group>
                        </Row>
                      ))) : null}
                    <br></br>

                  </AccordionBody>
                </AccordionCollapse>
              </AccordionItem>
            </Accordion>



          </Card.Body>



          <Card.Footer style={{ textAlign: "right" }}>
            {axios.defaults.headers.Authorization ? (jwt_decode(axios.defaults.headers.Authorization).sub === "ADMiN" || jwt_decode(axios.defaults.headers.Authorization).sub === missionTemp.author ? (
              <Button onClick={id ? updateMission : submitMission} size="sm" variant="success" disabled={change === false || missionTemp.title.length > 250 || missionTemp.location.length > 250 || missionTemp.report.length > 2499}>
                <FontAwesomeIcon icon={faSave} />{" "}
                {id ? "Update" : "Save"}{loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>) : null}
              </Button>) : null) : null}{" "}
            <Button size="sm" variant="info" type="reset" disabled={change === false}>
              <FontAwesomeIcon icon={faUndo} /> Reset
            </Button>{" "}
            <Button size="sm" variant="warning" onClick={(e) => { check === false ? setCheck(true) : setCheck(false) }}>
              <FontAwesomeIcon icon={check ? faCheckDouble : faCheckCircle} /> Prüfen
            </Button>{" "}
            <Button
              size="sm"
              variant="info"
              type="button"
              onClick={() => navigate("/mission-list")}
            >
              <FontAwesomeIcon icon={faList} /> Übersicht Einsätze
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </Container>



    <br></br>
    <br></br>
  </>
  );
}
export default Mission;
