import { faArrowLeft, faArrowRight, faDownload, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import {Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

function PDFViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [currenPage, setCurrentPage] = useState(1);
    const [endIndex, setEndIndex] = useState(3);
    const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  function resolveBreakpoint( width){
    if ( width < 420 ) {
      return 0.45;
    } else if ( width >= 420 && width < 576 ) {
      return 0.6;
    } else if ( width >= 576 && width < 768 ) {
      return 0.6;
    } else if ( width >= 768 && width < 992 ) {
      return 0.65;
    } else if ( width >= 992 && width < 1200 ) {
      return 0.8;
    } else if ( width >= 1200 && width < 1440 ) {
      return 0.9;
    } else if ( width >= 1440 ) {
      return 1;
    }
  }

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);
    
  useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; });
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleClose = () => {
    
    if (props.onClose) {
      setCurrentPage(1);
      props.onClose();
    }
  };
  const handlePrev = () => {
    console.log("cs");
    if (currenPage>1) {
        console.log("zurück");
      setCurrentPage(currenPage-1);
    }
  };
  const handleNext = () => {
    if (currenPage<numPages) {
        console.log("vor");
        setCurrentPage(currenPage+1);
      }
  };
  function downloadPdf() {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', props.url + '/image/image/download/' + props.id + '/' + props.file, true);
      xhr.responseType = 'blob';
    
      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'myPdfFile.pdf');
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url); }}; xhr.send();
  } catch (error) {
      console.error('Error downloading PDF:', error);
  }
  }
  const toastCss = {
    position: "absolute",
    top: "0px",
    left:"0px",
    bottom: "-200px",
    right: "0px",
    zIndex: "8",
    backgroundColor: "rgba(121, 121, 121, 0.8)", overflowX: "auto"
  };
  //props.url+"/image/image/download/"+props.id+"/"+props.file
  return (
    <>
    {(props.show)?(
      <>
    <div style={props.show ? toastCss : null}>
    <div className='pdf-container'>
    <Document className="flex-nowrap" file={props.url+"/image/image/download/"+props.id+"/"+props.file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page scale={size} pageNumber={currenPage} /> 
  </Document>
  </div>
      <ButtonGroup  style={{ zIndex:"5",position: 'absolute', top: '1%',right:'1%'}}>
      <Button variant="outline-dark" size="xl" onClick={downloadPdf} >
       <FontAwesomeIcon icon={faDownload} />
      </Button>
      <Button variant="danger" size="xl" onClick={handleClose} >
       <FontAwesomeIcon icon={faSquareXmark} />
      </Button>
      </ButtonGroup>
      <Button variant="outline-light" size="xl" disabled={currenPage===1} onClick={handlePrev} style={{zIndex:"5",position: 'absolute', top: '40%',left:'1%'}}>
       <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <Button variant="outline-light" disabled={currenPage>numPages-1} size="xl" onClick={handleNext} style={{zIndex:"5",position: 'absolute', top: '40%',right:'1%'}}>
       <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </div>
  </>
    ):(null)}
    </>
  );
}

export default PDFViewer;