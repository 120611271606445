import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  InputGroup,
  FormControl,
  Badge,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  Carousel,
} from "react-bootstrap";
import { renderToString } from 'react-dom/server';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faClockFour,
  faCalendarDay,
  faClipboardList,
  faFireExtinguisher,
  faTruckMedical,
  faFireAlt,
  faPersonChalkboard,
  faFireBurner,
  faHouseFire,
  faDumpsterFire,
  faHouseLock,
  faHouseFloodWater,
  faExplosion,
  faHeartCircleBolt,
  faSprayCanSparkles,
  faCarBurst,
  faSuitcaseMedical,
  faPersonSwimming,
  faOilCan,
  faRoadCircleExclamation,
  faTree,
  faTruckArrowRight,
  faScrewdriverWrench,
  faWrench,
  faFire,
  faCircleRadiation,
  faRadiation,
  faTowerCell,
  faCat,
  faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import L from 'leaflet';
  import { useNavigate, useParams } from "react-router-dom";
  import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
  import { icon } from 'leaflet';
import { debounce } from "debounce";
import ImageView from "../ImageView";
  
  export default function MissionDetailsPage(){
  
      const url= process.env.REACT_APP_PUBLIC_BACKEND;
      const {id}=useParams();
      const navigate=useNavigate();
      const [mission, setMission]=useState();
      const [position, setPosition] = useState([ 51.164981, 14.5066482 ]);
      const [ImagePath, setImagePath]=useState([]);  
      const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
      const [show, setShow]=useState(null);
      const [image, setImg]=useState(null);
      const [links, setLinkFields] = useState([]);

      function resolveBreakpoint( width){
        if ( width < 576 ) {
          return 1;
        } else if ( width >= 576 && width < 768 ) {
          return 1;
        } else if ( width >= 768 && width < 992 ) {
          return 2;
        } else if ( width >= 992 && width < 1200 ) {
          return 2;
        } else if ( width >= 1200 && width < 1440 ) {
          return 3;
        } else if ( width >= 1440 ) {
          return 3;
        }
      }
      async function findexamplePic(missionCat) {
        try {
          const response = await axios.get(`${url}/image/search/${missionCat}?page=0&size=1`);
          const data = response.data;
          if (data.content && data.content.length > 0) {
            return `${url}/image/image/download/${data.content[0].id}/${data.content[0].urls.split(",")[0]}`;
          } else {
            return 'path_to_default_image'; // Default image path
          }
        } catch (error) {
          console.error('Error in findexamplePic:', error);
          return 'path_to_default_image'; // Default image path
        }
      }
    
      useEffect(() => {
        // ... other useEffect logic
    
        if (id != null) {
          axios.get(`${url}/missions/public/${id}?pageNumber=0&pageSize=1&sortBy=date&sortDir=asc`)
            .then((response) => response.data)
            .then(async (data) => {
              if (data.content && data.content.length > 0) {
                const missionData = data.content[0];
                setMission(missionData);
    
                // Use findexamplePic if no image URL is present
                const imageUrls = missionData.imageUrl.length > 0 
                  ? missionData.imageUrl.split(",")
                  : [await findexamplePic(missionData.category)];
    
                setImagePath(imageUrls);
                setPosition(missionData.map.split(','));
                handleGenerateFields(missionData.links);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, [id]); // Add id as a dependency
      useEffect(() => {
        const calcInnerWidth = debounce(function () {
          setSize(resolveBreakpoint(window.innerWidth));
        }, 200);
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
      }, []);

     
    const handleGenerateFields = (string) => {
      if(string.length>0){
      const newFields = string.split(',').map((fieldString) => {
        const [value1, value2] = fieldString.split('|');
        return { value1, value2 };
      });
      setLinkFields(newFields);}
      else{setLinkFields([]);}
    };
    function linkInst(element){
        if(element==="Kubschütz"){
          return("https://www.feuerwehr-kubschuetz.de");
        }
        else if(element==="BF Bautzen"){
          return("https://www.feuerwehr-bautzen.de/");
        }
        else if(element==="FFW Hochkirch"){
          return("https://www.hochkirch.de/einrichtungen/feuerwehr");
        }
        return("https://www.feuerwehr-kubschuetz.de");
      }
    
      function createPill(string){
        const arr=string.split(",");
         return( 
          arr.map((element,i)=>(        
            <a  href={linkInst(element)} target="_blank" rel="noreferrer" className="cardtext">
          <Badge key={i} className="pillinst bottompill" pill bg="light" text="dark">
          {element}     
        </Badge>        
        </a>
          ))
         )
      }
      function createLinkPill(){
        return( 
         links.map((element,i)=>(        
           <a  href={element.value2} target="_blank" rel="noreferrer" className="cardtext">
         <Badge key={i} className="pillinst bottompill" pill bg="light" text="dark">
         {element.value1}     
       </Badge>        
       </a>
         ))
        )
     }
      function getCatIcon (cat){
        if(cat==="Einsatzübung"){
        return(<FontAwesomeIcon icon={faPersonChalkboard} />)
        }
        else if(cat==="Küchenbrand"){
        return(<FontAwesomeIcon icon={faFireBurner} />)
        }
        else if(cat==="Gebäudebrand"){
        return(<FontAwesomeIcon icon={faHouseFire} />)
        }
        else if(cat==="Mülltonnenbrand"){
        return(<FontAwesomeIcon icon={faDumpsterFire} />)
        }
        else if(cat==="Unratbrand"||cat==="Flächenbrand"||cat==="Fahrzeugbrand"){
        return(<FontAwesomeIcon icon={faFireExtinguisher} />)
        }
        else if(cat==="Tierrettung"){
          return(<FontAwesomeIcon icon={faCat} />)
          }
        else if(cat==="Nottüröffnung"){
        return(<FontAwesomeIcon icon={faHouseLock} />)
        }
        else if(cat==="Hochwasser"){
        return(<FontAwesomeIcon icon={faHouseFloodWater} />)
        }
        else if(cat==="First Responder"){
        return(<><FontAwesomeIcon icon={faSuitcaseMedical} />
        <FontAwesomeIcon icon={faHeartCircleBolt} /></>)
        }
        else if(cat==="Chemieunfall"){
        return(<FontAwesomeIcon icon={faSprayCanSparkles} />)
        }
        else if(cat==="Explosion"){
        return(<FontAwesomeIcon icon={faExplosion} />)
        }
        else if(cat==="Verkehrsunfall"){
        return(<FontAwesomeIcon icon={faCarBurst} />)
        }
        else if(cat==="Wasserrettung"){
        return(<FontAwesomeIcon icon={faPersonSwimming} />)
        }
        else if(cat==="Auslaufende Betriebsstoffe"){
        return(<FontAwesomeIcon icon={faOilCan} />)
        }
        else if(cat==="Baum auf Straße"){
        return(<><FontAwesomeIcon icon={faRoadCircleExclamation} />
        <FontAwesomeIcon icon={faTree} /></>)
        }
        else if(cat==="Amtshilfe"||cat==="Unterstützung Rettungsdienst"){
        return(  <FontAwesomeIcon icon={faTruckMedical} />)
        }
        return(<FontAwesomeIcon icon={faTruckArrowRight} />)
        }
    
    function getEmrgeIcon (cat){
    if(cat==="H"){
      return(
        <FontAwesomeIcon icon={faWrench} style={{margin:'1px'}}/>)
    }
    else if(cat==="H1"){
      return(<>
        <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
        </>)
    }
    else if(cat==="H2"){
      return(<>
      <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
      <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
      </>)
    }
    else if(cat==="H3"){
      return(<>
        <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
        <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
        <FontAwesomeIcon icon={faScrewdriverWrench} style={{margin:'1px'}}/>
        </>)
    }
    else if(cat==="B"){
      return(<FontAwesomeIcon icon={faFireAlt} />)
    }
    else if(cat==="B1"){
      return(<FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>)
    }
    else if(cat==="B2"){
      return(<>
        <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
        <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
        </>)
    }
    else if(cat==="B3"){
      return(<>
        <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
        <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
        <FontAwesomeIcon icon={faFire} style={{margin:'1px'}}/>
        </>)
    }
    else if(cat==="ABC"){
      return(<>
        <FontAwesomeIcon icon={faRadiation} style={{margin:'1px'}}/>
        </>)
    }
    else if(cat==="ABC1"){
      return(<>
        <FontAwesomeIcon icon={faCircleRadiation} style={{margin:'1px'}}/>
        </>)
    }
    else if(cat==="ABC2"){
      return(<>
        <FontAwesomeIcon icon={faCircleRadiation} style={{margin:'1px'}}/>
        <FontAwesomeIcon icon={faCircleRadiation} style={{margin:'1px'}}/>
        </>)
    }
    return(<FontAwesomeIcon icon={faTowerCell} />)
     }
     function customMarkerIcon(cat){
      return(L.divIcon({
        html: renderToString(getCatIcon(cat)),
        className: 'mission-icon',
        iconSize: [55, 71],
        iconAnchor: [5, 10],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      }));}

      const picSlides = ImagePath.slice(1).map((mission, index) => {
        if (index % size === 0) {
           const picSlide = ImagePath.slice(1).slice(index, index + size);
             return (
              <Carousel.Item>
                <Row>
                {picSlide.map((img, i) => (
                    <Col className="cardFokus" key={i} style={{padding:'2%'}} onClick={() => {setShow(true); setImg(img);}}>
                    <img 
                      className="imagestyle caroPicFokus"
                      src={url+"/missions/image/download/"+id+"/"+img} style={{maxHeight:'400px'}}
                 
                    />
                    </Col>
                  ))}
                </Row> 
              </Carousel.Item>
              );
            }
          });
return(
<>
{size>2?(<><br></br><br></br><br></br><br></br></>):null}
{mission?(
    <>
    <div className="containermdet">
  <div className={(mission.category!==("Einsatzübung"||"Sonstige")?(mission.emergency):("Sonstiges"))+" titledetails"}>
    <div className="categorydetails"><OverlayTrigger
placement="top"
delay={{ show: 250, hide: 400 }}
overlay={<Tooltip id="button-tooltip">{mission.category}</Tooltip>}>
<Badge pill bg="trasparence" text="dark" className="headpill">
{getCatIcon(mission.category)}
</Badge>
</OverlayTrigger></div>
    <div className="mtitle">{mission.title}</div>
    <div className="emergedetails">
        <OverlayTrigger
placement="top"
delay={{ show: 250, hide: 400 }}
overlay={<Tooltip id="button-tooltip">{mission.emergency}</Tooltip>}>
<Badge pill bg="trasparence" text="dark" className="headpill">
{getEmrgeIcon(mission.emergency)}
</Badge>
</OverlayTrigger>
</div>
  </div>
 
  <div className="detailsinfo">
    <div className="ipr"><FontAwesomeIcon icon={faClipboardList}/>&nbsp;Protokollnummer&nbsp;</div>
    <div className="pr">{mission.protocoll}</div>
    <div className="ical"><FontAwesomeIcon icon={faCalendarDay} />&nbsp;Datum&nbsp;</div>
    <div className="cal">{new Date(mission.date).toLocaleDateString('de-DE')}</div>
    <div className="icl"><FontAwesomeIcon icon={faClockFour}/>&nbsp;Uhrzeit</div>
    <div className="cl">{mission.time}&nbsp;Uhr</div>
    <div className="icat">{getCatIcon(mission.category)}&nbsp;Einsatzart&nbsp;</div>
    <div className="cat">{mission.category}</div>
    <div className="iloc"><FontAwesomeIcon icon={faMapLocationDot} />&nbsp;Einsatzort&nbsp;</div>
    <div className="loc">{mission.location}</div>
    <div className="iem">{getEmrgeIcon(mission.emergency)}&nbsp;Einsatzstufe&nbsp;</div>
    <div className="em">{mission.emergency}</div>
    <div className="iinsti"><FontAwesomeIcon icon={faTruckMedical} />&nbsp;Einsatzkräfte&nbsp;</div>
    <div className="inst">{createPill(mission.institutions)}</div>
    {links.length>0?(<>
      <div class="linksIc"><FontAwesomeIcon icon={faUpRightFromSquare} />&nbsp;Weiterführende Links&nbsp;</div>
    <div class="linksMi"><br></br>{createLinkPill()}<br></br><br></br></div></>):null}
    <div className="picdetails" onClick={() => {setShow(true); setImg(mission.imageUrl.split(",")[0]);}}><img src={mission.imageUrl.length>10?(url+"/missions/image/download/"+mission.id+"/"+mission.imageUrl.split(",")[0]):ImagePath[0]}
className="imagestyle rounded-3"/></div>
  </div>
  <div className="mapdetail">
  <MapContainer center={position} zoom={13} maxZoom={14} scrollWheelZoom={false} style={{zIndex:'0',height:'100%',width:'100%'}}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
                    <Marker
                    icon={customMarkerIcon(mission.category)}
                      position={position}>
                      <Popup minWidth={90}>
                     {mission.title}
                      </Popup>
                    </Marker>
  </MapContainer>
  </div>
  <ImageView
            show={show ? true:false}
            url={url+"/missions"}
            img={image}
            id={id}
            onClose={() => setShow(null)} 
          />
  <div className="reporty"><Form.Group controlId="formGridPrice">
                  <Form.Control
                     height="100%"
                     width="100%"
                     rows={15}
                   value={mission.report}
                   required
                    as="textarea" 
                    name="report"
                    disabled
                  />
                </Form.Group></div>
                <div className="imagesdet">
 
          {ImagePath.length>1?(
            <>
            <Carousel  key={245} indicators={true} pause="hover" interval={null} style={{zIndex:'0'}}>
            {picSlides}
            </Carousel>
            <br></br>
            <p style={{margin:'1%', fontWeight:'lighter', fontSize:'75%'}}>Wichtiger Hinweis: Auf unserer Internetseite berichten wir ausführlich (also auch mit Bildmaterial) über unser Einsatzgeschehen. Bilder werden erst gemacht, wenn das Einsatzgeschehen dies zulässt ! Es werden keine Bilder von Verletzten oder Toten gemacht oder hier veröffentlicht ! Sollten Sie Einwände gegen die hier veröffentlichen Fotos oder Berichte haben, wenden Sie sich bitte vertrauensvoll an unseren Webmaster.</p>
            <br></br>
           </>
          ):(<><br></br><br></br></>)}
        </div></div>
        <br></br>
<br></br>
<br></br>
</>
):(null)}
 </>
);}