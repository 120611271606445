import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import BannerSlide from "./Bannerslide";
import MissionCarousel from "./Mission/MissionCarousel";
import Map from "./Map";
import ReportCarousel from "./Report/ReportCarousel";
import CalendarCarousel from "./Calendar/CalendarCarousel";
import PopUp from "./Report/PopUp";

const Welcome = (props) => {
  const url= process.env.REACT_APP_PUBLIC_BACKEND;
  const [pics, setPics] = useState();
  const [available, setAvailable] = useState();
  const imageUrl = "https://www.dwd.de/DWD/warnungen/agrar/glfi/glfi_stationen.png";
  axios.get(imageUrl)
  .then(response => {
    if (response.status === 200) {
      setAvailable(true);
    } else {
      setAvailable(false);
    }
  })
  .catch(error => {
    setAvailable(false);
  });
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$KubAlt*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
          setPics({id: data.content[0].id, url: data.content[0].urls.split(","),description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  return (
    <>
    <PopUp/>
      <BannerSlide/>

        <div className="containerMainPage" style={{marginTop:"1.25%"}}>
        <div id="34" className="ConUni">
        <div id="3452" className="titleMain">
          Gemeindefeuerwehr Kubschütz
        </div>
        <div id="673" className="MainParty">
       <div className="PicWel1" style={{margin:'1%'}}>
       {pics?(<img  style={{height: '100%', maxHeight: '400px', width: '100%'}}
          src={url+"/image/image/download/"+pics.id+"/"+pics.url[0]}
          alt={pics.description}
          className="rounded-4 imageSt"
        />):null}

       </div>
    <div className="WelText moderneFont" style={{margin:'1%'}}>
    <p><a className="altDeutschFont" style={{fontSize:'140%'}}>Kubschütz</a> ist eine kleine Gemeinde im Osten von Sachsen, mit ca. 2600 Einwohnern. Unsere Feuerwehr besteht aus 2 Ortswehren mit angeschlossenen Löschgruppen. Das sind die Ortsfeuerwehr Kubschütz mit den Löschgruppen Baschütz, Soritz und Purschwitz und die Ortsfeuerwehr Rachlau. Wir haben 152 Mitglieder, davon 107 im aktiven Dienst. Neben den aktiven Abteilungen der Ortswehren gibt es auch eine Alters- und Ehrenabteilung für alle nicht mehr aktiven Feuerwehrkameraden, sowie eine Jugendfeuerwehr.</p>

<p>Wie alle Freiwilligen Feuerwehren haben auch wir immer das Problem, die volle Einsatzbereitschaft am Tage aufrecht zu erhalten. Aus diesem Grund werden, je nach Lage und Art des Einsatzes, neben der Ortswehr Kubschütz auch die anderen Ortswehren und Löschgruppen mitalarmiert. Im Alarmfall am Tag kommen viele Kameraden aus den umliegenden Ortsteilen der Gemeinde nach Kubschütz, damit immer ausreichend Personal zur Verfügung steht.</p>
    </div>
      <div className="PicWel2" style={{margin:'1%'}}>
      {pics?(<img style={{height: '100%',maxHeight: '400px', width: '100%'}}
          src={url+"/image/image/download/"+pics.id+"/"+pics.url[1]}
          alt={pics.description}
          className="rounded-4 imageSt"
        />):null} 
     </div>
     </div>
     </div>
      <div className="SideArea" style={{marginTop:"4.25%"}}>
      <div className="titleDias">
      Meldungen Umwelt
      </div>
      <div className="diaMain1">
      <div id="24" style={{marginTop:"3%"}} className="ConUni">
        <div id="3522" className="titleMain2" style={{fontSize:"100%", padding: "1%"}}>
          Unwetterkarte
        </div>
        <div id="62" className="MainParty2">
        <a target="_BLANK" href="https://www.dwd.de/DE/wetter/warnungen/warnWetter_node.html">
        <img className="" style={{borderBottomRightRadius:"15px", borderBottomLeftRadius:"15px"}} src="https://www.dwd.de/DWD/warnungen/warnstatus/SchilderLZ.jpg" brand="Unwetterkarte" width="100%" height="100%" /></a>
        </div></div>   
     </div>
        {available? <>
        <div className="diaMain2">
        <div id="24" className="ConUni">
        <div id="3522" className="titleMain2" style={{fontSize:"100%", padding: "1%"}}>
          Waldbrandgefahrenindex
        </div>
        <div id="62" className="MainParty2">
        <a href="https://www.dwd.de/DE/leistungen/waldbrandgef/waldbrandgef.html?nn=16102&amp;cl2Categories_Bundesland=wbh_bl13" target="_blank">
          <img style={{borderBottomRightRadius:"15px", borderBottomLeftRadius:"15px"}} src="https://www.dwd.de/DWD/warnungen/agrar/wbx/wbx_stationen.png" brand="Waldbrandgefahrenindex" width="100%" height="100%" />
          </a>
        </div></div>   
        </div>
        <div className="diaMain3">
        <div id="24" className="ConUni">
        <div id="3522" className="titleMain2" style={{fontSize:"100%", padding: "1%"}}>
        Graslandfeuerindex
        </div>
        <div id="62" className="MainParty2">
        <a href="https://www.dwd.de/DE/leistungen/graslandfi/graslandfi.html?nn=16102&amp;cl2Categories_Bundesland=glh_bl13" target="_blank">
          <img style={{borderBottomRightRadius:"15px", borderBottomLeftRadius:"15px"}}  src="https://www.dwd.de/DWD/warnungen/agrar/glfi/glfi_stationen.png" brand="Graslandfeuer-Index" width="100%" height="100%"/>
          </a>
        </div> </div> 
        </div> </>:null} 
      </div>
      <div className="missionMainCon">
      <div id="324" className="ConUni">
        <div id="34522" className="titleMain2">
          Letzte Einsätze
        </div>
        <div id="672" className="MainParty2">
        <MissionCarousel filter={"/missions/public?pageNumber="+0+"&pageSize="+10+"&sortBy=date&sortDir="+"asc"}/>
        </div></div>   
      
      </div>
      <div className="mapMainCon">
      <div id="24" className="ConUni">
        <div id="3522" className="titleMain2">
          Einsatzbereich
        </div>
        <div id="62" className="MainParty2">
        <Map/>
        </div></div>   
      </div>
      <div className="reportMainCon">
      <div id="24" className="ConUni">
        <div id="3522" className="titleMain2">
          Letzte Neuigkeiten
        </div>
        <div id="62" className="MainParty2">
        <ReportCarousel filter={"/report/public?pageNumber="+0+"&pageSize="+10+"&sortBy=date&sortDir="+"asc"}/>
        </div></div>   
      </div>
      <div className="calenderMainCon">
      <div id="24" className="ConUni">
        <div id="3522" className="titleMain2">
          Nächste Termine
        </div>
        <div id="62" className="MainParty2">
        <CalendarCarousel/>
        </div></div>   
      </div>
   </div>
   <br></br>
            <br></br>
            <br></br>
</>
  );
};

export default Welcome;
