import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faHandPointRight, faMailBulk, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Container, Col, Button, ButtonGroup, Carousel, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import ImageView from "../ImageView";
import ReportCarousel from "../Report/ReportCarousel";

const LöschgruppeSoritz = () => {

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const url=process.env.REACT_APP_PUBLIC_BACKEND;
  const recipientEmail = "robinhenke3@web.de";
  const subject = "Webinhalte der Feuerwehr Kubschütz";
  const body = "Vielen Dank";
  const navigate=useNavigate();
  const [ImagePath, setImagePath]=useState([]);  
  const [show, setShow]=useState(null);
 
      const [image, setImg]=useState(null);
      useEffect(() => {
        axios
        .get(
          url+"/image/search/" +
          "$LöschgruppeSoritz*" +
            "?page=" +
            0 +
            "&size=" +
            1
        )
        .then((response) => response.data)
        .then((data) => {
            console.log(data.content[0].urls.split(",").slice(1));

            setImagePath({id: data.content[0].id, urlsPage: data.content[0].urls.split(","), description: data.content[0].description});  
        })
        .catch((error) => {
          console.log(error);
        });
        }, []);

  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 1;
    } else if ( width >= 576 && width < 768 ) {
      return 2;
    } else if ( width >= 768 && width < 992 ) {
      return 2;
    } else if ( width >= 992 && width < 1200 ) {
      return 3;
    } else if ( width >= 1200 && width < 1440 ) {
      return 4;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

  return (
    <>
    {size>2?(<><br></br><br></br><br></br><br></br></>):null}
    <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Löschgruppe Soritz
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
        
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{fontWeight:'bold'}}>Zur Geschichte:</p>
        <p>Die FFw Soritz wurde im Jahre 1942 gegründet. Sie bestand aus 23 Kameraden unter Führung von Feuerwehrmeister Löhnert.
Die Ausrüstung bestand aus einem Einsatzfahrzeug mit Anhänger der Marke „Flader“ aus Jöhstadt.
Diese Daten beziehen sich auf ein Dokument aus Dresden „Übersicht über die Gliederung der sächsischen FFW“. Eine Feuerwehr wird schon vorher existiert haben, aber dafür gibt es keine Aufzeichnungen.</p>
        </div>
        <div id="324" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[0]} 
                      alt={ImagePath.description}
                    />):("LG Soritz")}
        </div></div>
        <div id="324" className="ConUniPart">
        <div id="324" className="textPartUni" style={{padding:"3%"}}>
        <p style={{fontWeight:'bold'}}>Heute:</p>
        <p>Die Löschgruppe Soritz
besteht heute aus 20 Kameraden, 10 aktive & 10 in der Alters- und Ehrenabteilung.
Seit circa 15 Jahren ist ein <u><Link to="/fahrzeugtechnik#klf" style={{color:'red'}}>KLF Barkas B1000</Link></u> stationiert. Im Jahr 2022 wurde eine neue TS aus dem Hause Magirus verbaut.
Jeden ersten Donnerstag im Monat findet unser Dienst statt.
Ausgerückt zu den Einsätzen wird in der Hauptwehr Kubschütz, dort findet auch jeden zweiten Dienstag im Monat Ausbildung für die aktiven Kameraden statt.</p>

        </div>
        <div id="5465" className="PicPartUni" style={{padding:"1%"}}>
        {ImagePath.id?(<img 
                      className="imagestyle"
                      style={{ maxHeight:"250px"}}
                      src={url+"/image/image/download/"+ImagePath.id+"/"+ImagePath.urlsPage[1]} 
                      alt={ImagePath.description}
                    />):("LG Soritz")}
        </div></div>
      
        </div></div> 
        
         <div id="324" className="ConUni" style={{  position:"relative", textAlign:"center"}}>
        <div id="34522" className="titleMain2" style={{ fontSize:"130%"}}>
        Aktuelles von der Löschgruppe Soritz
       </div>
        <div id="672" className="MainParty2" style={{padding:"1%"}}>
         <ReportCarousel filter={"/report/public/institution/"+"LG Soritz"+"?page="+0+"&size="+10+"&sortBy=date&sortDir="+"asc"}/>
         </div></div>
         <br></br>
         <br></br>
    </>
  );
};

export default LöschgruppeSoritz;
